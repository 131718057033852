import { acceptHMRUpdate, defineStore } from 'pinia'
import {
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import { onSnapshot } from 'firebase/firestore'
import { fbDoc, firebaseAuth, firebaseDb, functions, httpsCallable, firebaseMessage, getFirebaseDocument } from '@/apis'
import { useWebsiteStore } from '@/stores/useWebsiteStore'
import { useNotifyStore } from '@/stores/useNotifyStore.js'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    isAuthorized: false,
    company: null,
    companyClone: null,
    listeners: [],
    urlMasterList: []
  }),

  getters: {
    isPremiumWeb (state) { return ['pro_plus', 'premium'].includes(state.company?.plan?.type) }
  },

  actions: {
    /**
     * Asynchronously signs in a user using the given email and password.
     * @async
     * @function
     * @param {string} email - The email address to use for the sign-in.
     * @param {string} password - The password to use for the sign-in.
     * @param {string} service - The firebase auth provider to sign in with.
     * @returns {Promise<undefined>} A promise that resolves with undefined when the sign-in is complete.
     */
    async signInUser (email, password, service) {
      const useNotify = useNotifyStore()
      try {
        if (service === 'email') {
          await signInWithEmailAndPassword(firebaseAuth, email, password)
        } else if (service === 'google') {
          const provider = new GoogleAuthProvider()
          const firebaseCredential = await signInWithPopup(firebaseAuth, provider)
          const user = await getFirebaseDocument('users', firebaseCredential.user.uid)
          if (!user) {
            useNotify.openNotify(
              'error',
              'Error signing in',
              'No account found associated with this email address. Please try signing in with another Google account or create a new TradeBox account at tradeboxpro.app.')
            return
          }
        } else if (service === 'apple') {
          const provider = new OAuthProvider('apple.com')
          const result = await signInWithPopup(firebaseAuth, provider)
          const user = await getFirebaseDocument('users', result.user.uid)
          if (!user) {
            useNotify.openNotify(
              'error',
              'Error signing in',
              'No account found associated with this email address. Please try signing in with another Apple account' +
              ' or create a new TradeBox account at tradeboxpro.app.')
            return
          }
        }

        this.router.push('/auth')
      } catch (error) {
        this.isAuthorized = false
        useNotify.openNotify('error', 'Error Signing in', firebaseMessage(error.message))
      }
    },

    /**
     * Asynchronously signs out the current user.
     * @async
     * @function
     * @returns {Promise<undefined>} A promise that resolves with undefined when the sign-out is complete.
     */
    async signOutUser () {
      const useNotify = useNotifyStore()
      try {
        await signOut(firebaseAuth)
        this.router.push('/auth')
      } catch (error) {
        useNotify.openNotify(
          'error',
          'Error Signing out',
          firebaseMessage(error.message))
      }
    },

    /**
     * Asynchronously sends a password reset email to the given email address.
     * @async
     * @function
     * @param {string} email - The email address to send the password reset email to.
     * @returns {Promise<undefined>} A promise that resolves with undefined when the password reset email is sent.
     */
    async resetPassword (email) {
      const useNotify = useNotifyStore()
      try {
        await sendPasswordResetEmail(firebaseAuth, email)
        useNotify.openNotify('success', 'Success', 'Please check your email for password reset instructions.')
      } catch (error) {
        useNotify.openNotify(
          'error',
          'Error Resetting Password',
          'There was an issue. Please check your email and try again.')
      }
    },

    /**
     * Sets up a listener to the Firebase authentication state and sets the 🍍Pinia state accordingly, as well as
     * fetching
     * and updating relevant data in the 🍍Pinia store.
     * @function
     * @returns {undefined} Returns undefined.
     */
    handleAuthStateChange () {
      const useWebsite = useWebsiteStore()
      const useAuth = useAuthStore()
      onAuthStateChanged(firebaseAuth, async (user) => {
        if (user) {
          try {
            console.log('🔥Auth Change')
            this.isAuthorized = true

            // Set user
            const uid = user.uid
            this.user = await getFirebaseDocument('users', uid)

            // Set company
            const companyUid = this.user.company_uid || uid
            this.company = await getFirebaseDocument('companies', companyUid)

            //   Set website
            const result = await useWebsite.fetchUserWebsiteDoc(companyUid)

            if (this.router.currentRoute.value.path === '/auth') {
              const { path } = result || {}

              setTimeout(() => {
                useWebsite.isLoadingPage = false
                this.router.push(path || '/create')
              }, path !== '/create' ? 0 : 1000)
            }
          } catch (error) {
            console.warn('error handle auth state change')
          }
        } else {
          // User is signed out
          this.isAuthorized = false
          this.unsubscribe()

          useWebsite.$reset()
          useAuth.$reset()
        }
      })
    },

    /**
     * Fetches the URL master list from Firebase and updates the urlMasterList property in the component's data.
     * @return {void}
     */
    async fetchUrlMasterList () {
      try {
        const docRef = fbDoc(firebaseDb, 'website', 'master')
        const unsubscribe = onSnapshot(docRef, (doc) => {
          this.urlMasterList = doc.data().urlMasterList
        })

        this.listeners.push(unsubscribe)
      } catch (error) {
        console.warn('error fetching master url list')
      }
    },

    /**
     * Calls the Cloud Function 'updateMasterUrlList' with new and old URL parameters to update the website's master URL list.
     * @param {string} newUrl - The new URL to add to the master list.
     * @param {string} oldUrl - The old URL to remove from the master list.
     * @param {string} siteUid - The site uid.
     * @returns {Promise} - A Promise that resolves with the response data from the Cloud Function or rejects with an error.
     */
    async updateMasterUrlList (newUrl, oldUrl, siteUid) {
      try {
        const callable = httpsCallable(functions, 'firebaseUpdateUrlMasterList')
        return await callable({ newUrl, oldUrl, siteUid })
      } catch (error) {
        console.log('🚨 error', error.message)
      }
    },

    /**
     * Unsubscribe from all Firebase listeners.
     */
    unsubscribe () {
      console.log('unsubscribe documents')
      this.listeners.forEach(fn => fn())
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
