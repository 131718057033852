import VueGoogleMaps from '@fawmi/vue-google-maps'
export default {
  install (app) {
    app.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyAxULs4tuSygMRf8S6QpFwWWbN3B_-1IkU',
        libraries: 'places',
        version: 3.50
      }
    })
  }
}
