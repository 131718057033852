
export const handyman = {
// page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Elevate Your Space with Expert Handyman Services',
        body: 'From custom shelving to home repairs, our team of skilled handymen will bring your vision to life with unparalleled craftsmanship and attention to detail.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are passionate about transforming your space with exceptional handyman services. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring a flawless finish that exceeds your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Handyman Services',
        headline: 'Our Comprehensive Handyman Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your space.'
      },
      bullets: [
        {
          title: 'Custom Carpentry',
          text: 'Add function and style to your home with our expert custom carpentry services. We specialize in creating beautiful, high-quality pieces that are tailored to your unique needs and preferences.'
        },
        {
          title: 'Home Repairs',
          text: 'Keep your home in top condition with our reliable home repair services. From fixing leaky faucets to repairing drywall, we have the expertise and tools to tackle any repair project.'
        },
        {
          title: 'Home Maintenance',
          text: 'Maintain the value and integrity of your home with our comprehensive home maintenance services. We offer regular inspections and upkeep to keep your home in top condition year-round.'
        },
        {
          title: 'Outdoor Living',
          text: 'Transform your outdoor space into a beautiful oasis with our expert outdoor living services. From deck and patio construction to landscaping and more, we can help you create the perfect outdoor retreat.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Mark R',
          rating: 5,
          location: 'Denver, CO',
          text: 'They did an amazing job on our custom shelving. The team was professional, courteous, and went above and beyond to ensure a perfect finish. We highly recommend them!'
        },
        {
          name: 'Tina & Jeff W.',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We were so impressed with the quality of the work done by Them. Our home repairs were completed quickly and efficiently, and the handyman was reliable and friendly. Thank you for your excellent service!'
        },
        {
          name: 'Eric M',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'I am so happy I chose them for my custom carpentry project. Their experts were knowledgeable, friendly, and helped me design and build the perfect piece for my home. I am thrilled with the results!'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional handyman projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Experience the Difference in Quality Handyman Services',
        body: 'With a passion for delivering exceptional results and a commitment to excellence, our team of skilled handymen is dedicated to providing top-notch craftsmanship and customer service. Get to know us and see why we\'re the trusted choice for transforming homes and businesses.'
      }
    },
    features: {
      text: {
        overline: 'Comprehensive Handyman Services',
        headline: 'Our Expert Solutions for Every Need',
        body: 'From repairs and installations to upgrades and renovations, our team is equipped to handle all your handyman needs.'
      },
      bullets: [
        {
          title: 'Home Repairs',
          text: 'Keep your home in top condition with our reliable and affordable home repair services. We can handle everything from small fixes to major repairs.'
        },
        {
          title: 'Installations',
          text: 'Trust our team of experts to install new fixtures, appliances, and equipment in your home or business. We\'ll make sure everything is installed correctly and functioning smoothly.'
        },
        {
          title: 'Upgrades & Renovations',
          text: 'Transform your space with our comprehensive renovation services. From kitchen and bathroom upgrades to full-scale remodeling projects, we\'ll help you achieve the look and functionality you\'ve always wanted.'
        },
        {
          title: 'Maintenance & Cleaning',
          text: 'Keep your property in top condition with our maintenance and cleaning services. We can help with routine tasks like gutter cleaning and lawn care, as well as deep cleaning and organization projects.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional handyman projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Home Improvement Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our handyman services. From questions about pricing and scheduling, to tips for preparing your home, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Home Improvement Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your home improvement project.'
      },
      bullets: [
        {
          title: 'How long will it take to complete my project?',
          text: 'The time required to complete your project will depend on a variety of factors, such as the size of the project, the scope of the work, and any necessary preparations. We will provide you with a detailed timeline before beginning work, and keep you informed throughout the process.'
        },
        {
          title: 'What types of services do you offer?',
          text: 'We offer a wide range of handyman services, including home repairs, maintenance, and installations. From fixing leaky faucets to installing new light fixtures, we can help with all your home improvement needs.'
        },
        {
          title: 'Will the work be disruptive to my daily routine?',
          text: 'We understand that having work done on your home can be an inconvenience, and we will do everything we can to minimize disruption to your daily routine. Our team will work efficiently and cleanly, and will be respectful of your home throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Start Your Project?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Handymen',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Let\'s Talk About Your Next Project',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our handyman services. Our team is standing by to answer your questions and help you transform your space with exceptional handyman solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for Project Pricing?',
        body: 'We understand that you want to make informed decisions about your home improvement project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your space and project needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
