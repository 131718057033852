<script setup>
import { ref } from 'vue'
import { Switch } from '@headlessui/vue'

const props = defineProps({
  label: {
    type: [String, Number],
    default: ''
  },
  modelValue: {
    type: Boolean,
    required: false
  }
})

const emit = defineEmits(['update:modelValue', 'toggle'])

const isToggled = ref(props.modelValue)

function toggle () {
  isToggled.value = !isToggled.value
  emit('update:modelValue', isToggled.value)
  // emit('toggle', isToggled.value)
}
</script>

<template>
  <Switch
    v-model="isToggled"
    :class="[
      modelValue ? 'bg-blue-600' : 'bg-gray-200',
      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto',
    ]"
    @click="toggle"
  >
    <span
      :class="[
        modelValue ? 'translate-x-5' : 'translate-x-0',
        'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ]"
      aria-hidden="true"
    />
  </Switch>
</template>
