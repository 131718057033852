
export const roofer = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Protect Your Home with Expert Roofing Services',
        body: 'Our team of skilled roofers is dedicated to providing top-quality roofing services that ensure the safety and protection of your home. From minor repairs to full roof replacements, we have the experience and expertise to handle all of your roofing needs.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are committed to delivering exceptional results and providing the highest level of customer service. Our team of skilled professionals brings years of expertise and a dedication to quality to every project we undertake, ensuring that your roofing needs are met with unparalleled craftsmanship and attention to detail.'
      }
    },
    features: {
      text: {
        overline: 'Comprehensive Roofing Services',
        headline: 'Our Roofing Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that protect your home and ensure your peace of mind.'
      },
      bullets: [
        {
          title: 'Roof Repair',
          text: 'Don\'t let minor roof damage turn into a major problem. Our expert team will quickly and effectively repair any issues to ensure the long-term protection of your home.'
        },
        {
          title: 'Roof Replacement',
          text: 'If your roof is beyond repair, our team will provide a comprehensive roof replacement solution that meets your needs and budget. We offer a variety of roofing options to suit your style and protection requirements.'
        },
        {
          title: 'Roof Inspection',
          text: 'Regular roof inspections are essential to ensuring the safety and protection of your home. Our team will thoroughly inspect your roof and provide a detailed assessment of any issues or necessary repairs.'
        },
        {
          title: 'Gutter Installation',
          text: 'Properly functioning gutters are critical to the health and longevity of your roof. Our team will expertly install high-quality gutters that protect your home from water damage and ensure proper drainage.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'John D',
          rating: 5,
          location: 'Denver, CO',
          text: 'They did an outstanding job on our roof replacement. The team was professional, courteous, and ensured that our home was protected throughout the entire process. We highly recommend them!'
        },
        {
          name: 'Sara R',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We were so impressed with their attention to detail during our roof repair. They took the time to fully assess the damage and provide a comprehensive repair solution that exceeded our expectations.'
        },
        {
          name: 'Michael L',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'Their team provided an excellent roof inspection and identified several issues that we were able to address before they became major problems. We highly recommend their services for all of your roofing needs.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional roofing projects and see the difference our skilled professionals can make in protecting and enhancing your home.'
      }
    },
    cta: {
      text: {
        overline: 'Protect Your Home Today',
        headline: 'Contact Us Today to Schedule a Consultation',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Roofing',
        body: 'With a commitment to excellence and a passion for delivering stunning results, our team of professional roofers is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for roofing services.'
      }
    },
    features: {
      text: {
        overline: 'Comprehensive Roofing Services',
        headline: 'Our Expert Roofing Solutions',
        body: 'From start to finish, our team is dedicated to delivering exceptional results that protect and enhance your property.'
      },
      bullets: [
        {
          title: 'Roof Repair',
          text: 'Keep your roof in top shape with our expert repair services. We use only the highest-quality materials to ensure a long-lasting and effective solution.'
        },
        {
          title: 'Roof Replacement',
          text: 'If it\'s time for a new roof, our team is here to help. We offer reliable and efficient replacement services to ensure your property is protected for years to come.'
        },
        {
          title: 'Gutter Installation',
          text: 'Protect your property from water damage with our expert gutter installation services. We offer a range of options to ensure your gutters are both functional and stylish.'
        },
        {
          title: 'Storm Damage Repair',
          text: 'If your roof has been damaged in a storm, our team can help. We offer fast and reliable repair services to get your property back to normal as quickly as possible.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional roofing projects and see the difference our skilled professionals can make in protecting and enhancing your property.'
      }
    },
    cta: {
      text: {
        overline: 'Protect Your Property Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Roofing Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our roofing services. From questions about pricing and materials, to tips for maintaining your roof, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Roofing Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your roofing project.'
      },
      bullets: [
        {
          title: 'How often should I have my roof inspected?',
          text: 'We recommend having your roof inspected at least once a year to identify any potential issues before they become major problems. Additionally, if you notice any signs of damage or wear, such as missing shingles or leaks, it is important to have your roof inspected as soon as possible.'
        },
        {
          title: 'What types of roofing materials do you use?',
          text: 'We use only the highest-quality roofing materials to ensure a durable and long-lasting roof. Our team can provide recommendations based on your needs and preferences, and we are happy to work with any roofing material of your choice.'
        },
        {
          title: 'Will the roofing process be disruptive to my daily routine?',
          text: 'We understand that having your roof repaired or replaced can be an inconvenience, and we will do everything we can to minimize disruption to your daily routine. Our team will work efficiently and cleanly, and will be respectful of your home or business throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Protect Your Home?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Roofing Experts',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Need Roofing Help?',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our roofing services. Our team is standing by to answer your questions and help you protect your home with exceptional roofing solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our roofing services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your roofing project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your roofing needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
