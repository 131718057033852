<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<!-- Tailwind Compliant -->
<template>
  <div class="@container">
    <div class="relative isolate overflow-hidden bg-gray-800">
      <!--  background image -->
      <theme-base-img
        :img-src="props.data?.content?.background_image?.url"
        alt=""
        class="absolute inset-0 -z-10 h-full w-full object-cover opacity-30"
      />

      <!-- overlay -->
      <div class="px-6 @lg:px-8">
        <div class="mx-auto max-w-2xl py-32 @sm:py-48 @lg:py-56">
          <div class="text-center">
            <!-- title -->
            <h1 class="text-4xl font-bold tracking-tight text-white @sm:text-6xl">
              {{ props.data?.content?.headline || 'Beautiful Home Painting by Professionals' }}
            </h1>

            <!-- body -->
            <p class="mt-6 text-lg leading-8 text-gray-300">
              {{
                props.data?.content?.body ||
                  'Revitalize your space with the magic of a fresh coat of paint. Our team of professionals is dedicated to delivering clean lines, vibrant color, and flawless results that will leave you with a home that you\'ll love for years to come.'
              }}
            </p>

            <!-- button -->
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <component
                :is="button.component"
                v-for="button in props.data?.content?.button"
                :key="button.uid"
                v-bind="button"
                @click="router.push(useWebsite.getRoutePath(button.link.href))"
              >
                {{ button.label || 'Get a free estimate' }}
              </component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
