import { acceptHMRUpdate, defineStore } from 'pinia'
import { useAuthStore } from '@/stores/useAuthStore.js'
import { useNotifyStore } from '@/stores/useNotifyStore.js'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { collection, query, where, getDocs } from 'firebase/firestore'
import {
  firebaseDb,
  functions,
  storage,
  getFirebaseDocument,
  setFirebaseDocument,
  updateFirebaseDocumentField,
  httpsCallable
} from '@/apis'
import { createWebsite, useUid, useGetNewUrl } from '@/utils/index.js'
import { cloneDeep, isEqual } from 'lodash'

export const useWebsiteStore = defineStore('website', {
  state: () => ({
    site: null,
    siteClone: null,
    isEditor: false,
    isLoadingPage: false,
    loadingMessage: ''
  }),

  getters: {
    /**
     * Checks if there are any changes between the current site state and the cloned site state
     * @param {Object} state - the current state of the site
     * @returns {boolean} - true if there are changes, false otherwise
     */
    hasChanges (state) { return !isEqual(state.site, state.siteClone) },

    /**
     * Returns a color theme object based on the provided state. If `isEditor` is true, the theme color will be retrieved from `siteClone`, otherwise it will be retrieved from `site`.
     * @param {Object} state - The state object.
     * @param {Boolean} state.isEditor - A boolean value indicating whether the site is being edited.
     * @param {Object} state.site - The site object.
     * @param {Object} state.siteClone - The cloned site object.
     * @returns {Object} The color theme object.
     */
    theme (state) {
      const obj = { color: 'blue' }
      if (state.isEditor) {
        obj.color = state.siteClone?.global?.color_theme
      } else {
        obj.color = state.site?.global?.color_theme
      }
      return obj
    },

    /**
     * Returns the page data for the specified page name.
     * @param {object} state - The state object.
     * @returns {Object} The page data object.
     */
    fetchPageData (state) {
      return (name = 'home') => {
        if (state.isEditor) {
          return this.siteClone?.pages?.find(item => item.path.toLowerCase() === name)
        } else {
          return this.site?.pages?.find(item => item.path === name)
        }
      }
    }
  },

  actions: {
    /**
     * Assigns a deep clone of this.site to this.siteClone
     */
    assignClone () {
      this.siteClone = cloneDeep(this.site)
    },

    /**
     * Fetches website data from Firebase
     * @param {string} url - the url to fetch
     * @param {string} type - the type of url to fetch (either 'path' or 'domain')
     */
    async fetchWebsiteData (url, type) {
      const useAuth = useAuthStore()
      let companyUid

      if (!this.site?.uid) {
        this.isLoadingPage = true

        let q

        if (type === 'path') {
          q = query(collection(firebaseDb, 'website'), where('url', '==', url))
        } else if (type === 'domain') {
          q = query(collection(firebaseDb, 'website'), where('domain', '==', url))
        }

        try {
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((doc) => {
            this.site = doc.data()
            companyUid = doc.id
            this.isLoadingPage = false
          })
          useAuth.user = await getFirebaseDocument('users', companyUid)
          console.log('user', useAuth.user)
          useAuth.company = await getFirebaseDocument('companies', companyUid)
          console.log('company', useAuth.company)

          if (useAuth?.company?.plan?.type !== 'pro_plus' && useAuth?.company?.plan?.type !== 'premium') {
            this.router.push('/restricted')
          }
        } catch (error) {
          console.error(error)
          this.isLoadingPage = false
          this.router.push('/restricted')
        }
      }
    },

    /**
     * Fetches the website data from Firestore based on the provided docId and type
     * @param {string} docId - The document id to fetch
     * @returns {Promise}
     */
    async fetchUserWebsiteDoc (docId) {
      const { user } = useAuthStore()

      const notifyStore = useNotifyStore()

      if (!this.site?.url) {
        this.isLoadingPage = true
        this.loadingMessage = 'Let\'s Build.'
        const webDoc = await getFirebaseDocument('website', docId)

        if (webDoc) {
          this.isLoadingPage = false
          // Set the website document to the latest version
          this.site = webDoc
          this.siteClone = cloneDeep(this.site)
          return ({ path: '/editor' })
        } else if (!user.uid) {
          notifyStore.openNotify(
            'error',
            'Error signing in',
            'No account found associated with this email address. Please try signing in with another account or create a new TradeBox account at tradeboxpro.app.')
        } else {
          return ({ path: '/create' })
        }
      }
    },

    async buildWebsite (company, trade, color, theme) {
      const companyName = company.contact.name || company.uid
      trade = trade || company?.business?.trade
      // If the document doesn't exist, create a new one
      this.loadingMessage = 'Building your website...'
      this.isLoadingPage = true

      // fetch master url list
      const urlList = await getFirebaseDocument('website', 'master')

      // create website
      const userSite = createWebsite(company, trade, color, theme)

      // assign current url or update url
      userSite.url = useGetNewUrl(companyName, urlList.urlMasterList)

      // define callable
      const callable = httpsCallable(functions, 'firebaseCreateWebsite')
      const result = await callable({ site: userSite })

      // if success
      if (result?.data?.success) {
        this.site = cloneDeep(userSite)
        this.siteClone = cloneDeep(userSite)
      } else {
        throw new Error(result.data.message)
      }
    },

    /**
     * Asynchronously publishes website assets and updates website document in Firestore.
     * @param {string} asset - The type of asset to publish. Either 'logo' or 'gallery'.
     * @param {array} images - An array of image files to publish to storage.
     * @returns {Promise} A promise containing the result of the updateFirebaseDocument call.
     */
    async publishWebsite (asset, images) {
      const useNotify = useNotifyStore()
      const useAuth = useAuthStore()
      const companyId = useAuth.company?.uid
      const doc = this.site.uid || companyId
      try {
        if (images && (asset === 'logo')) {
          await this.uploadAssetToStorage(asset, images)
        } else if (images && (asset === 'gallery')) {
          await this.uploadImagesToStorage(asset, images)
        }

        if (this.hasChanges) {
          if (this.site.url !== this.siteClone.url) {
            const callable = httpsCallable(functions, 'firebaseUpdateUrlMasterList')
            const result = await callable({
              newUrl: this.siteClone.url,
              oldUrl: this.site.url,
              siteUid: this.site.uid
            })
            // if success
            if (result.data.success) {
              return this.updateSite(this.siteClone, doc)
            } else {
              useNotify.openNotify(
                'error',
                'Error',
                'There was an issue saving your website.'
              )
            }
          } else {
            return this.updateSite(this.siteClone, doc)
          }
        }
      } catch (e) {
        throw new Error(e)
      }
    },

    /**
     * Updates the website data in Firebase and sets the updated data to the current site data.
     * @param {Object} siteClone - The updated site data.
     * @param {string} docId - The document ID for the website data.
     * @returns {Object} An object containing a success flag, message, and the updated website data.
     */
    async updateSite (siteClone, docId) {
      const useNotify = useNotifyStore()
      try {
        await setFirebaseDocument('website', docId, siteClone)
        // assign website data
        this.site = cloneDeep(siteClone)
        useNotify.openNotify(
          'success',
          'Success',
          'Your website has been saved.'
        )
        return { success: true, message: 'error ', value: this.site }
      } catch (e) {
        useNotify.openNotify(
          'error',
          'Error',
          'There was an issue saving your website.'
        )
        return { error: true, message: e }
      }
    },

    /**
     * Uploads a file to Firebase Storage and updates the corresponding field in the website document.
     * @param {string} asset - The name of the asset being uploaded (e.g. 'logo', 'favicon').
     * @param {File} file - The file to be uploaded.
     * @returns {Promise<Object>} An object with a success property indicating if the upload was successful, and an error property containing an error message if the upload failed.
     */
    async uploadAssetToStorage (asset, file) {
      const useNotify = useNotifyStore()
      const doc = this.site.uid
      const storagePath = `${doc}/website/${asset}`
      const uploadTask = uploadBytesResumable(ref(storage, storagePath), file)

      try {
        await uploadTask
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
        const image = this.createImageObject(downloadURL, storagePath, asset, file.size)
        const field = `global.${asset}`

        await updateFirebaseDocumentField('website', doc, field, image)
        this.siteClone.global[asset] = image
        this.site.global[asset] = image
        useNotify.openNotify(
          'success',
          'Success',
          `Your ${asset} has been saved.`
        )
      } catch (error) {
        return { error: true, message: error.message }
      }
    },

    createImageObject (downloadURL, storagePath, asset, size) {
      return {
        uid: useUid(),
        name: `Company ${asset}`,
        type: 'url',
        size,
        url: downloadURL,
        storage_path: storagePath
      }
    },

    async uploadImagesToStorage (files, asset) {
      const useNotify = useNotifyStore()
      const doc = this.site.uid

      // Loop through each file and upload it to Firebase Storage
      const uploadPromises = files.map((file) => {
        const storagePath = `${doc}/website/${asset}/${file.name}`
        const uploadTask = uploadBytesResumable(ref(storage, storagePath), file)

        return new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            () => { },
            (error) => {
              reject(error)
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
                const image = {
                  uid: useUid(),
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  storage_path: storagePath,
                  url: downloadURL
                }
                const field = `global.${asset}`
                await updateFirebaseDocumentField('website', doc, field, image)
                this.siteClone.global[asset] = image
                this.site.global[asset] = image
                resolve()
              } catch (error) {
                reject(error)
              }
            }
          )
        })
      })

      // Wait for all upload promises to complete
      try {
        await Promise.all(uploadPromises)
        useNotify.openNotify(
          'success',
          'Success',
          'Your website images have been saved.'
        )
      } catch (error) {
        useNotify.openNotify(
          'error',
          'Error',
          'There was an error uploading your website images.'
        )
      }
    },

    /**
     * Returns an array of 6 unique random image names.
     * @returns {string[]} Array of image names in the format 'img-n'
     */
    randomImages () {
      const images = new Set()
      while (images.size < 6) {
        const randomNumber = Math.floor(Math.random() * 72) + 1
        images.add(`img-${randomNumber}`)
      }
      return Array.from(images)
    },

    /**
     * Get the correct route path based on href and whether in editor or not.
     */
    getRoutePath (href) {
      const sitePath = this.site?.url || ''
      const editorPath = href === 'home' ? '/editor' : `/editor/${href || ''}`
      const clientPath = `/${sitePath}${href ? `/${href}` : ''}`
      return this.isEditor ? editorPath : clientPath
    },

    async createLead (formData, type) {
      const { user } = useAuthStore()
      const useNotify = useNotifyStore()
      const companyUid = user?.company_uid
      const uid = useUid()
      const lead = {
        uid,
        created_at: Date.now(),
        status: 'new',
        company_uid: companyUid,
        formData
      }
      const body = type === 'event' ? `${formData.name} scheduled a new event.` : `${formData.name} submitted a new lead.`
      const notification = {
        uid,
        company_uid: companyUid,
        user_uid: user?.uid,
        doc_uid: uid,
        title: 'New Lead',
        body,
        href: `lead/${uid}`,
        badge: 1,
        created_at: Date.now(),
        devices: [`${user?.device || ''}`]
      }

      const data = {
        company_uid: companyUid,
        uid,
        lead,
        notification
      }

      if (!user.company_uid) throw new Error('Invalid company uid')

      try {
        const callable = httpsCallable(functions, 'firebaseCreateLead')
        const result = await callable(data)

        return { success: true, result }
      } catch (error) {
        useNotify.openNotify(
          'error',
          'Error',
          'There was an error submitting your contact form. Please try again later'
        )
        throw new Error(e)
      }
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWebsiteStore, import.meta.hot))
}
