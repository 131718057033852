<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()

</script>

<!-- tailwind compliant -->
<template>
  <theme-base-section class="bg-gray-100">
    <div class="max-w-2xl mx-auto text-center">
      <theme-base-title>
        {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
      </theme-base-title>

      <theme-base-subtitle>
        {{
          props.data?.content?.text?.body ||
            'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
        }}
      </theme-base-subtitle>

      <!-- button -->
      <div class="mt-10">
        <component
          :is="button.component"
          v-for="button in props.data?.content?.button"
          :key="button.uid"
          v-bind="button.style"
          @click="router.push(useWebsite.getRoutePath(button.link.href))"
        >
          {{ button.label || 'Get in touch' }}
        </component>
      </div>
    </div>
  </theme-base-section>
</template>
