import { reactive, toRefs } from 'vue'
import { usePlatform } from '@/composables/usePlatform'

export default function useScreen () {
  const state = reactive({
    screen: {
      width: 0,
      height: 0,
      is: {
        xs: true,
        sm: false,
        md: false,
        lg: false,
        xl: false
      },
      gt: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false
      },
      lt: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false
      }
    },
    viewport: {
      height: 0,
      keyboardHeight: 0,
      browserBarHeight: 0,
      viewHeight: 0
    }
  })

  const breakpoints = {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1536
  }

  const updateViewportHeight = (keyboardHeight) => {
    state.screen.width = window.innerWidth
    state.screen.height = window.innerHeight
    state.viewport.keyboardHeight = keyboardHeight || 0
    state.viewport.height = window.innerHeight
    setScreenSizes()

    const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight
    const appleCapApp = usePlatform.capacitor && usePlatform.device === 'apple'

    state.viewport.viewHeight = viewportHeight - state.viewport.browserBarHeight -
      (appleCapApp ? 36 : 0)
  }

  const updateBrowserBarHeight = () => {
    state.viewport.browserBarHeight = state.viewport.height - window.innerHeight
  }

  const setSizes = (val) => {
    Object.keys(state.screen.is).forEach(key => {
      if (key === val) {
        state.screen.is[key] = true
        state.screen.gt[key] = false
        state.screen.lt[key] = false
      } else if (state.screen.width > breakpoints[key]) {
        state.screen.is[key] = false
        state.screen.gt[key] = true
        state.screen.lt[key] = false
      } else {
        state.screen.is[key] = false
        state.screen.gt[key] = false
        state.screen.lt[key] = true
      }
    })
  }

  const setScreenSizes = () => {
    if (state.screen.width <= breakpoints.xs) {
      setSizes('xs')
    } else if (state.screen.width >= breakpoints.xs && state.screen.width <= breakpoints.sm) {
      setSizes('sm')
    } else if (state.screen.width >= breakpoints.sm && state.screen.width <= breakpoints.md) {
      setSizes('md')
    } else if (state.screen.width >= breakpoints.md && state.screen.width <= breakpoints.lg) {
      setSizes('lg')
    } else {
      setSizes('xl')
    }
  }

  let isInitialized = false
  const initData = () => {
    updateViewportHeight()
    isInitialized = true
  }

  if (!isInitialized) {
    initData()
  }

  window.addEventListener('resize', updateViewportHeight)

  window.addEventListener('statusTap', updateBrowserBarHeight)

  window.addEventListener('keyboardDidShow', () => {
    const keyboardHeight = state.viewport.height - window.innerHeight
    updateViewportHeight(keyboardHeight)
  })

  window.addEventListener('keyboardDidHide', () => {
    updateViewportHeight(0)
  })

  return toRefs(state)
}
