
export const hvac = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Elevate Your Space with Expert HVAC Services',
        body: 'From heating and cooling installations to routine maintenance and repairs, our team of skilled HVAC technicians will keep your home or business comfortable year-round.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are passionate about providing exceptional HVAC services to our customers. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring your comfort and satisfaction.'
      }
    },
    features: {
      text: {
        overline: 'Comprehensive HVAC Solutions',
        headline: 'Our Range of HVAC Services',
        body: 'From installation to maintenance and repairs, our team has you covered.'
      },
      bullets: [
        {
          title: 'Heating Installation',
          text: 'We offer professional heating installation services to keep your home or business warm and comfortable throughout the colder months.'
        },
        {
          title: 'Air Conditioning Installation',
          text: 'Stay cool and comfortable in the summer with our reliable air conditioning installation services.'
        },
        {
          title: 'HVAC Maintenance',
          text: 'Keep your HVAC system running smoothly with our routine maintenance services.'
        },
        {
          title: 'Emergency Repairs',
          text: 'When your HVAC system breaks down unexpectedly, our team is available 24/7 for emergency repairs.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Samuel P',
          rating: 5,
          location: 'Denver, CO',
          text: 'Great service and very professional team. They did an excellent job with our heating installation and we will definitely use them again for future HVAC needs.'
        },
        {
          name: 'Megan & Tom L.',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We were extremely impressed with the quality of work done by this team. Our air conditioning installation was completed quickly and efficiently, and the technicians were very knowledgeable and friendly.'
        },
        {
          name: 'Anthony R',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'They were a lifesaver when our HVAC system stopped working during a cold spell. Their emergency repair services were fast and reliable, and the technician who came to our home was friendly and knowledgeable.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional HVAC projects and see the difference our skilled professionals can make in keeping your space comfortable.'
      }
    },
    cta: {
      text: {
        overline: 'Stay Comfortable All Year Round',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional HVAC Services',
        body: 'With a commitment to excellence and a passion for delivering exceptional results, our team of HVAC professionals is dedicated to providing top-quality service and customer satisfaction. Get to know us and see why we\'re the trusted choice for all your HVAC needs.'
      }
    },
    features: {
      text: {
        overline: 'Premium HVAC Services',
        headline: 'Our Comprehensive HVAC Solutions',
        body: ' From installation to maintenance, our team is dedicated to delivering exceptional results that ensure your comfort and peace of mind.'
      },
      bullets: [
        {
          title: 'Heating Services',
          text: 'Keep your home or business warm and comfortable with our expert heating services. We use only the highest-quality materials and products to ensure reliable and efficient operation.'
        },
        {
          title: 'Air Conditioning Services',
          text: 'Stay cool and comfortable during the hot summer months with our reliable air conditioning services. Our team will work with you to select the right system for your needs and ensure a flawless installation.'
        },
        {
          title: 'Indoor Air Quality',
          text: 'Breathe easy with our comprehensive indoor air quality solutions. From air duct cleaning to filtration systems, we can help ensure your home or business has clean and healthy air.'
        },
        {
          title: 'Maintenance Plans',
          text: 'Protect your investment and ensure reliable operation with our comprehensive maintenance plans. Our team will perform regular inspections and maintenance to keep your HVAC system running smoothly.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional HVAC projects and see the difference our skilled professionals can make in ensuring your comfort and satisfaction.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Comfort Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your HVAC Questions',
        body: 'Our HVAC FAQ page is your one-stop shop for everything you need to know about our heating and cooling services. From questions about pricing and scheduling, to tips for maintaining your HVAC system, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your HVAC Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your HVAC project.'
      },
      bullets: [
        {
          title: 'How often should I have my HVAC system serviced?',
          text: 'Regular maintenance is crucial for keeping your HVAC system running smoothly and efficiently. We recommend having your system serviced at least once a year, preferably before the start of each heating or cooling season.'
        },
        {
          title: 'What types of HVAC systems do you service?',
          text: 'We service all types of HVAC systems, including central air conditioning, heat pumps, furnaces, boilers, and ductless mini-split systems.'
        },
        {
          title: 'How can I improve the efficiency of my HVAC system?',
          text: 'There are many ways to improve the efficiency of your HVAC system, such as sealing air leaks, upgrading insulation, and installing a programmable thermostat. Our team can provide recommendations based on your specific needs and budget.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Improve Your Comfort?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert HVAC Technicians',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Contact Us',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our HVAC services. Our team is standing by to answer your questions and help you create a comfortable indoor environment.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for Project Pricing?',
        body: 'We understand that you want to make informed decisions about your HVAC project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your HVAC needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
