const constructionTrades = {
  Carpenter: ['cabinetry', 'custom furniture', 'framing', 'trim work', 'woodworking'],
  Cleaner: ['residential', 'commercial'],
  'Concrete Finisher': ['decorative concrete', 'driveways', 'foundations', 'patios', 'sidewalks'],
  Drywaller: ['ceiling installation', 'drywall installation', 'drywall repair', 'plastering'],
  Electrician: ['electrical panels', 'lighting', 'outlets and switches', 'rewiring', 'solar panels'],
  HVAC: ['air conditioning', 'ductwork', 'furnaces', 'heat pumps', 'thermostats'],
  Handyman: ['carpentry', 'repairs', 'electrical work', 'furniture assembly', 'plumbing'],
  Landscaper: ['garden design', 'hardscaping', 'irrigation systems', 'lawn care', 'tree trimming'],
  Mason: ['brickwork', 'concrete work', 'fireplaces', 'stone work', 'tuckpointing'],
  Painter: [
    'cabinet painting',
    'commercial',
    'exterior painting',
    'interior painting',
    'misc repairs',
    'new construction painting',
    'repaint',
    'residential',
    'staining'],
  Plumber: ['bathroom fixtures', 'drain cleaning', 'faucet repair and installation', 'pipe repair', 'water heaters'],
  Roofer: ['flat roofs', 'metal roofing', 'roof inspections', 'roof repair', 'roof replacement'],
  Welder: ['custom metalwork', 'metal fabrication', 'metal repair', 'welding repairs']
}

const colors = [
  { theme: 'red', color: '#ef4444' },
  { theme: 'orange', color: '#f97316' },
  { theme: 'amber', color: '#f59e0b' },
  { theme: 'yellow', color: '#eab308' },
  { theme: 'lime', color: '#84cc16' },
  { theme: 'green', color: '#22c55e' },
  { theme: 'emerald', color: '#10b981' },
  { theme: 'teal', color: '#14b8a6' },
  { theme: 'cyan', color: '#06b6d4' },
  { theme: 'sky', color: '#0ea5e9' },
  { theme: 'blue', color: '#3b82f6' },
  { theme: 'indigo', color: '#6366f1' },
  { theme: 'violet', color: '#8b5cf6' },
  { theme: 'purple', color: '#a855f7' },
  { theme: 'fuchsia', color: '#d946ef' },
  { theme: 'pink', color: '#ec4899' },
  { theme: 'rose', color: '#f43f5e' }
]

const address = {
  uid: '',
  area: '',
  city: '',
  formatted: '',
  lat: 0,
  link: '',
  long: 0,
  state: '',
  street: '',
  zip: ''
}

const client = {
  uid: '',
  doc_uid: '',
  user_uid: '',
  company_uid: '',
  created_at: 0,
  name: '',
  name_alt: '',
  email: '',
  phone: '',
  phone_alt: '',
  address,
  notes: '',
  stripe: {}
}

const fileData = {
  uid: '',
  name: '',
  size: 0,
  type: '',
  url: '',
  storage_path: ''
}

const link = {
  uid: '',
  name: '',
  href: '',
  external: false
}

const review = {
  name: '',
  rating: null,
  location: '',
  text: ''
}

const bullet = {
  title: '',
  text: ''
}

const company = {
  uid: '',
  admin: [],
  created_at: 0,
  business: {
    structure: '',
    trade: '',
    license_number: '',
    currency: { code: 'usd', label: 'US Dollar $' }
  },
  contact: {
    address,
    name: '',
    phone: '',
    email: '',
    website: ''
  },
  geoHash: '',
  logo: fileData,
  connected: {
    contractors: [],
    sent_requests: [],
    received_requests: []
  },
  plan: {
    type: 'free',
    provider: '',
    will_lapse: false,
    has_downgraded: false,
    period_end: 0,
    stripe: {
      customer_uid: '',
      subscription_uid: ''
    },
    stripe_connect: {
      account_uid: '',
      details_submitted: false,
      charges_enabled: false
    }
  },
  review_statistics: {
    review_total: 0,
    total_star_count: 0
  },
  reference: {
    estimate: {
      number: 100,
      period_count: 0,
      days_valid: 30
    },
    invoice: {
      number: 100,
      period_count: 0,
      days_until_due: 7
    },
    client: {
      number: 100,
      period_count: 0
    },
    ai: {
      period_tokens: 0
    }
  },
  market: {
    status: '',
    job_posts: [],
    job_wanted: []
  }
}

export {
  colors,
  address,
  client,
  fileData,
  link,
  review,
  bullet,
  constructionTrades,
  company
}
