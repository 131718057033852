import { acceptHMRUpdate, defineStore } from 'pinia'

export const useNotifyStore = defineStore('notify', {
  state: () => ({
    notifications: []
  }),
  actions: {

    openNotify (type, title, text, timeout = 5000) {
      const id = this.notifications.length
      const notification = {
        id,
        type,
        title,
        text,
        timeout,
        isOpen: true,
        close () { this.isOpen = false }
      }
      this.notifications.push(notification)
      return ({
        notification,
        index: id
      })
    },

    closeNotify (index) {
      this.notifications[index].close()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotifyStore, import.meta.hot))
}
