
export const drywaller = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Transform Your Space with Expert Drywall Services',
        body: 'From flawless finishes to intricate textures, our team of skilled drywallers will bring your vision to life with precision and attention to detail.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are passionate about transforming your space with exceptional drywall services. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring a flawless finish that exceeds your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Drywall Services',
        headline: 'Our Comprehensive Drywall Solutions',
        body: 'From flawless finishes to intricate textures, our team is dedicated to delivering exceptional results that transform your space.'
      },
      bullets: [
        {
          title: 'Drywall Installation',
          text: 'Trust our skilled drywallers to install new drywall that meets your specific needs and style preferences. We use only the highest-quality materials to ensure a durable and long-lasting finish.'
        },
        {
          title: 'Drywall Repair',
          text: 'Whether you have a small hole or extensive damage, our team will provide expert drywall repair services to restore your walls to their former glory.'
        },
        {
          title: 'Texture Matching',
          text: 'Our team specializes in texture matching, ensuring that any repairs blend seamlessly with your existing texture. Trust us to restore your walls to their original look and feel.'
        },
        {
          title: 'Soundproofing',
          text: 'Reduce noise and enhance your space with our professional soundproofing services. We use high-quality materials to create a quieter, more comfortable environment.'
        }
      ]
    },

    reviews: {
      bullets: [
        {
          name: 'John S',
          rating: 5,
          location: 'Denver, CO',
          text: 'The drywall work done by Them was exceptional! They were prompt, professional, and cleaned up after themselves. I highly recommend their services.'
        },
        {
          name: 'Sarah H',
          rating: 4,
          location: 'Golden, CO',
          text: 'I had a hole in my wall and they came out to repair it. They did a great job and it looks like it never even happened. The only reason I gave them 4 stars is because they were a bit late for the appointment.'
        },
        {
          name: 'Mike R',
          rating: 5,
          location: 'Arvada, CO',
          text: 'I had some texture damage on my walls and they were able to match it perfectly. They were professional and the price was reasonable. I will definitely use their services again.'
        }
      ]
    },

    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional drywall projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Drywall Services',
        body: 'With a commitment to excellence and a passion for delivering stunning results, our team of professional drywallers is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for transforming homes.'
      }
    },
    features: {
      text: {
        overline: 'Premium Drywall Services',
        headline: 'Our Comprehensive Drywall Solutions',
        body: 'From flawless finishes to intricate textures, our team is dedicated to delivering exceptional results that transform your space.'
      },
      bullets: [
        {
          title: 'Drywall Installation',
          text: 'Trust our skilled drywallers to install new drywall that meets your specific needs and style preferences. We use only the highest-quality materials to ensure a durable and long-lasting finish.'
        },
        {
          title: 'Drywall Repair',
          text: 'Whether you have a small hole or extensive damage, our team will provide expert drywall repair services to restore your walls to their former glory.'
        },
        {
          title: 'Texture Matching',
          text: 'Our team specializes in texture matching, ensuring that any repairs blend seamlessly with your existing texture. Trust us to restore your walls to their original look and feel.'
        },
        {
          title: 'Soundproofing',
          text: 'Reduce noise and enhance your space with our professional soundproofing services. We use high-quality materials to create a quieter, more comfortable environment.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional drywall projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Drywall Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our drywall services. From questions about pricing and scheduling, to tips for preparing your home, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Drywall Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your drywall project.'
      },
      bullets: [
        {
          title: 'How long will it take to complete my drywall project?',
          text: 'The time required to complete your drywall project will depend on a variety of factors, such as the size of the space, the scope of the project, and any necessary preparations. We will provide you with a detailed timeline before beginning work, and keep you informed throughout the process.'
        },
        {
          title: 'What types of drywall materials do you use?',
          text: 'We use only the highest-quality drywall materials and products to ensure a flawless finish that lasts. Our team can provide recommendations based on your needs and preferences, and we are happy to work with any brand of drywall materials of your choice.'
        },
        {
          title: 'Will the drywall process be disruptive to my daily routine?',
          text: 'We understand that having your space drywalled can be an inconvenience, and we will do everything we can to minimize disruption to your daily routine. Our team will work efficiently and cleanly, and will be respectful of your home or business throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Transform Your Space?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Drywallers',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Contact Us Today',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our drywall services. Our team is standing by to answer your questions and help you transform your space with exceptional drywall solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate for Your Drywall Project',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for Drywall Project Pricing?',
        body: 'We understand that you want to make informed decisions about your drywall project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your space and drywall needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }

}
