<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { cloneDeep } from 'lodash'
import { address as addressRef } from '@/utils'
import { loader } from '@/composables/GoogleMaps.js'
import { XMarkIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => addressRef
  },
  label: {
    type: [String, Number],
    default: 'Address'
  },
  format: {
    type: String,
    default: 'full'
  },
  required: {
    type: Boolean,
    default: false
  },
  altColor: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'place_changed'])

const autocompleteInput = ref(props.modelValue)
const listeners = ref([])

onMounted(() => {
  const input = autocompleteInput.value
  loader.load().then(() => {
    const autocomplete = new google.maps.places.Autocomplete(input, {
      types: ['address'],
      componentRestrictions: { country: 'us' }
    })

    const listener = autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()

      if (!place.geometry) {
        console.log('No details available for input: ', place.name)
        return
      }

      const obj = {}
      obj.formatted = place.formatted_address
      obj.street = place.name
      obj.lat = place.geometry.location.lat()
      obj.long = place.geometry.location.lng()

      place.address_components.forEach((c) => {
        switch (c.types[0]) {
          case 'sublocality_level_1': //  Bronx
            obj.area = c.short_name
            break
          case 'locality': //  Los Angeles
            obj.city = c.short_name
            break
          case 'administrative_area_level_1': // Some countries NO states
            obj.state = c.short_name
            break
          case 'postal_code': // Zip Code
            obj.zip = c.short_name
            break
          case 'country': // Country
            obj.country = c.short_name
            break
        }
      })
      emit('update:modelValue', obj)
    })

    listeners.value.push(listener)
  })
})

onUnmounted(() => {
  // Remove the event listeners to prevent memory leaks
  listeners.value.forEach((listener) => {
    google.maps.event.removeListener(listener)
  })
  listeners.value = []
})

function clearField () {
  emit('update:modelValue', cloneDeep(addressRef))
}

// function geoBounds () {
//   return {
//     north: geolocation.value.lat + 1.5, // + 103 miles
//     south: geolocation.value.lat - 1.5, // - 103 miles
//     east: geolocation.value.long + 1.5, // + 103 miles
//     west: geolocation.value.long - 1.5 // - 103 miles}
//   }
// }

</script>

<template>
  <div class="w-full px-0.5">
    <div class="flex justify-between">
      <label
        v-if="label"
        :for="label"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >{{ label }} </label>

      <label
        v-if="!props.required"
        :for="label"
        class="text-sm text-gray-400"
      >Optional</label>
    </div>

    <div :class="[ label ? 'mt-2': null, 'w-full relative']">
      <input
        id="autocomplete"
        ref="autocompleteInput"
        :name="label"
        :class="[
          'border-gray-300 focus:border-theme-primary-500 focus:ring-theme-primary-500 ',
          altColor ? 'bg-gray-50' : 'bg-transparent',
          'block w-full rounded-md shadow-sm sm:text-sm pr-8',
        ]"
        :value="format === 'street' ? modelValue.street : modelValue.formatted"
        placeholder=""
        type="text"
        v-bind="$attrs"
      >

      <!-- clearable -->
      <div class="absolute inset-y-0 right-2 top-0 flex items-center">
        <XMarkIcon
          aria-hidden="true"
          class="h-5 w-5 text-gray-400 hover:text-gray-700"
          @click="clearField"
        />
      </div>
    </div>
  </div>
</template>
