<script setup>
/* eslint-disable camelcase */
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useFormatDate } from '@/utils'
import { useCalendar } from '@/composables/useCalendar.js'
import { useCalendarStore } from '@/stores/useCalendarStore.js'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'
import googleCalendarAPI from '@/apis/googleCalendar.js'
import { useAuthStore } from '@/stores/useAuthStore.js'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { useModalStore } from '@/stores/useModalStore.js'
import { useNotifyStore } from '@/stores/useNotifyStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const {
  current,
  selectedDate,
  weeklyStartDate,
  daysOfWeek,
  dailyTimeSlots,
  resetCurrentDates,
  monthToLongFormatDate,
  formatTime,
  formatSelectedDate,
  usePrevCalendarPeriod,
  useGoToToday,
  getWeekDay,
  useNextCalendarPeriod,
  generateDateArray
} = useCalendar()

const authStore = useAuthStore()
const modalStore = useModalStore()
const notifyStore = useNotifyStore()
const websiteStore = useWebsiteStore()
const calendarStore = useCalendarStore()

const dayTimes = ref([])
const dateArray = ref([])
const selectedDay = ref(null)
const currentView = ref('schedule')
const views = ['schedule', 'info', 'submit']
const pendingEvent = ref(false)

const event = ref({
  summary: '',
  location: '',
  start: {
    dateTime: '',
    timeZone: ''
  },
  end: {
    dateTime: '',
    timeZone: ''
  },
  attendees: []
})

const formData = ref({
  name: '',
  email: '',
  phone: '',
  address: { formatted: '' },
  description: ''
})

const schedule = computed(() => websiteStore?.site?.schedule)
const user = computed(() => authStore.user)

onMounted(async () => {
  dateArray.value = await generateDateArray(current.year, current.month)
  await handleGoogleCalendar()
})

function goBack () {
  const currentIndex = views.indexOf(currentView.value)
  if (currentIndex > 0) {
    currentView.value = views[currentIndex - 1]
  }
}

function goNext () {
  const currentIndex = views.indexOf(currentView.value)
  if (currentIndex < views.length - 1) {
    currentView.value = views[currentIndex + 1]
  } else {
    addCalendarEvent()
  }
}

async function handleGoogleCalendar () {
  const { refresh_token } = schedule.value.google
  const calendars = websiteStore?.site?.schedule.google.calendars.map(calendar => ({ id: calendar.id }))
  const currentDate = new Date()
  const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0)
  const endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999)
  const timeMin = startOfDay.toISOString()
  const timeMax = endOfDay.toISOString()

  try {
    if (refresh_token) {
      await calendarStore.refreshAccessToken(refresh_token)
      const events = await googleCalendarAPI.fetchEvents(calendarStore.google.access_token, calendars, timeMin, timeMax)
      createTimeArray(current.dateString, current.weekday, events)
    }
  } catch (e) {
    console.log(e)
  }
}

async function prevCalendarPeriod () {
  usePrevCalendarPeriod('month')
  dateArray.value = await generateDateArray(current.year, current.month)
}

async function nextCalendarPeriod () {
  useNextCalendarPeriod('month')
  dateArray.value = await generateDateArray(current.year, current.month)
}

async function selectDay (day) {
  selectedDate.value = day.date || new Date().toISOString().slice(0, 10)
  selectedDay.value = day
  dateArray.value.forEach((date) => {
    date.isSelected = date?.date === day.date
  })

  const selectDate = new Date(day.date)
  const startOfDay = new Date(selectDate.getFullYear(), selectDate.getMonth(), selectDate.getDate() + 1, 0, 0, 0, 0)
  const endOfDay = new Date(selectDate.getFullYear(), selectDate.getMonth(), selectDate.getDate() + 1, 23, 59, 59, 999)
  const timeMin = startOfDay.toISOString()
  const timeMax = endOfDay.toISOString()
  const calendars = schedule.value.google.calendars.map(calendar => ({ id: calendar.id }))

  try {
    if (calendarStore.google.access_token && calendarStore.google.expires_in) {
      const events = await googleCalendarAPI.fetchEvents(calendarStore.google.access_token, calendars, timeMin, timeMax)
      createTimeArray(day.date, day.weekday, events)
    }
  } catch (e) {
    console.log(e)
  }
}

function createTimeArray (dateString, weekday, events) {
  const availability = schedule.value.available.days
  const timeRanges = schedule.value.available.time_ranges
  const timeArray = []

  timeRanges.forEach((range, index) => {
    const { start, end } = range
    const startHour = parseInt(start.time24h.slice(0, 2))
    const endHour = parseInt(end.time24h.slice(0, 2))

    for (let hour = startHour; hour < endHour; hour++) {
      const startHourFormat = hour.toString().padStart(2, '0')
      const endHourFormat = (hour + 1).toString().padStart(2, '0')
      const timeFormat = hour < 12 ? ' am' : ' pm'
      const displayHour = hour > 12 ? hour - 12 : hour
      const time = `${displayHour}:00${timeFormat}`

      const startTime = `${dateString}T${startHourFormat}:00:00`
      const endTime = `${dateString}T${endHourFormat}:00:00`

      const available = availability.includes(weekday)

      // Check if the time slot is still available based on events
      const eventTimeExists = events.some((event) => {
        if (event.start.dateTime && event.end.dateTime) {
          const eventStartHour = parseInt(event.start.dateTime.slice(11, 13))
          const eventEndHour = parseInt(event.end.dateTime.slice(11, 13))
          return hour >= eventStartHour && hour < eventEndHour
        }
        return true
      })

      // Check if the time slot is at least an hour in the future
      const currentDateTime = new Date()
      const currentHour = currentDateTime.getHours()
      const currentDateString = currentDateTime.toISOString().split('T')[0]
      const isFutureTime = dateString > currentDateString || (dateString === currentDateString && hour >= currentHour + 1)

      timeArray.push({
        time,
        start: startTime,
        end: endTime,
        available: available && !eventTimeExists && isFutureTime
      })
    }

    // Include time slots between ranges and mark them as unavailable
    if (index !== timeRanges.length - 1) {
      const nextRange = timeRanges[index + 1]
      const nextStartHour = parseInt(nextRange.start.time24h.slice(0, 2))

      for (let hour = endHour; hour < nextStartHour; hour++) {
        const timeFormat = hour < 12 ? ' am' : ' pm'
        const displayHour = hour > 12 ? hour - 12 : hour
        const time = `${displayHour}:00${timeFormat}`

        timeArray.push({ time, available: false })
      }
    }
  })

  dayTimes.value = timeArray
  return timeArray
}

watch(() => currentView.value,
  (newLocation) => {
    updateEvent()
  })

function selectTime (slot) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  event.value.start.dateTime = slot.start
  event.value.start.timeZone = timeZone
  event.value.end.dateTime = slot.end
  event.value.end.timeZone = timeZone
}

function nextEnabled () {
  console.log(currentView.value)
  switch (currentView.value) {
    case 'schedule':
      return event.value.start.dateTime && event.value.end.dateTime
    case 'info':
      return formData.value.name && event.value.attendees.length > 1 && formData.value.address.formatted
    case 'submit':
      return true
  }
}

function updateEvent () {
  event.value.summary = `Meeting: ${formData.value?.name} & ${websiteStore?.site?.global?.company?.name}`

  event.value.attendees = [{ email: user?.value?.email }, { email: formData.value?.email }]

  event.value.location = formData.value.address.formatted
}

async function addCalendarEvent () {
  pendingEvent.value = true
  try {
    await googleCalendarAPI.addEvent(calendarStore.google.access_token, 'primary', event.value)

    formData.value.description = createDescription(event.value)

    pendingEvent.value = false
    modalStore.openModal(
      'success',
      'Thank you!',
      'Your appoint has been successfully booked. An invite has been sent to your email and calendar. If you have any questions feel free to contact us.',
      { name: 'Home Page', action: () => router.push(websiteStore.getRoutePath('home')) }
    )
    const result = await websiteStore.createLead(formData.value, 'event')

    if (result.success) {
      resetUserInfo()
    }
  } catch (e) {
    pendingEvent.value = false
    console.log(e)
    notifyStore.openNotify(
      'error',
      'Error',
      'There was an error scheduling your appointment. Please refresh the page and try again.'
    )
  }
}

function createDescription (data) {
  return `Client Scheduled Event\n\n${data.summary}\n\nDate: ${useFormatDate(data.start.dateTime)}\n\nTime: ${formatTime(data.start.dateTime)} to ${formatTime(data.end.dateTime)}`
}

function resetUserInfo () {
  event.value = {
    summary: '',
    location: '',
    start: {
      dateTime: '',
      timeZone: ''
    },
    end: {
      dateTime: '',
      timeZone: ''
    },
    attendees: []
  }

  formData.value = {
    name: '',
    phone: '',
    email: '',
    address: { formatted: '' },
    description: ''
  }
  currentView.value = 'schedule'
}
</script>

<template>
  <div class="@container">
    <!--  header -->
    <theme-base-section class="-mb-12">
      <div class="max-w-2xl mx-auto text-center ">
        <theme-base-overline>
          {{ props.data?.content?.text?.overline }}
        </theme-base-overline>

        <theme-base-title>
          {{ props.data?.content?.text?.headline }}
        </theme-base-title>

        <theme-base-subtitle>
          {{
            props.data?.content?.text?.body }}
        </theme-base-subtitle>
      </div>
    </theme-base-section>

    <!-- Scheduling -->
    <theme-base-section v-show="currentView === 'schedule'">
      <div class="@md:grid @md:grid-cols-2 @md:divide-x @md:divide-gray-200">
        <div class="@md:pr-14">
          <div class="flex items-center">
            <h2 class="flex-auto text-base font-semibold text-gray-900">
              {{ `${ monthToLongFormatDate(current.month) } ${ current.year }` }}
            </h2>
            <!-- left -->
            <button
              type="button"
              class="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              @click="prevCalendarPeriod()"
            >
              <span class="sr-only">Previous month</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>

            <!-- right -->
            <button
              type="button"
              class="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              @click="nextCalendarPeriod()"
            >
              <span class="sr-only">Next month</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          <div class="mt-10 grid grid-cols-7 text-center text-xs/6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div class="mt-2 grid grid-cols-7 text-sm">
            <div
              v-for="(day, dayIdx) in dateArray"
              :key="day"
              :class="[dayIdx > 6 && 'border-t border-gray-200', 'py-2']"
            >
              <button
                type="button"
                :disabled="!schedule.available.days.includes(day.weekday)"
                :class="[
                  day.isSelected && 'text-white',
                  day.isSelected && day.isToday && 'bg-theme-primary-600',
                  day.isSelected && !day.isToday && 'bg-gray-900',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  !day.isSelected && 'hover:bg-gray-200',
                  !day.isSelected && day.isToday && 'text-theme-primary-600',
                  !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-gray-900',
                  !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-gray-400',
                  !schedule.available.days.includes(day.weekday) && 'cursor-not-allowed opacity-50'
                ]"
                class="mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                @click="selectDay(day)"
              >
                <time :datetime="day.date">
                  {{ day.date.split('-').pop().replace(/^0/, '') }}
                </time>
              </button>
            </div>
          </div>
        </div>

        <section class="mt-12 @md:mt-0 @md:pl-14">
          <h2 class="text-base/6 font-semibold text-gray-900">
            Availability for
            <time :datetime="formatSelectedDate(selectedDate)">
              {{ formatSelectedDate(selectedDate) }}
            </time>
          </h2>
          <ol class="mt-4 text-sm/6 text-gray-500 grid grid-cols-3 gap-4">
            <li
              v-for="slot in dayTimes"
              :key="slot"
            >
              <button
                type="button"
                :disabled="!slot.available"
                :class="[
                  slot.available ? 'bg-theme-primary-600 hover:bg-theme-primary-500 text-white' : 'bg-gray-100 text-gray-400 cursor-not-allowed whitespace-nowrap',
                  event.start.dateTime === slot.start && 'ring-2 ring-offset-2 ring-theme-primary-600 bg-theme-primary-800'
                ]"
                class="w-full rounded-md px-3 py-2 text-sm font-semibold"
                @click="selectTime(slot)"
              >
                {{ slot.time }}
              </button>
            </li>
          </ol>
        </section>
      </div>
    </theme-base-section>

    <theme-base-section v-show="currentView === 'info'">
      <div class="space-y-8 max-w-lg mx-auto">
        <h2 class="text-base/6 font-semibold text-gray-900">
          Contact Information
        </h2>

        <theme-base-input
          v-model.trim="formData.name"
          required
          clearable
          label="First Name"
          autocomplete="given-name"
          @blur="updateEvent('name')"
        />

        <theme-base-input-phone
          v-model="formData.phone"
          required
          clearable
          autocomplete="tel"
          label="Phone Number"
        />

        <theme-base-input
          v-model="formData.email"
          required
          clearable
          label="Email"
          autocomplete="email"
          @blur="updateEvent('email')"
        />

        <theme-base-google-autocomplete
          v-model="formData.address"
          required
          label="Address"
        />
      </div>
    </theme-base-section>

    <theme-base-section v-show="currentView === 'submit'">
      <div class="max-w-lg mx-auto space-y-6 border-b border-gray-900/10 divide-y divide-gray-900/10">
        <h2 class="text-base/6 font-semibold text-gray-900">
          Confirm Information
        </h2>
        <div class="flex justify-between items-center pt-6 px-4">
          <div class="text-sm font-semibold text-gray-900">
            Event
          </div>
          <div class="text-sm text-gray-900">
            {{ event.summary }}
          </div>
        </div>

        <div class="flex justify-between items-center pt-6 px-4">
          <div class="text-sm font-semibold text-gray-900">
            Date
          </div>
          <div class="text-sm text-gray-900">
            {{ useFormatDate(event.start.dateTime) }}
          </div>
        </div>

        <div class="flex justify-between items-center pt-6 px-4">
          <div class="text-sm font-semibold text-gray-900">
            Time
          </div>
          <div class="text-sm text-gray-900">
            {{ formatTime(event.start.dateTime) }} to  {{ formatTime(event.end.dateTime) }}
          </div>
        </div>

        <div class="flex justify-between items-center py-4 px-4">
          <div class="text-sm font-semibold text-gray-900">
            Location
          </div>
          <div class="text-sm text-gray-900">
            {{ event.location }}
          </div>
        </div>

        <div class="flex justify-between items-center py-4 px-4">
          <div class="text-sm font-semibold text-gray-900">
            Attendees
          </div>
          <div>
            <div
              v-for="attendee in event.attendees"
              :key="attendee.email"
              class="text-sm text-gray-900"
            >
              {{ attendee.email }}
            </div>
          </div>
        </div>
      </div>
    </theme-base-section>

    <div class="flex justify-between items-center mx-8 mb-8">
      <theme-base-button
        v-show="currentView !== 'schedule'"
        outlined
        @click="goBack()"
      >
        Back
      </theme-base-button>
      <theme-base-button
        :disabled="!nextEnabled()"
        :loading="pendingEvent"
        class="ml-auto"
        @click="goNext()"
      >
        {{ currentView === 'submit' ? 'Schedule Appointment' : 'Next' }}
      </theme-base-button>
    </div>
  </div>
</template>
