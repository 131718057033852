<script setup>
import { ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useToggleArrayItem } from '@/utils/index.js'

const props = defineProps({
  modelValue: {
    type: [Number, String, Object, Array],
    default: null
  },
  default: {
    type: [Number, String, Object, Array],
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  multiple: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array,
    default () {
      return []
    }
  }
})
const emit = defineEmits(['update:modelValue'])
const selected = ref(null)

onBeforeMount(() => {
  selected.value = useCloneDeep(props.modelValue)
})

onBeforeUnmount(() => {
  selected.value = null
})

function isSelected (val) {
  return selected.value.includes(val)
}

function updateInput (val) {
  emit('update:modelValue', selected.value)
}

function clickedDismiss (val) {
  useToggleArrayItem(selected.value, val)
}

</script>

<template>
  <Listbox
    v-model="selected"
    multiple
    class="px-0.5"
  >
    <ListboxLabel class="block text-sm font-medium text-gray-700 capitalize">
      {{ label }}
    </ListboxLabel>
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm min-h-full overflow-hidden"
        style="min-height: 38px"
      >
        <base-badge
          v-for="item in selected"
          :key="item"
          class="truncate"
          :label="item"
          @on-dismiss="clickedDismiss(item)"
        />
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in options"
            :key="option.value ?? option"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
            @click="updateInput"
          >
            <li :class="[active ? 'text-white bg-blue-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 capitalize']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                option.name || option
              }}</span>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-blue-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
