const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/pages/auth.vue'),
    props: true,
    meta: { layout: 'layout-blank' }
  },

  {
    path: '/create',
    name: 'Create',
    meta: { requiresAuth: true, layout: 'layout-blank' },
    component: () => import('@/pages/editor/create.vue')
  },

  // Client facing pages
  {
    path: '/:id',
    component: () => import('@/layouts/default.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'Home',
        props: true,
        component: () => import('@/pages/[...id]/[...path].vue')
      },
      {
        path: ':path',
        name: 'Page View',
        props: true,
        component: () => import('@/pages/[...id]/[...path].vue')
      },
      {
        path: 'success',
        name: 'Instate Estimate',
        component: () => import('@/pages/[...id]/Success.vue')
      }
      /* {
        path: 'estimate/create',
        name: 'Instate Estimate',
        component: () => import('@/pages/[...id]/instant-estimate.vue')
      }, */
      /* {
        path: 'setup',
        name: 'Setup',
        component: () => import('@/pages/[...id]/instant-estimate.vue')
      } */
    ]
  },

  // Editor pages
  {
    path: '/',
    component: () => import('@/layouts/LayoutEditor.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/pages/editor/settings.vue')
      },
      {
        path: 'billing',
        name: 'Plans & Billing',
        component: () => import('@/pages/editor/billing.vue')
      },
      {
        path: '',
        component: () => import('@/layouts/default.vue'),
        props: true,
        children: [
          {
            path: 'editor',
            name: 'Editor',
            component: () => import('@/pages/editor/[...path].vue'),
            children: [
              {
                path: ':id',
                name: 'Editor Page View',
                props: true,
                component: () => import('@/pages/editor/[...path].vue')
              }
            ]
          }
        ]
      }
    ]
  },

  {
    path: '/restricted',
    name: 'Restricted',
    component: () => import('@/pages/Upgrade404.vue')
  },

  {
    path: '/404',
    component: () => import('@/pages/Page404.vue')
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/Page404.vue')
  }
]

export default routes
