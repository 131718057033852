import { ref } from 'vue'

const screen = ref({
  width: 0,
  height: 0,
  is: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false
  }
})

const breakpoints = {
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1536
}

function setSizes (val) {
  Object.keys(screen.value.is).forEach(key => {
    screen.value.is[key] = key === val
  })
}

function handleResize () {
  screen.value.width = window.innerWidth
  screen.value.height = window.innerHeight
  if (screen.value.width <= breakpoints.xs) {
    setSizes('xs')
  } else if (screen.value.width >= breakpoints.xs && screen.value.width <= breakpoints.sm) {
    setSizes('sm')
  } else if (screen.value.width >= breakpoints.sm && screen.value.width <= breakpoints.md) {
    setSizes('md')
  } else if (screen.value.width >= breakpoints.md && screen.value.width <= breakpoints.lg) {
    setSizes('lg')
  } else {
    setSizes('xl')
  }
}

// Initialize screen data
handleResize()

/**
 * Add listener to handleResize
 * Event fires when the floating video window has been resized.
 * https://developer.mozilla.org/en-US/docs/Web/API/PictureInPictureWindow/resize_event
 *
 */
window.addEventListener('resize', handleResize, { passive: true })

export default {
  install (app) {
    app.config.globalProperties.$screen = screen.value
  }
}
