<script setup>
import { computed, ref } from 'vue'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import {
  Bars3Icon,
  CalendarDaysIcon, EnvelopeIcon,
  HomeIcon,
  QuestionMarkCircleIcon, UserCircleIcon,
  UserIcon,
  XMarkIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  global: {
    type: Object,
    default: () => {}
  },
  editor: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ''
  }
})

const useWebsite = useWebsiteStore()

const isOpen = ref(false)

const navigation = computed(() => props.global.page_navigation)

// Filter the navigation array based on the condition
const filteredNavigation = navigation.value.filter(item => {
  // Check if the item component is "nav-item" and it has a link
  if (item.link && item.link.name === 'Schedule') {
    // Check if the schedule is enabled
    return useWebsite.site.schedule.is_enabled
  }

  // Include other items that don't match the condition
  return true
})

function setIsOpen (value) {
  isOpen.value = value
}

function routeIcon (item) {
  switch (item.name) {
    case 'Home':
      return HomeIcon
    case 'About':
      return UserCircleIcon
    case 'FAQ':
      return QuestionMarkCircleIcon
    case 'Schedule':
      return CalendarDaysIcon
    case 'Contact us':
      return EnvelopeIcon
  }
}
</script>

<!-- tailwind compliant -->
<template>
  <section class="@container">
    <div class="max-w-screen-xl mx-auto">
      <div class="my-4 px-4 @xl:px-0 flex items-center justify-between">
        <router-link
          v-if="props.global?.logo?.url"
          :to="useWebsite.getRoutePath('home')"
        >
          <img
            :src="props.global?.logo?.url"
            alt=""
            class="h-12 w-auto rounded-md"
          >
        </router-link>

        <div class="flex items-center ">
          <div v-if="filteredNavigation.length" class="hidden @sm:ml-12 @sm:block @sm:space-x-8">
            <router-link
              v-for="item in filteredNavigation"
              :key="item.uid"
              :disabled="useWebsite.isEditor"
              :to="useWebsite.getRoutePath(item.link.href)"
              class="text-sm/6 font-semibold text-gray-900 hover:bg-gray-100 px-4 py-2.5 rounded-md"
            >
              {{ item.name }}
            </router-link>
          </div>

          <button
            :disabled="useWebsite.isEditor"
            class="rounded-md text-gray-700 @sm:hidden"
            type="button"
            @click.prevent="setIsOpen(true)"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" class="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>

    <TransitionRoot
      :show="isOpen"
      as="template"
    >
      <Dialog class="relative z-50 lg:hidden" @close="setIsOpen">
        <!-- background -->
        <TransitionChild
          appear
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" />
        </TransitionChild>

        <div class="fixed inset-0 flex right-0">
          <TransitionChild
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="h-screen w-72 bg-white">
              <div class="p-4">
                <div class="flex items-center justify-between">
                  <router-link
                    v-if="props.global?.logo?.url"
                    :to="useWebsite.getRoutePath('home')"
                    href="#"
                  >
                    <span class="sr-only">{{ props.global?.company?.name }}</span>
                    <img
                      :src="props.global?.logo?.url"
                      alt=""
                      class="h-12 w-auto @sm:h-16 rounded-md"
                    >
                  </router-link>

                  <button @click="setIsOpen(false)">
                    <XMarkIcon class="text-gray-400 h-6 w-6" />
                  </button>
                </div>

                <nav class="mt-6 space-y-2">
                  <div
                    v-for="item in navigation"
                    :key="item.uid"
                    class="flex items-center gap-3"
                  >
                    <div>
                      <component :is="routeIcon(item)" class="text-gray-500 h-6 w-6" />
                    </div>
                    <router-link
                      :to="useWebsite.getRoutePath(item.link.href)"
                      class="-mx-3 block rounded-lg py-2 px-3 text-base/7 font-semibold text-gray-900 hover:bg-gray-400/10"
                      @click="setIsOpen(false)"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </section>
</template>
