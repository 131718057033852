<script setup>
import { ref } from 'vue'
import { useElementSize, useScroll } from '@vueuse/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { useRandomImage } from '@/utils'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const el = ref(null)
const { x } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)
</script>

<!-- Tailwind Compliant -->
<template>
  <theme-base-section
    v-if="props.data?.content?.bullets?.length"
    class="overflow-hidden bg-gray-50 relative"
  >
    <div class="flex items-center">
      <!-- left button -->
      <button
        class="absolute z-10 left-8 xl:left-24 2xl:left-48 rounded-full p-2 hidden @sm:block"
        @click="x -= (width * 0.7)"
      >
        <ChevronLeftIcon class="text-gray-400 hover:text-gray-900 h-6 w-6" />
      </button>

      <!-- review container  -->
      <div
        ref="el"
        class="flex flex-nowrap items-center overflow-x-auto snap-mandatory snap-x hide-scrollbar scrollbar--none w-screen"
      >
        <!-- review -->
        <div
          v-for="(item, index) in props.data?.content?.bullets"
          :key="index"
          class=" snap-center px-8 min-w-[100vw]"
        >
          <blockquote class="mt-10 ">
            <!-- quote -->
            <div class="mx-auto max-w-2xl text-center text-2xl/9 font-medium text-gray-900 ">
              <p>
                {{ item?.text }}
              </p>
            </div>

            <!-- reviewer info -->
            <div class="mt-8">
              <div class="flex items-center justify-center">
                <!-- image -->
                <div class="flex-shrink-0">
                  <theme-base-img
                    :img-src="useRandomImage()"
                    class="h-10 w-10 rounded-full object-cover"
                  />
                </div>

                <!-- reviewer info -->
                <div class="text-center ml-4">
                  <div class="text-base font-medium text-gray-900 text-left">
                    {{ item?.name }}
                  </div>

                  <div class="text-sm font-medium text-gray-500 text-left">
                    {{ item?.location }}
                  </div>
                </div>
              </div>
            </div>
          </blockquote>
        </div>
      </div>

      <!-- right button -->
      <button
        class="absolute right-8 lg:right-24 xl:right-48 z-10 rounded-full p-2 hidden @sm:block"
        @click="x += (width * 0.7)"
      >
        <ChevronRightIcon class="text-gray-400 hover:text-gray-900 h-6 w-6" />
      </button>
    </div>
  </theme-base-section>
</template>
