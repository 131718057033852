<script setup>
import { CheckIcon } from '@heroicons/vue/20/solid'
import { computed, ref } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const bullets = computed(() => {
  if (!props.data?.content?.bullets?.length) {
    return serviceList.value
  } else {
    return props.data?.content?.bullets
  }
})

const serviceList = ref([
  {
    title: 'Protect Your Home with Exterior Painting',
    text: 'Ensure your home\'s protection with professional exterior painting. Our team delivers results that look great and last.'
  },
  {
    title: 'Coordinate Your Rooms with Room Painting',
    text: 'Create a harmonious living space with expert room painting. Our team delivers results that reflect your personal style.'
  },
  {
    title: 'Revitalize Your Kitchen with Cabinet Painting',
    text: 'Transform your kitchen with expert cabinet painting. Our team delivers flawless results for a fresh, functional look.'
  }
])
</script>

<template>
  <div class="@container">
    <div class="overflow-hidden bg-white py-24 @sm:py-32">
      <div class="mx-auto max-w-7xl @md:px-6 @lg:px-8">
        <div class="grid grid-cols-1 gap-y-16 gap-x-8 @sm:gap-y-20 @lg:grid-cols-2 @lg:items-start">
          <div class="px-6 @lg:px-0 @lg:pt-4 @lg:pr-4 order-last @lg:order-first">
            <div class="mx-auto max-w-2xl @lg:mx-0 @lg:max-w-lg">
              <h2 class="text-lg font-semibold leading-8 tracking-tight text-theme-primary-600">
                {{ props.data?.content?.text?.overline || 'Expert Services' }}
              </h2>

              <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 @sm:text-4xl">
                {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
              </p>

              <p class="mt-6 text-lg leading-8 text-gray-600">
                {{
                  props.data?.content?.text?.body ||
                    'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
                }}
              </p>

              <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 @lg:max-w-none">
                <div
                  v-for="(item, bulletIdx) in bullets"
                  :key="bulletIdx"
                  class="relative pl-9"
                >
                  <dt class="inline font-semibold text-gray-900">
                    <CheckIcon
                      aria-hidden="true"
                      class="absolute top-1 left-1 h-5 w-5 text-theme-primary-600"
                    />
                    {{ item?.title }}
                  </dt>
                  {{ ' ' }}
                  <dd class="inline">
                    {{ item?.text }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <!--  -->
          <div class="pl-6 @sm:px-6 @lg:px-0 my-auto">
            <div class="overflow-hidden  @sm:mx-auto @sm:max-w-2xl rounded-l-3xl @sm:rounded-3xl shadow-2xl">
              <div class="mx-auto max-w-2xl @sm:mx-0 @sm:max-w-none ">
                <theme-base-img
                  :img-src="props.data?.content?.background_image?.url"
                  alt="Featured project"
                  class="w-[57rem] max-w-none rounded-3xl object-cover overflow-hidden "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
