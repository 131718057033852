<script setup>
import { useElementSize, useScroll } from '@vueuse/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'
import { useRandomImage } from '@/utils'
const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const el = ref(null)
const { x } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)

</script>

<template>
  <section
    v-if="props.data?.content?.bullets?.length"
    class="@container bg-gray-100"
  >
    <div class="relative mx-auto max-w-7xl px-4 @sm:px-6 py-8  @lg:px-8 @lg:py-20">
      <div class="relative flex items-center">
        <!--  left button -->
        <button
          class="absolute z-20 rounded-full mx-4 mr-6 p-2 hidden @md:block"
          @click="x -= (width * 0.7)"
        >
          <ChevronLeftIcon class="text-gray-400 hover:text-gray-900 h-6 w-6" />
        </button>

        <!--  review container -->
        <div
          ref="el"
          class="flex flex-nowrap overflow-x-auto snap-mandatory snap-x hide-scrollbar scrollbar--none w-screen space-x-8"
        >
          <div
            v-for="(item, index) in props.data?.content?.bullets"
            :key="index"
            class="relative col-span-1 @lg:min-h-[460px] snap-center min-w-fit flex items-center mx-auto px-8 @md:px-[10vw] py-20 @lg:py-0 "
          >
            <!--  large image -->
            <div class="hidden @lg:block @lg:flex-shrink-0">
              <theme-base-img
                :img-src="useRandomImage()"
                alt=""
                class="h-64 w-64 rounded-full object-cover xl:h-80 xl:w-80"
              />
            </div>

            <!--  review content container -->
            <div class="relative @lg:ml-10">
              <div class="relative">
                <!--  review -->
                <p class="text-2xl font-medium leading-9 text-gray-900">
                  {{ item?.text }}
                </p>

                <div class="flex mt-8">
                  <!--  small image -->
                  <div class="flex-shrink-0 @lg:hidden">
                    <theme-base-img
                      :img-src="useRandomImage()"
                      alt=""
                      class="h-12 w-12 rounded-full object-cover"
                    />
                  </div>

                  <!--  reviewer info -->
                  <div class="ml-4 @lg:ml-0">
                    <div class="text-base font-medium text-gray-900">
                      {{ item?.name }}
                    </div>
                    <div class="text-base font-medium text-theme-primary-600">
                      {{ item?.location }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="absolute right-0 z-10 rounded-full mx-4 mr-6 p-2 hidden @md:block"
          @click="x += (width * 0.7)"
        >
          <ChevronRightIcon class="text-gray-400 hover:text-gray-900 h-6 w-6" />
        </button>
      </div>
    </div>
  </section>
</template>
