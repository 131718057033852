<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  global: {
    type: Object,
    default: () => {}
  },
  editor: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ''
  }
})

function handleRouting (to) {
  const path = to ? (to === 'home' ? '' : to) : 'estimate'
  return props.editor ? `/editor/${path}` : `/${props.url}/${path}`
}

const navigation = computed(() => props.global.page_navigation)

</script>

<template>
  <section class="@container">
    <Popover class="relative bg-white z-10">
      <div class="flex items-center justify-between p-6 @md:justify-center @md:space-x-10">
        <router-link
          v-if="global?.logo?.url"
          :to="props.editor ? '/editor' : `/${props.url}`"
          class="flex"
        >
          <span class="sr-only">{{ global.company.name }}</span>
          <img
            :src="global?.logo?.url"
            alt=""
            class="h-12 w-auto @sm:h-16 rounded-md"
          >
        </router-link>
        <div class="-my-2 -mr-2 @md:hidden">
          <PopoverButton
            class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-theme-primary-500"
          >
            <span class="sr-only">Open menu</span>
            <Bars3Icon aria-hidden="true" class="h-6 w-6" />
          </PopoverButton>
        </div>
        <div class="hidden @md:block">
          <nav class="flex space-x-10">
            <template
              v-for="item in navigation"
              :key="item.uid"
            >
              <router-link
                v-if="item.name !== 'Estimate' || (item.name === 'Estimate' && global.enable_estimate)"

                :to="handleRouting(item.link.href)"
                class="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                {{ item.name }}
              </router-link>
            </template>
          </nav>
        </div>
      </div>

      <transition
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <PopoverPanel class="absolute inset-x-0 top-0 origin-top-right transform p-2 transition @md:hidden" focus>
          <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="px-5 pt-5 pb-6">
              <div class="flex items-center justify-between">
                <div>
                  <img
                    :src="global?.logo?.url "
                    alt="Your Company"
                    class="h-10 w-auto rounded"
                  >
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-theme-primary-500"
                  >
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon aria-hidden="true" class="h-6 w-6" />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <nav class="grid gap-6">
                  <template
                    v-for="item in navigation"
                    :key="item.uid"
                  >
                    <router-link
                      v-if="item.name !== 'Estimate' || (item.name === 'Estimate' && global.enable_estimate)"
                      :to="handleRouting(item.link.href)"
                      class="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                    >
                      <div class="ml-4 text-base font-medium text-gray-900">
                        {{ item.name }}
                      </div>
                    </router-link>
                  </template>
                </nav>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </section>
</template>
