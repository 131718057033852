// useRoute
import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes.js'
import { useWebsiteStore } from '@/stores/useWebsiteStore'
import { firebaseAuth } from '@/apis'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({ top: 0 }), 300)
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const isAuthorized = firebaseAuth.currentUser || localStorage.getItem('isAuthorized')

  const privateRoutesRegex = /^(\/(auth|editor|signin|sign-in|privacy|premium|terms|success|help|restricted|settings|billing|create|''))?$/i

  if (to?.meta?.requiresAuth && !isAuthorized) {
    // authorized route and not signed in
    next({ name: 'Auth' })
  } else if (isAuthorized && to.path === '/auth') {
    // signed in and route == auth reroute to editor
    next({ name: 'Editor' })
  } else if (!privateRoutesRegex.test(to.path) && !to.path.includes('editor')) {
    // possible company website fetch data
    const currentHostname = window.location.hostname
    const splitHostname = currentHostname.split('.')
    const tld = splitHostname.slice(-2).join('.')

    const path = to.path.split('/')

    if (['tradebox.biz', 'test-tradebox.biz'].includes(tld) || ['localhost', '127.0.0.1'].includes(currentHostname)) {
      await useWebsiteStore().fetchWebsiteData(path[1], 'path')
    } else {
      await useWebsiteStore().fetchWebsiteData(tld, 'domain')
    }

    next()
  } else {
    next()
  }
})

export default router
