<script setup>
import { ref } from 'vue'
import { useElementSize, useScroll } from '@vueuse/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'
import { useRandomImage } from '@/utils'

defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const el = ref(null)
const { x } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)

</script>
<template>
  <div class="@container">
    <div class="bg-white pt-16 @lg:py-24">
      <div class="bg-theme-primary-600 pb-16 @lg:relative @lg:z-10 @lg:pb-0">
        <div class="@lg:mx-auto @lg:grid @lg:max-w-7xl @lg:grid-cols-3 @lg:gap-8 @lg:px-8">
          <div class="relative @lg:-my-8">
            <div
              aria-hidden="true"
              class="absolute inset-x-0 top-0 h-1/2 bg-white @lg:hidden"
            />
            <div class="mx-auto max-w-md px-6 @sm:max-w-3xl @lg:h-full @lg:p-0">
              <div
                class="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl @sm:aspect-w-16 @sm:aspect-h-7 @lg:aspect-h-none @lg:h-full"
              >
                <theme-base-img
                  :img-src="useRandomImage()"
                  class="object-cover @lg:h-full @lg:w-full"
                />
              </div>
            </div>
          </div>
          <div class="mt-12 @lg:col-span-2 @lg:m-0 @lg:pl-8">
            <div class="mx-auto max-w-md px-6 @sm:max-w-2xl @lg:max-w-none @lg:px-0 @lg:py-20">
              <div
                ref="el"
                class="flex flex-nowrap w-full overflow-x-auto snap-mandatory snap-x hide-scrollbar scrollbar--none items-center"
              >
                <button
                  class="absolute z-10 hover:bg-white/10 rounded-full mx-4 -ml-8 p-2 hidden @md:block"
                  @click="x -= (width * 0.7)"
                >
                  <ChevronLeftIcon class="text-gray-200 h-6 w-6" />
                </button>
                <div
                  v-for="(item, index) in data.content.bullets"
                  :key="index"
                  class="relative col-span-1 snap-center min-w-fit @sm:px-20 flex justify-between items-center"
                >
                  <blockquote>
                    <div>
                      <svg
                        aria-hidden="true"
                        class="h-12 w-12 text-white opacity-25"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                      >
                        <path
                          d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
                        />
                      </svg>
                      <p class="mt-6 text-2xl font-medium text-white">
                        {{ item?.text }}
                      </p>
                    </div>
                    <footer class="mt-6">
                      <p class="text-base font-medium text-white">
                        {{ item?.name }}
                      </p>
                      <p class="text-base font-medium text-theme-primary-100">
                        {{ item?.location }}
                      </p>
                    </footer>
                  </blockquote>
                </div>
                <button
                  class="absolute right-12 z-10 hover:bg-white/10 rounded-full mx-4  p-2 hidden @md:block"
                  @click="x += (width * 0.7)"
                >
                  <ChevronRightIcon class="text-gray-200 h-6 w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
