<script setup>
import { ref } from 'vue'
import { useNotifyStore } from '@/stores/useNotifyStore.js'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'
import { useRouter } from 'vue-router'

const props = defineProps({
  form: {
    type: Array,
    required: true
  },
  content: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useNotify = useNotifyStore()
const useWebsite = useWebsiteStore()

const inputRef = ref(null)
const isPending = ref(false)

const formData = ref({
  name: '',
  email: ''
})

async function submitForm () {
  if (!formData.value.name || !formData.value.email) {
    useNotify.openNotify(
      'error',
      'Incomplete Information',
      'Please review the form and complete required fields.')
  } else {
    try {
      isPending.value = true

      await useWebsite.createLead(formData.value, 'form')
      await router.push(useWebsite.getRoutePath('success'))

      formData.value = {
        name: '',
        email: ''
      }
    } catch (e) {
      throw new Error(e)
    } finally {
      isPending.value = false
    }
  }
}

function convertLabel (key) {
  const labels = {
    name: 'Full Name',
    email: 'Email Address',
    phone: 'Phone Number',
    address: 'Project Address',
    budget: 'Expected Budget',
    description: 'Description'
  }
  return labels[key] || key
}
</script>

<template>
  <form
    class="grid grid-cols-2 gap-4 gap-y-6 mt-6"
  >
    <div
      v-for="(field, index) in props.form"
      :key="index"
      class="col-span-2"
    >
      <theme-base-input
        v-if="field.input === 'input' || field.input === 'textarea'"
        ref="inputRef"
        v-model.trim="formData[field.label.toLowerCase()]"
        :type="field.type"
        :component="field.input"
        :label="convertLabel(field.label) "
        :required="field.required"
      />

      <theme-base-input-phone
        v-else-if="field.input === 'phone'"
        v-model.trim="formData[field.label.toLowerCase()]"
        :type="field.type"
        :component="field.input"
        :label="convertLabel(field.label) "
        :required="field.required"
      />

      <theme-base-google-autocomplete
        v-else-if="field.input === 'address'"
        v-model="formData[field.label.toLowerCase()]"
        label="Project Address"
      />

      <div v-else-if="field.input === 'radio'">
        <label class="block text-sm font-semibold leading-6 text-gray-900">

          {{ convertLabel(field.label) }}
        </label>

        <fieldset class="mt-4">
          <div class="space-y-4">
            <div
              v-for="option in field.options"
              :key="option.label"
              class="flex items-center"
            >
              <input
                :id="option.label"
                v-model="formData[field.label.toLowerCase()]"
                :value="option.value"
                type="radio"
                :checked="option.isSelected"
                class="h-4 w-4 border-gray-300 text-theme-primary-600 focus:ring-theme-primary-600"
              >
              <label :for="option.label" class="ml-3 block text-sm leading-6 text-gray-600">{{ option.label }}</label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="@sm:col-span-2 @sm:flex @sm:justify-end mt-4">
      <theme-base-button
        :loading="isPending"
        class="w-20 justify-center"
        @click="submitForm"
      >
        Submit
      </theme-base-button>
    </div>
  </form>
</template>
