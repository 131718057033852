
export const cleaner = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Experience the Power of a Pristine Space',
        body: "At our professional cleaning service, we go beyond the ordinary cleaning routine. Our mission is to transform your space into a pristine environment that you'll love coming home to. With a meticulous focus on attention to detail and a commitment to delivering exceptional service, our experienced team is dedicated to exceeding your expectations. Let us work together to create a beautiful and inviting space that you can be proud of. Experience the power of a pristine space and elevate your living or working environment with our top-notch cleaning services."
      }
    },
    about: {
      text: {
        headline: 'Professional cleaning services for a spotless home.',
        body: 'Searching for skilled cleaners to enhance your living space? Your search ends here. Our team of experts offers a wide range of cleaning services, from deep cleaning to routine maintenance. We take pride in our attention to detail and guarantee exceptional results that will surpass your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Excellence that leaves a lasting impression.',
        headline: 'Professional cleaning solutions for your space.',
        body: 'From deep cleans to regular upkeep, our team of skilled cleaners will transform your space with meticulous attention to detail and a commitment to quality. We utilize top-notch cleaning products and techniques to ensure exceptional results that go above and beyond. Get in touch with us to experience the difference.'
      },
      bullets: [
        {
          title: 'Deep Cleaning',
          text: "Our skilled cleaners excel at deep cleaning services, tackling every nook and cranny of your home. From scrubbing floors to sanitizing surfaces, we'll leave your space immaculate and refreshed."
        },
        {
          title: 'Routine Maintenance',
          text: 'We offer routine maintenance services to keep your space in pristine condition on a regular basis. Our team will create a personalized cleaning schedule tailored to your needs, ensuring that your home always looks its best.'
        },
        {
          title: 'Specialized Services',
          text: 'In addition to general cleaning, we also offer specialized services such as carpet cleaning, window washing, and upholstery cleaning. We have the expertise and equipment to tackle any cleaning task, leaving your space looking and feeling brand new.'
        },
        {
          title: 'Move-In/Move-Out Cleaning',
          text: "If you're moving in or out of a space, our cleaners can provide a thorough clean to make the transition seamless. We'll take care of all the details, so you can focus on settling in or moving on to your next adventure."
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Emily S.',
          rating: 5,
          location: 'Aspen, CO',
          text: 'The team at this cleaning service goes above and beyond. They transformed my home with their thorough deep cleaning services. I was amazed at the attention to detail and how spotless everything looked. Highly recommend!'
        },
        {
          name: 'Robert M.',
          rating: 5,
          location: 'Boulder, CO',
          text: "I've been using this cleaning service for routine maintenance and I am extremely satisfied. They consistently provide a high level of cleanliness and professionalism. It's such a relief to come home to a sparkling clean house. Great job!"
        },
        {
          name: 'Sarah B.',
          rating: 5,
          location: 'Colorado Springs, CO',
          text: 'I recently hired this cleaning service for a move-in cleaning and they did an outstanding job. They left the place spotless and made the transition to my new home seamless. The team was friendly, efficient, and thorough. I highly recommend their services.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Take a Look at Our Impeccable Cleaning Results.',
        body: "Browse through our gallery of before and after photos to see the exceptional cleaning results we've achieved for our clients. From transforming dirty carpets to sparkling windows, each image showcases our team's meticulous attention to detail and commitment to delivering the highest quality cleaning services. Start exploring today and let us inspire you with the possibilities for your space."
      }
    },
    cta: {
      text: {
        overline: 'Experience Our Exceptional Cleaning Services.',
        headline: 'Book a Consultation with Our Expert Cleaners.',
        body: 'Are you ready to experience the difference our professional cleaning services can make for your home or office? Schedule a consultation with our experienced team today and let us show you why we are the trusted choice for exceptional cleanliness. Contact us now to book your appointment.'
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Experience the Art of Professional Cleaning.',
        body: 'At our cleaning company, we are committed to providing exceptional cleaning services that transform your space into a pristine and inviting environment. With a dedication to using top-quality cleaning products and a meticulous attention to detail, our team of expert cleaners can deliver outstanding results. Let us take care of the cleaning, so you can focus on enjoying your beautifully refreshed space.'
      }
    },
    features: {
      text: {
        overline: 'Excellence that leaves a lasting impression.',
        headline: 'Professional cleaning solutions for your space.',
        body: 'From deep cleans to regular upkeep, our team of skilled cleaners will transform your space with meticulous attention to detail and a commitment to quality. We utilize top-notch cleaning products and techniques to ensure exceptional results that go above and beyond. Get in touch with us to experience the difference.'
      },
      bullets: [
        {
          title: 'Deep Cleaning',
          text: "Our skilled cleaners excel at deep cleaning services, tackling every nook and cranny of your home. From scrubbing floors to sanitizing surfaces, we'll leave your space immaculate and refreshed."
        },
        {
          title: 'Routine Maintenance',
          text: 'We offer routine maintenance services to keep your space in pristine condition on a regular basis. Our team will create a personalized cleaning schedule tailored to your needs, ensuring that your home always looks its best.'
        },
        {
          title: 'Specialized Services',
          text: 'In addition to general cleaning, we also offer specialized services such as carpet cleaning, window washing, and upholstery cleaning. We have the expertise and equipment to tackle any cleaning task, leaving your space looking and feeling brand new.'
        },
        {
          title: 'Move-In/Move-Out Cleaning',
          text: "If you're moving in or out of a space, our cleaners can provide a thorough clean to make the transition seamless. We'll take care of all the details, so you can focus on settling in or moving on to your next adventure."
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Take a Look at Our Impeccable Cleaning Results.',
        body: "Browse through our gallery of before and after photos to see the exceptional cleaning results we've achieved for our clients. From transforming dirty carpets to sparkling windows, each image showcases our team's meticulous attention to detail and commitment to delivering the highest quality cleaning services. Start exploring today and let us inspire you with the possibilities for your space."
      }
    },
    cta: {
      text: {
        overline: 'Experience Our Exceptional Cleaning Services.',
        headline: 'Book a Consultation with Our Expert Cleaners.',
        body: 'Are you ready to experience the difference our professional cleaning services can make for your home or office? Schedule a consultation with our experienced team today and let us show you why we are the trusted choice for exceptional cleanliness. Contact us now to book your appointment.'
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get Answers to Your Cleaning Questions.',
        body: "Curious about our professional cleaning services or have questions about the cleaning process? Visit our FAQ page to find answers to common questions. If you can't find the information you're looking for, don't hesitate to reach out to our friendly team. We're here to provide you with all the information you need to make an informed decision about our exceptional cleaning services."
      }
    },
    faq: {
      text: {
        overline: 'Get Your Cleaning Questions Answered.',
        headline: 'Find Answers to Common Cleaning Questions.',
        body: "Our FAQ page is a comprehensive resource for homeowners seeking more information about our professional cleaning services, the products we use, and our cleaning process. We've compiled responses to some of the most frequently asked questions to aid you in making informed decisions about your cleaning needs. If you have any additional inquiries, please don't hesitate to reach out to our knowledgeable team."
      },
      bullets: [
        {
          title: 'What services do you offer?',
          text: "We offer a wide range of cleaning services to cater to all your needs. From regular residential cleaning to deep cleaning, move-in/move-out cleaning, and post-construction cleaning, we've got you covered. Contact us to discuss how we can transform your space into a pristine environment."
        },
        {
          title: 'What cleaning products do you use?',
          text: 'We are committed to using top-quality and environmentally friendly cleaning products. Our team carefully selects cleaning solutions that are effective, safe, and gentle on surfaces. We prioritize both cleanliness and your well-being.'
        },
        {
          title: 'How long does a cleaning session take?',
          text: 'The duration of a cleaning session depends on the size and specific requirements of your space. We will provide you with an estimate of the cleaning time during our initial consultation. Rest assured, we are dedicated to completing our work efficiently without compromising the quality of our results.'
        },
        {
          title: 'Do you offer specialized cleaning services?',
          text: 'Absolutely! We understand that every space is unique and may require specialized cleaning services. Whether you need carpet cleaning, upholstery cleaning, window cleaning, or any other specific cleaning service, our team has the expertise and equipment to fulfill your requirements with precision and care.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Experience Our Exceptional Cleaning Services.',
        headline: 'Book a Consultation with Our Expert Cleaners.',
        body: 'Are you ready to experience the difference our professional cleaning services can make for your home or office? Schedule a consultation with our experienced team today and let us show you why we are the trusted choice for exceptional cleanliness. Contact us now to book your appointment.'
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Contact Our Professional Cleaning Team',
        body: 'If you have any questions about our professional cleaning services or would like to schedule a free consultation, our expert team is here to assist you. Fill out the form below or give us a call to get in touch with us today. We look forward to hearing from you and providing you with exceptional cleaning solutions.'
      }
    },
    contact: {
      text: {
        headline: 'Reach Out to Our Professional Cleaning Team',
        body: "We value your interest and involvement in our professional cleaning services. If you have any questions or would like to schedule a consultation, please don't hesitate to contact us. Our team is ready to assist you and provide more information about our cleaning solutions. Let us help you create a clean and fresh environment for your home or office. Reach out today!"
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    estimate_selector: {
      text: {
        overline: 'Get an Instant Cleaning Estimate',
        headline: 'Curious about project pricing?',
        body: "We understand the importance of transparency when it comes to cleaning services. That's why we've developed an easy-to-use instant estimate tool that provides you with an accurate and reliable estimate of the cost of your cleaning project. With just a few details about your space and cleaning needs, our tool will generate an estimate for you in a matter of minutes. Take advantage of this tool today to gain a better understanding of the cost and how our team can assist you. Get started now to find out how we can help transform your space into a clean and refreshing environment."
      }
    }
  }
}
