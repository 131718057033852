<script setup>
import { defineComponent, h } from 'vue'
import {
  CurrencyDollarIcon,
  EnvelopeIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  QueueListIcon
} from '@heroicons/vue/24/outline'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  global: {
    type: Object,
    default: () => {}
  },
  editor: {
    type: Boolean,
    default: false
  },
  url: {
    type: String,
    default: ''
  }
})

const navigation = {
  main: [
    {
      name: 'Home',
      to: props.editor ? '/editor' : `/${props.url}`,
      icon: HomeIcon
    },
    {
      name: 'About',
      to: props.editor ? '/editor/about' : `/${props.url}/about`,
      icon: QueueListIcon
    },
    {
      name: 'FAQ',
      to: props.editor ? '/editor/faq' : `/${props.url}/faq`,
      icon: QuestionMarkCircleIcon
    },

    {
      name: 'Estimate',
      to: props.editor ? '/editor/estimate' : `/${props.url}/contact`,
      icon: CurrencyDollarIcon
    },
    {
      name: 'Contact us',
      to: props.editor ? '/editor/contact' : `/${props.url}/contact`,
      icon: EnvelopeIcon
    }
  ],
  social: [
    {
      name: 'Facebook',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Instagram',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Twitter',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84'
            })
          ])
      })
    },
    {
      name: 'GitHub',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'YouTube',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Instagram',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [h('path', { d: 'M12 2.09a9.91 9.91 0 100 19.82 9.91 9.91 0 000-19.82zm0 3.78a6.14 6.14 0 11-4.34 1.8 6.13 6.13 0 014.34-1.8zm5.72 5.5a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm-4.62 4.15a4.67 4.67 0 00-3.3 1.37 4.63 4.63 0 00-1.37 3.3 4.67 4.67 0 004.67 4.66 4.67 4.67 0 004.66-4.66 4.62 4.62 0 00-1.36-3.3 4.67 4.67 0 00-3.3-1.37z' })])
      })
    },
    {
      name: 'Facebook',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [h('path', { d: 'M20.41 2H3.59A1.59 1.59 0 002 3.59v16.82A1.59 1.59 0 003.59 22h8.47v-8.47H9.75v-3.29h2.31V8.59c0-2.3 1.4-3.55 3.44-3.55 1 0 1.85.08 2.1.12v2.4l-1.44.01c-1.13 0-1.34.54-1.34 1.32v1.73h2.67l-.35 3.29h-2.32V22h4.54A1.59 1.59 0 0022 20.41V3.59A1.59 1.59 0 0020.41 2z' })])
      })
    },
    {
      name: 'Google',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [h('path', { d: 'M20.69 10.89c0-.76-.06-1.36-.18-1.81h-8.92v3.24h5.11c-.21 1.25-.79 2.28-1.67 3.1a4.53 4.53 0 01-3.23 1.32c-1.16 0-2.17-.39-2.98-1.05-.81-.67-1.22-1.62-1.22-2.86s.41-2.18 1.22-2.85c.81-.67 1.83-1.05 2.98-1.05 1.13 0 2.11.34 2.95 1.02.85.68 1.44 1.59 1.67 2.72h-1.67v1.91h3.36c.12-.45.18-1.06.18-1.83z' })])
      })
    },
    {
      name: 'Houzz',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [h('path', { d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm3.1 16.2c-.2 0-.4-.1-.6-.2L12 15.9l-2.5 2.1c-.4.4-1.1.4-1.5 0-.4-.4-.4-1.1 0-1.5l3-2.5c.2-.2.5-.3.7-.3.3 0 .5.1.7.3l3 2.5c.4.4.4 1.1 0 1.5-.2.2-.4.2-.6.2z' })])
      })
    },
    {
      name: 'Yelp',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [h('path', { d: 'M9.31 11.97l-1.31 5.17c-.08.34-.2.47-.53.46l-5.21-.25c-.3 0-.44-.12-.37-.44l3.99-3.16L3.05 7.9c-.12-.29 0-.47.28-.46l5.2.24c.3 0 .46.11.4.44L7.87 11l1.44.97zm6.49 0l-1.3 5.17c-.08.34-.2.47-.53.46l-5.2-.25c-.3 0-.45-.12-.38-.44l4-3.16-1.68-4.13c-.12-.29 0-.47.28-.46l5.2.24c.3 0 .46.11.4.44L14.38 11l1.42.97z' })])
      })
    },
    {
      name: 'TikTok',
      to: '#',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [h('path', { d: 'M22.8 8.55c-.8.36-2.05.72-2.98.88-2.2.5-2.5 1.5-2.5 1.5h-.02c-1.42 2.16-.26 5.66-.26 5.66 1.33 3.44 1.12 3.69.52 4.18-.7.53-1.42.31-1.75.24-.7-.15-1.28-.7-1.53-1.37-.1-.23-.18-.46-.26-.7-.4-1.22-.44-1.4-1.1-1.4-.82 0-2.52 1.4-2.52 2.65 0 1.64 1.44 1.94 2.2 2 .86.07 1.05-.06 1.64-.43.15-.08.3-.15.45-.24.6-.38.6-1.25 0-1.64-.2-.13-.42-.22-.63-.33-.13-.07-.25-.13-.37-.2-.6-.35-1.27-.78-1.27-1.73 0-1.4 1.35-2.84 2.4-2.84 1.1 0 1.98.83 1.98 1.84 0 .45-.15.8-.37 1.08-.3.3-.68.45-1.08.45-.08 0-.17 0-.25-.02-1.56-.2-2.34-.88-2.34-2.15 0-1.52 1.38-3.2 2.78-3.2.92 0 1.82.48 2.27.85.38.28.8.58 1.3.58.05 0 .1 0 .15-.02.7-.13 1.15-1.03.78-1.7z' })])
      })
    }
  ]
}
</script>

<template>
  <footer class="bg-white @container">
    <div class="mx-auto max-w-7xl overflow-hidden py-20 px-6 @sm:py-24 @lg:px-8">
      <nav aria-label="Footer" class="-mb-6 columns-2 @sm:flex @sm:justify-center @sm:space-x-12">
        <div
          v-for="item in navigation.main"
          :key="item.name"
          class="pb-6"
        >
          <router-link :to="item.to" class="text-sm leading-6 text-gray-600 hover:text-gray-900">
            {{ item.name }}
          </router-link>
        </div>
      </nav>
      <div class="mt-10 flex justify-center space-x-10">
        <router-link
          v-for="item in navigation.social"
          :key="item.name"
          :to="item.to"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">{{ item.name }}</span>
          <component
            :is="item.icon"
            aria-hidden="true"
            class="h-6 w-6"
          />
        </router-link>
      </div>
      <p class="mt-10 text-center text-xs leading-5 text-gray-500">
        &copy; 2020 Your Company, Inc. All rights
        reserved.
      </p>
    </div>
  </footer>
</template>
