<script setup>
import { computed } from 'vue'
import { CheckIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const bullets = computed(() => {
  return props.data?.content?.bullets
})
</script>

<!-- tailwind compliant -->
<template>
  <theme-base-section>
    <!-- header -->
    <div class="max-w-2xl">
      <theme-base-overline>
        {{ data?.content?.text?.overline || 'Expert Services' }}
      </theme-base-overline>
      <theme-base-title>
        {{ data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
      </theme-base-title>
      <theme-base-subtitle>
        {{
          data?.content?.text?.body ||
            'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
        }}
      </theme-base-subtitle>
    </div>

    <div class="mt-16">
      <div class="grid @md:grid-cols-2 gap-10">
        <div
          v-for="item in bullets"
          :key="item.uid"
        >
          <div class="flex gap-4 max-w-xl">
            <div>
              <span class="bg-theme-primary-500 flex items-center justify-center rounded-lg h-8 w-8">
                <CheckIcon class="h-5 w-5 text-white" /></span>
            </div>
            <div>
              <h6 class="text-lg font-medium">
                {{ item.title }}
              </h6>
              <p class="text-gray-700 text-base/6 mt-2">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </theme-base-section>
</template>
