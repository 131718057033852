<script setup>
import { computed, defineComponent, h } from 'vue'
import { LinkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: 'h-5'
  },
  width: {
    type: String,
    default: 'w-5'
  }
})

const iconComponent = computed(() => {
  const iconName = props.icon.toLowerCase().trim()
  const matchedIcon = Object.keys(social).find(key => {
    const socialName = key.toLowerCase()
    return iconName === socialName || socialName.includes(iconName)
  })

  if (matchedIcon) {
    return social[matchedIcon]
  } else {
    return { icon: LinkIcon, name: props.icon }
  }
})

const social = {
  facebook: {
    name: 'Facebook',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
            'clip-rule': 'evenodd'
          })
        ])
    })
  },
  instagram: {
    name: 'Instagram',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
            'clip-rule': 'evenodd'
          })
        ])
    })
  },
  twitter: {
    name: 'Twitter',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84'
          })
        ])
    })
  },
  youtube: {
    name: 'YouTube',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
            'clip-rule': 'evenodd'
          })
        ])
    })
  },

  tiktok: {
    name: 'TikTok',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 48 48' }, [
          h('path', {
            d: 'M38.077 15.854C36.069 15.794 34.25 14.834 32.879 13.547C32.132 12.832 31.54 11.969 31.142 11.015C30.743 10.06 30.545 9.034 30.562 8L24.973 8V28.83C24.973 32.343 22.877 34.418 20.277 34.418C19.651 34.432 19.028 34.321 18.446 34.091C17.864 33.861 17.334 33.518 16.886 33.08C16.439 32.642 16.083 32.12 15.84 31.543C15.598 30.966 15.473 30.346 15.473 29.72C15.473 29.094 15.598 28.475 15.84 27.898C16.083 27.321 16.439 26.798 16.886 26.36C17.334 25.923 17.864 25.579 18.446 25.349C19.028 25.119 19.651 25.008 20.277 25.022C20.794 25.026 21.307 25.117 21.795 25.29V19.599C21.296 19.49 20.787 19.436 20.277 19.438C18.243 19.439 16.256 20.043 14.566 21.173C12.876 22.303 11.559 23.909 10.781 25.788C10.004 27.667 9.801 29.734 10.198 31.728C10.595 33.722 11.575 35.553 13.013 36.99C14.451 38.428 16.283 39.407 18.277 39.803C20.271 40.199 22.338 39.995 24.216 39.217C26.095 38.438 27.7 37.121 28.83 35.43C29.959 33.74 30.562 31.752 30.562 29.719V18.832C32.728 20.342 35.315 21.04 38.077 21.087V15.854Z'
          })
        ])
    })
  },
  yelp: {
    name: 'Yelp',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 16 16' }, [
          h('path',
            { d: 'M284.1 298.4c-6.1 6.1-0.9 17.3-0.9 17.3l45.8 76.4c0 0 7.5 10.1 14 10.1 6.5 0 13-5.4 13-5.4l36.2-51.7c0 0 3.6-6.5 3.7-12.2 0.1-8.1-12.1-10.4-12.1-10.4l-85.7-27.5C298.1 294.9 289.7 292.7 284.1 298.4L284.1 298.4zM279.7 259.8c4.4 7.4 16.5 5.3 16.5 5.3l85.5-25c0 0 11.6-4.7 13.3-11.1 1.6-6.3-1.9-13.9-1.9-13.9L352.2 167c0 0-3.5-6.1-10.9-6.7 -8.1-0.7-13.1 9.1-13.1 9.1l-48.3 76C280 245.4 275.7 253 279.7 259.8L279.7 259.8zM239.4 230.2c10.1-2.5 11.7-17.1 11.7-17.1l-0.7-121.7c0 0-1.5-15-8.3-19.1 -10.6-6.4-13.7-3.1-16.7-2.6l-71 26.4c0 0-6.9 2.3-10.6 8.1 -5.2 8.2 5.3 20.2 5.3 20.2L222.8 225C222.8 225 230.1 232.5 239.4 230.2L239.4 230.2zM221.8 279.5c0.3-9.4-11.3-15-11.3-15l-76.3-38.5c0 0-11.3-4.7-16.8-1.4 -4.2 2.5-7.9 7-8.3 11l-5 61.2c0 0-0.7 10.6 2 15.4 3.9 6.8 16.7 2.1 16.7 2.1l89.1-19.7C215.4 292.1 221.5 291.9 221.8 279.5L221.8 279.5zM244 312.5c-7.6-3.9-16.8 4.2-16.8 4.2l-59.6 65.6c0 0-7.4 10-5.5 16.2 1.8 5.8 4.7 8.6 8.9 10.7l59.9 18.9c0 0 7.3 1.5 12.8-0.1 7.8-2.3 6.4-14.5 6.4-14.5l1.4-88.9C251.3 324.6 251 316.1 244 312.5L244 312.5zM244 312.5' })])
    })
  },
  pinterest: {
    name: 'pinterest',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 16 16' }, [
          h('path',
            { d: 'M266.6 76.5c-100.2 0-150.7 71.8-150.7 131.7 0 36.3 13.7 68.5 43.2 80.6 4.8 2 9.2 0.1 10.6-5.3 1-3.7 3.3-13 4.3-16.9 1.4-5.3 0.9-7.1-3-11.8 -8.5-10-13.9-23-13.9-41.3 0-53.3 39.9-101 103.8-101 56.6 0 87.7 34.6 87.7 80.8 0 60.8-26.9 112.1-66.8 112.1 -22.1 0-38.6-18.2-33.3-40.6 6.3-26.7 18.6-55.5 18.6-74.8 0-17.3-9.3-31.7-28.4-31.7 -22.5 0-40.7 23.3-40.7 54.6 0 19.9 6.7 33.4 6.7 33.4s-23.1 97.8-27.1 114.9c-8.1 34.1-1.2 75.9-0.6 80.1 0.3 2.5 3.6 3.1 5 1.2 2.1-2.7 28.9-35.9 38.1-69 2.6-9.4 14.8-58 14.8-58 7.3 14 28.7 26.3 51.5 26.3 67.8 0 113.8-61.8 113.8-144.5C400.1 134.7 347.1 76.5 266.6 76.5z' })])
    })
  },
  google: {
    name: 'google',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 16 16' }, [
          h('path',
            { d: 'M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z' })])
    })
  },
  'google plus': {
    name: 'google plus',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 16 16' }, [
          h('path',
            { d: 'M179.7 237.6L179.7 284.2 256.7 284.2C253.6 304.2 233.4 342.9 179.7 342.9 133.4 342.9 95.6 304.4 95.6 257 95.6 209.6 133.4 171.1 179.7 171.1 206.1 171.1 223.7 182.4 233.8 192.1L270.6 156.6C247 134.4 216.4 121 179.7 121 104.7 121 44 181.8 44 257 44 332.2 104.7 393 179.7 393 258 393 310 337.8 310 260.1 310 251.2 309 244.4 307.9 237.6L179.7 237.6 179.7 237.6ZM468 236.7L429.3 236.7 429.3 198 390.7 198 390.7 236.7 352 236.7 352 275.3 390.7 275.3 390.7 314 429.3 314 429.3 275.3 468 275.3' })])
    })
  },
  apple: {
    name: 'apple',
    to: '#',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 16 16' }, [
          h('path',
            { d: 'M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z' }),
          h('path',
            { d: 'M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z' })
        ])
    })
  }
}

</script>

<template>
  <span class="text-gray-400 hover:text-gray-500 p-1 rounded-full flex items-center justify-center">
    <span class="sr-only">{{ iconComponent.name }}</span>
    <component
      :is="iconComponent.icon"
      aria-hidden="true"
      :class="[props.height, props.width]"
    />
  </span>
</template>
