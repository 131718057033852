<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<template>
  <div class="@container">
    <div class="relative isolate overflow-hidden  bg-gradient-to-br from-white from-60%  to-theme-primary-100">
      <div class="mx-auto max-w-7xl pt-10 pb-24 @sm:pb-32 @lg:grid @lg:grid-cols-2 @lg:gap-x-8 @lg:py-40 @lg:px-8">
        <div class="px-6 @lg:px-0">
          <div class="mx-auto max-w-2xl">
            <div class="max-w-lg ">
              <!-- headline -->
              <h1 class="mt-8 text-4xl font-bold tracking-tight text-gray-900 @sm:text-6xl">
                {{ props.data?.content?.text?.headline || 'Beautiful Home Painting by Professionals' }}
              </h1>

              <!-- body -->
              <p class="mt-6 text-lg leading-8 text-gray-600">
                {{
                  props.data?.content?.text?.body ||
                    'Revitalize your space with the magic of a fresh coat of paint. Our team of professionals is dedicated to delivering clean lines, vibrant color, and flawless results that will leave you with a home that you\'ll love for years to come.'
                }}
              </p>

              <!-- button -->
              <div class="mt-10 flex items-center gap-x-6">
                <component
                  :is="button.component"
                  v-for="button in props.data?.content?.button"
                  :key="button.uid"
                  v-bind="button"
                  @click="router.push(useWebsite.getRoutePath(button.link.href))"
                >
                  {{ button.label || 'Get a free estimate' }}
                </component>
              </div>
            </div>
          </div>
        </div>

        <!--  image -->
        <div class="mt-20 @sm:mt-24 @md:mx-auto @md:max-w-2xl @lg:mx-0 @lg:mt-0 @lg:w-screen">
          <!--  <div
            aria-hidden="true"
            class="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-theme-primary-600/10 ring-1 ring-theme-primary-50 @md:-mr-20 @lg:-mr-36"
          /> -->
          <div class="shadow-lg @md:rounded-3xl overflow-hidden">
            <theme-base-img
              :img-src="props.data?.content?.background_image?.url"
              class="bg-gray-50 object-cover @md:h-[50vh] w-full rou"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
