<script setup>
import { computed, ref } from 'vue'
import { CheckIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const bullets = computed(() => {
  if (!props.data?.content?.bullets?.length) {
    return serviceList.value
  } else {
    return props.data?.content?.bullets
  }
})

const serviceList = ref([
  {
    title: 'Protect Your Home with Exterior Painting',
    text: 'Ensure your home\'s protection with professional exterior painting. Our team delivers results that look great and last.'
  },
  {
    title: 'Coordinate Your Rooms with Room Painting',
    text: 'Create a harmonious living space with expert room painting. Our team delivers results that reflect your personal style.'
  },
  {
    title: 'Revitalize Your Kitchen with Cabinet Painting',
    text: 'Transform your kitchen with expert cabinet painting. Our team delivers flawless results for a fresh, functional look.'
  }
])
</script>

<!-- Tailwind Compliant -->
<template>
  <theme-base-section>
    <!--  header -->
    <div class="mx-auto max-w-2xl @lg:text-center">
      <p class="text-lg/8 font-semibold tracking-tight text-theme-primary-600">
        {{ props.data?.content?.text?.overline || 'Expert Services' }}
      </p>
      <h3 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 @sm:text-4xl">
        {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Services' }}
      </h3>
      <p class="mt-6 text-lg/8 text-gray-600">
        {{
          props.data?.content?.text?.body ||
            'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
        }}
      </p>
    </div>

    <!--  list container -->
    <div class="mx-auto mt-16 max-w-2xl @sm:mt-20 @lg:mt-24 @lg:max-w-4xl">
      <ul class="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 @lg:max-w-none @lg:grid-cols-2 @lg:gap-y-16">
        <li
          v-for="(item, bulletIdx) in bullets"
          :key="bulletIdx"
          class="flex"
        >
          <!-- Check -->
          <div class="mr-6">
            <span class="flex h-10 w-10 items-center justify-center rounded-lg bg-theme-primary-600">
              <CheckIcon aria-hidden="true" class="h-6 w-6 text-white" />
            </span>
          </div>

          <!--  Item -->
          <div>
            <div class="text-base/7 font-semibold text-gray-900">
              {{ item?.title }}
            </div>
            <div class="mt-2 text-base/7 text-gray-600">
              {{ item?.text }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </theme-base-section>
</template>
