
export const electrician = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Experience the Power of Professional Electrical Services',
        body: 'Trust our team of skilled electricians to bring light and energy to your home or business with exceptional electrical solutions.'
      }
    },
    about: {
      text: {
        headline: 'Exceptional Electrical Services',
        body: 'Our team is dedicated to providing reliable and innovative electrical solutions that meet your needs and exceed your expectations. With years of expertise and a commitment to quality, we are the trusted choice for all your electrical needs.'
      }
    },
    features: {
      text: {
        overline: 'Expert Electrical Services',
        headline: 'Our Comprehensive Electrical Solutions',
        body: 'From installations to repairs, our team is dedicated to delivering exceptional results that enhance your space.'
      },
      bullets: [
        {
          title: 'Residential Electrical Services',
          text: 'Trust our experienced electricians to provide top-notch service for your home\'s electrical needs. From installations to repairs, we have you covered.'
        },
        {
          title: 'Commercial Electrical Services',
          text: 'Keep your business running smoothly with our comprehensive electrical services. We offer installations, repairs, and maintenance to keep your space safe and efficient.'
        },
        {
          title: 'Lighting Solutions',
          text: 'Transform your space with expert lighting solutions. From design to installation, our team will create a customized lighting plan that meets your needs and enhances your space.'
        },
        {
          title: 'Smart Home Integration',
          text: 'Bring your home into the 21st century with our smart home integration services. From lighting to security, we\'ll help you automate and optimize your space for modern living.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'John D',
          rating: 5,
          location: 'Phoenix, AZ',
          text: 'We were very happy with their electrical services. They were very professional and knowledgeable, and were able to fix the issue quickly. We highly recommend them!'
        },
        {
          name: 'Megan R',
          rating: 5,
          location: 'Tucson, AZ',
          text: 'Their team did a fantastic job installing new lighting fixtures in our home. They were prompt, courteous, and the final result exceeded our expectations. We would definitely use their services again!'
        },
        {
          name: 'Mike S',
          rating: 4,
          location: 'Scottsdale, AZ',
          text: 'We had a great experience with them overall. The work was completed on time and within budget, and the team was very professional. The only reason for the 4-star rating instead of 5 is because we had a minor issue with one of the installations, but they were able to quickly resolve it.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional electrical projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Space Today',
        headline: 'Request a Consultation to Get Started',
        body: 'Get in touch with our expert electricians today to schedule a consultation or request more information about our electrical services. Our team is standing by to answer your questions and help you elevate your space with exceptional electrical solutions.'
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Electrical Services',
        body: 'With a commitment to excellence and a passion for delivering reliable and efficient solutions, our team of professional electricians is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for all your electrical needs.'
      }
    },
    features: {
      text: {
        overline: 'Expert Electrical Services',
        headline: 'Our Comprehensive Electrical Solutions',
        body: 'From installations to repairs, our team is dedicated to delivering exceptional results that enhance your space.'
      },
      bullets: [
        {
          title: 'Residential Electrical Services',
          text: 'Trust our experienced electricians to provide top-notch service for your home\'s electrical needs. From installations to repairs, we have you covered.'
        },
        {
          title: 'Commercial Electrical Services',
          text: 'Keep your business running smoothly with our comprehensive electrical services. We offer installations, repairs, and maintenance to keep your space safe and efficient.'
        },
        {
          title: 'Lighting Solutions',
          text: 'Transform your space with expert lighting solutions. From design to installation, our team will create a customized lighting plan that meets your needs and enhances your space.'
        },
        {
          title: 'Smart Home Integration',
          text: 'Bring your home into the 21st century with our smart home integration services. From lighting to security, we\'ll help you automate and optimize your space for modern living.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Explore Our Work',
        body: 'Take a look at our portfolio of exceptional electrical projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Get a Free Estimate Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Electrical Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our electrical services. From questions about pricing and scheduling, to safety tips and maintenance, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Electrical Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your electrical project.'
      },
      bullets: [
        {
          title: 'What electrical services do you offer?',
          text: 'We offer a wide range of electrical services including installation, repair, and maintenance of electrical systems for residential and commercial properties.'
        },
        {
          title: 'Are your electricians licensed and insured?',
          text: 'Yes, all our electricians are licensed and insured to ensure the safety of our clients and their property.'
        },
        {
          title: 'What types of electrical problems can you fix?',
          text: 'Our experienced electricians can handle any electrical issue, from minor repairs to complex installations.'
        },
        {
          title: 'Do you offer emergency electrical services?',
          text: 'Yes, we offer 24/7 emergency electrical services to ensure that our clients\' electrical issues are resolved promptly and efficiently.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Upgrade Your Electrical System?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Electricians',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Contact Us',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our electrical services. Our team is standing by to answer your questions and help you elevate your space with exceptional electrical solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your electrical project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your space and electrical needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
