
export const landscaper = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Elevate Your Outdoor Space with Expert Landscaping Services',
        body: 'From lush gardens to beautiful hardscaping, our team of skilled landscapers will bring your vision to life with unparalleled craftsmanship and attention to detail.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are passionate about transforming your outdoor space with exceptional landscaping services. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring a flawless finish that exceeds your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Landscaping Services',
        headline: 'Our Comprehensive Landscaping Solutions',
        body: ' From design to installation, our team is dedicated to delivering exceptional results that elevate your outdoor space.'
      },
      bullets: [
        {
          title: 'Garden Design and Installation',
          text: 'Transform your yard with expert garden design and installation services. Our team will work with you to create a customized plan that meets your specific needs and style preferences.'
        },
        {
          title: 'Hardscaping',
          text: 'Enhance your outdoor space with our expert hardscaping services. We use only the highest-quality materials and expert craftsmanship to create stunning walkways, patios, and more.'
        },
        {
          title: 'Lawn Care',
          text: 'Maintain a healthy, vibrant lawn with our expert lawn care services. From fertilization to weed control, our team will ensure your lawn stays looking its best year-round.'
        },
        {
          title: 'Outdoor Lighting',
          text: 'Add beauty and functionality to your outdoor space with our expert outdoor lighting services. We will work with you to create a customized lighting plan that enhances your space and provides added safety and security.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Sarah M',
          rating: 5,
          location: 'Denver, CO',
          text: 'We are so impressed with the quality of work done by this landscaping company. Our yard looks amazing and the team was friendly, knowledgeable and professional throughout the entire process.'
        },
        {
          name: 'Matt P',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We couldn\'t be happier with the hardscaping work done by this company. They were efficient and skilled, and our patio looks better than we could have imagined. Highly recommend!'
        },
        {
          name: 'Lisa R',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'The team did an amazing job on our garden design and installation. They were responsive to our needs and style preferences and we couldn\'t be happier with the final result!'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional landscaping projects and see the difference our skilled professionals can make in transforming your outdoor space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Outdoor Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Painting',
        body: 'With a commitment to excellence and a passion for delivering stunning results, our team of professional painters is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for transforming homes.'
      }
    },
    features: {
      text: {
        overline: 'Premium Painting Services',
        headline: 'Our Comprehensive Painting Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your space.'
      },
      bullets: [
        {
          title: 'Interior Painting',
          text: 'Transform the look and feel of your home or office with our expert interior painting services. We use only the highest-quality materials to create a flawless finish that lasts.'
        },
        {
          title: 'Exterior Painting',
          text: 'Protect and enhance your property with our reliable exterior painting services. Our team will carefully prepare and paint every surface, ensuring a durable and long-lasting finish.'
        },
        {
          title: 'Color Consultation',
          text: 'Let our color experts guide you in selecting the perfect palette for your space. We offer personalized color consultations to help you achieve the look you envision.'
        },
        {
          title: 'Cabinet Painting',
          text: 'Update your kitchen or bathroom with our professional cabinet painting services. We use high-quality paint and finishes to breathe new life into your existing cabinetry.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional painting projects and see the difference our skilled professionals can make in transforming your space.'
      },
      cta: {
        text: {
          overline: 'Transform Your Space Today',
          headline: 'Request a Consultation to Get Started',
          body: ''
        }
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Landscaping Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our landscaping services. From questions about pricing and scheduling, to tips for maintaining your yard, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Landscaping Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your landscaping project.'
      },
      bullets: [
        {
          title: 'How long will it take to complete my landscaping project?',
          text: 'The time required to complete your landscaping project will depend on a variety of factors, such as the size of the space, the scope of the project, and any necessary preparations. We will provide you with a detailed timeline before beginning work, and keep you informed throughout the process.'
        },
        {
          title: 'What types of plants do you use?',
          text: 'We use only the highest-quality plants and materials to ensure a beautiful and sustainable landscape. Our team can provide recommendations based on your needs and preferences, and we are happy to work with any plant species of your choice.'
        },
        {
          title: 'Will the landscaping process be disruptive to my daily routine?',
          text: 'We understand that having your yard landscaped can be an inconvenience, and we will do everything we can to minimize disruption to your daily routine. Our team will work efficiently and cleanly, and will be respectful of your property throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Transform Your Yard?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Landscapers',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Transform Your Outdoor Space with Our Help',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our landscaping services. Our team is standing by to answer your questions and help you transform your outdoor space with exceptional landscaping solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your landscaping project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your outdoor space and landscaping needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
