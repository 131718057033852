
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useModalStore = defineStore('modal', {
  state: () => ({
    isOpen: false,
    type: '',
    title: 'Lorem ipsum',
    text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.',
    action: {}
  }),
  actions: {
    openModal (type = String, title, text, action = { name: String, action: Function }) {
      this.isOpen = true
      this.type = type
      this.title = title
      this.text = text
      this.action = action
    },

    onCancel () {
      this.closeModal()
    },

    onConfirm () {
      this.action.action()
      this.closeModal()
    },

    closeModal () {
      this.isOpen = false
      setTimeout(() => {
        this.action = {}
      }, 200)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot))
}
