
export const painter = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Elevate Your Space with Expert Painting Services',
        body: 'From bold accent walls to precise trim work, our team of skilled painters will bring your vision to life with unparalleled craftsmanship and attention to detail.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are passionate about transforming your space with exceptional painting services. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring a flawless finish that exceeds your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Painting Services',
        headline: 'Our Comprehensive Painting Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your space.'
      },
      bullets: [
        {
          title: 'Interior Painting',
          text: 'Transform the look and feel of your home or office with our expert interior painting services. We use only the highest-quality materials to create a flawless finish that lasts.'
        },
        {
          title: 'Exterior Painting',
          text: 'Protect and enhance your property with our reliable exterior painting services. Our team will carefully prepare and paint every surface, ensuring a durable and long-lasting finish.'
        },
        {
          title: 'Color Consultation',
          text: 'Let our color experts guide you in selecting the perfect palette for your space. We offer personalized color consultations to help you achieve the look you envision.'
        },
        {
          title: 'Cabinet Painting',
          text: 'Update your kitchen or bathroom with our professional cabinet painting services. We use high-quality paint and finishes to breathe new life into your existing cabinetry.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Lucy M',
          rating: 5,
          location: 'Denver, CO',
          text: 'They did an amazing job on our home\'s interior. The team was professional, courteous, and went above and beyond to ensure a perfect finish. We highly recommend them!'
        },
        {
          name: 'Brian & Anne T.',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We were so impressed with the quality of the work done by Them. Our house\'s exterior looks beautiful, and the painters were reliable and efficient. Thank you for your excellent service!'
        },
        {
          name: 'Julie L',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'I am so happy I chose them for my color consultation. Their experts were knowledgeable, friendly, and helped me choose the perfect colors for my home. I am thrilled with the results!'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional painting projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },
  //
  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Painting',
        body: 'With a commitment to excellence and a passion for delivering stunning results, our team of professional painters is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for transforming homes.'
      }
    },
    features: {
      text: {
        overline: 'Premium Painting Services',
        headline: 'Our Comprehensive Painting Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your space.'
      },
      bullets: [
        {
          title: 'Interior Painting',
          text: 'Transform the look and feel of your home or office with our expert interior painting services. We use only the highest-quality materials to create a flawless finish that lasts.'
        },
        {
          title: 'Exterior Painting',
          text: 'Protect and enhance your property with our reliable exterior painting services. Our team will carefully prepare and paint every surface, ensuring a durable and long-lasting finish.'
        },
        {
          title: 'Color Consultation',
          text: 'Let our color experts guide you in selecting the perfect palette for your space. We offer personalized color consultations to help you achieve the look you envision.'
        },
        {
          title: 'Cabinet Painting',
          text: 'Update your kitchen or bathroom with our professional cabinet painting services. We use high-quality paint and finishes to breathe new life into your existing cabinetry.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional painting projects and see the difference our skilled professionals can make in transforming your space.'
      },
      cta: {
        text: {
          overline: 'Transform Your Space Today',
          headline: 'Request a Consultation to Get Started',
          body: ''
        }
      }
    }
  },
  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Painting Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our painting services. From questions about pricing and scheduling, to tips for preparing your home, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Painting Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your painting project.'
      },
      bullets: [
        {
          title: 'How long will it take to complete my painting project?',
          text: 'The time required to complete your painting project will depend on a variety of factors, such as the size of the space, the scope of the project, and any necessary preparations. We will provide you with a detailed timeline before beginning work, and keep you informed throughout the process.'
        },
        {
          title: 'What types of paint do you use?',
          text: 'We use only the highest-quality paints and materials to ensure a flawless finish that lasts. Our team can provide recommendations based on your needs and preferences, and we are happy to work with any paint brand of your choice.'
        },
        {
          title: 'Will the painting process be disruptive to my daily routine?',
          text: 'We understand that having your space painted can be an inconvenience, and we will do everything we can to minimize disruption to your daily routine. Our team will work efficiently and cleanly, and will be respectful of your home or business throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Transform Your Space?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Painters',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Let\'s Transform Your Space with Exceptional Painting Services',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our painting services. Our team of expert painters is standing by to answer your questions and help you bring your vision to life.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your painting project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your space and painting needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
