<script setup>
import { CheckIcon } from '@heroicons/vue/20/solid'
import { computed, ref } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const bullets = computed(() => {
  if (!props.data?.content?.bullets?.length) {
    return serviceList.value
  } else {
    return props.data?.content?.bullets
  }
})

const serviceList = ref([
  {
    title: 'Protect Your Home with Exterior Painting',
    text: 'Ensure your home\'s protection with professional exterior painting. Our team delivers results that look great and last.'
  },
  {
    title: 'Coordinate Your Rooms with Room Painting',
    text: 'Create a harmonious living space with expert room painting. Our team delivers results that reflect your personal style.'
  },
  {
    title: 'Revitalize Your Kitchen with Cabinet Painting',
    text: 'Transform your kitchen with expert cabinet painting. Our team delivers flawless results for a fresh, functional look.'
  }
])

</script>

<!-- Tailwind Compliant -->
<template>
  <theme-base-section>
    <div
      class="mx-auto grid grid-cols-1 gap-x-8 gap-y-16 @sm:gap-y-20 @lg:mx-0 @lg:max-w-none @lg:grid-cols-3"
    >
      <!-- heading -->
      <div class="max-w-xl">
        <!-- overline -->
        <p class="text-lg font-semibold leading-8 tracking-tight text-theme-primary-600">
          {{ props.data?.content?.text?.overline || 'Expert Services' }}
        </p>

        <!-- headline -->
        <h3 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 @sm:text-4xl">
          {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
        </h3>

        <!-- body -->
        <p class="mt-6 text-base leading-7 text-gray-600">
          {{
            props.data?.content?.text?.body ||
              'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
          }}
        </p>
      </div>

      <!-- list -->
      <ul class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 @sm:grid-cols-2 @lg:gap-y-16">
        <li
          v-for="(item, bulletIdx) in bullets"
          :key="bulletIdx"
          class="relative pl-9"
        >
          <div class="font-semibold text-gray-900">
            <CheckIcon aria-hidden="true" class="absolute top-1 left-0 h-5 w-5 text-theme-primary-500" />
            {{ item?.title }}
          </div>
          <div class="mt-2">
            {{ item?.text }}
          </div>
        </li>
      </ul>
    </div>
  </theme-base-section>
</template>
