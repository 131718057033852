<script setup>
import { onMounted } from 'vue'
import { useAuthStore } from '@/stores/useAuthStore.js'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'
import { useDark, useToggle } from '@vueuse/core'
import useScreen from '@/composables/useScreen.js'

const useUser = useAuthStore()
const webStore = useWebsiteStore()

const { viewport } = useScreen()
const isDark = useDark()
useToggle(isDark)

onMounted(() => {
  useUser.handleAuthStateChange()
})

</script>

<template>
  <div>
    <!-- Router view -->
    <div v-if="webStore.isLoadingPage" class="h-screen flex items-center justify-center">
      <base-loading
        :is-loading="true"
      >
        <template #content>
          {{ webStore.loadingMessage }}
        </template>
      </base-loading>
    </div>

    <div v-else :style="{ 'max-height': `${viewport.viewHeight}px` }">
      <router-view />
    </div>

    <!-- Global components -->
    <base-modal />
    <base-notify />
  </div>
</template>
