
export const welder = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Expert Welding Services for Your Projects',
        body: 'From precision welding to custom fabrication, our team of skilled welders is committed to delivering exceptional quality and craftsmanship to elevate your projects.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are dedicated to providing exceptional welding services with a focus on quality, safety, and customer satisfaction. Our team of experts brings years of experience and expertise to every project, ensuring a flawless finish that exceeds your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Welding Services',
        headline: 'Our Comprehensive Welding Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your projects.'
      },
      bullets: [
        {
          title: 'Precision Welding',
          text: 'Trust us for your precision welding needs, whether for industrial or commercial projects. Our team uses only the highest-quality materials to ensure a strong and durable weld.'
        },
        {
          title: 'Custom Fabrication',
          text: 'We provide custom fabrication services to meet your unique needs. Our team works with you to design and fabricate custom metal parts and structures for your projects.'
        },
        {
          title: 'Welding Repairs',
          text: 'Let us help you get your project back on track with our reliable welding repair services. Our team will assess the damage and provide prompt and effective repairs to ensure minimal downtime.'
        },
        {
          title: 'Mobile Welding',
          text: 'Our mobile welding services allow us to come to you and provide on-site welding services for your convenience. Contact us to schedule a mobile welding appointment.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Mark D',
          rating: 5,
          location: 'Denver, CO',
          text: 'I am so happy with the welding work done by this team. They were professional, courteous, and went above and beyond to ensure a perfect finish. I highly recommend them!'
        },
        {
          name: 'Chris & Emily B.',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We were very impressed with the quality of the welding work done by this team. Our project looks beautiful, and the welders were reliable and efficient. Thank you for your excellent service!'
        },
        {
          name: 'Mike R',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'I am so happy I chose this team for my custom fabrication needs. Their experts were knowledgeable, friendly, and helped me design and fabricate the perfect metal structure for my project. I am thrilled with the results!'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional welding projects and see the difference our skilled professionals can make in elevating your projects.'
      }
    },
    cta: {
      text: {
        overline: 'Elevate Your Projects Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Welding',
        body: 'With a commitment to excellence and a passion for delivering stunning results, our team of professional welders is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for welding projects.'
      }
    },
    features: {
      text: {
        overline: 'Premium Welding Services',
        headline: 'Our Comprehensive Welding Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your project.'
      },
      bullets: [
        {
          title: 'Metal Fabrication',
          text: 'Our skilled welders can create custom metal fabrications for your project, including gates, railings, and decorative metal work. We use high-quality materials and techniques to ensure a durable and beautiful result.'
        },
        {
          title: 'Welding Repairs',
          text: 'Trust our team to repair your metal items with precision and care. We can repair everything from small parts to large structures, ensuring that they are safe and functional.'
        },
        {
          title: 'Welding Consultation',
          text: 'Let our welding experts guide you in selecting the perfect solution for your project. We offer personalized welding consultations to help you achieve the result you envision.'
        },
        {
          title: 'Mobile Welding',
          text: 'Our mobile welding services make it easy for us to come to you, whether you need welding repairs on-site or want us to create custom metal work at your location.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional welding projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Project Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Welding Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our welding services. From questions about pricing and scheduling, to tips for preparing your materials, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Welding Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your welding project.'
      },
      bullets: [
        {
          title: 'What types of welding services do you offer?',
          text: 'We offer a wide range of welding services, including MIG, TIG, and stick welding, as well as custom fabrication and repair work. Our team has the expertise and equipment necessary to handle any welding project.'
        },
        {
          title: 'What types of materials can you weld?',
          text: 'We can weld a variety of materials, including steel, aluminum, and stainless steel. Our team has experience working with a range of materials and can provide recommendations based on your project needs.'
        },
        {
          title: 'How long will my welding project take?',
          text: 'The time required to complete your welding project will depend on a variety of factors, such as the size of the project, the complexity of the work, and any necessary preparations. We will provide you with a detailed timeline before beginning work, and keep you informed throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Elevate Your Welding Project?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Welders',
        body: 'Have questions about our welding services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Contact Us for Your Welding Needs',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our welding services. Our team is standing by to answer your questions and help you with your welding needs.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our welding services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your welding project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your welding needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
