import { set } from 'lodash'
import { colors } from '@/utils/constants.js'

// format date to human date
// additional options // hour: 'numeric', minute: '2-digit'
export const useFormatDate = (date, format = 'long') => {
  if (format === 'full') {
    return new Date(date).toLocaleDateString('en',
      { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
  }
  if (format === 'long') {
    return new Date(date).toLocaleDateString('en',
      { year: 'numeric', month: 'short', day: 'numeric' })
  }
  if (format === 'short') {
    return new Date(date).toLocaleDateString('en',
      { year: '2-digit', month: '2-digit', day: '2-digit' })
  }
  if (format === 'monthDay') {
    return new Date(date).toLocaleDateString('en',
      { month: 'short', day: 'numeric' })
  }
  if (format === 'time') {
    return new Date(date).toLocaleDateString('en',
      { hour: 'numeric', minute: 'numeric' })
  }
  if (format === 'ago') {
    const diffMs = new Date() - new Date(date)
    const diffSeconds = Math.round(diffMs / 1000)
    const diffMinutes = Math.round(diffMs / 1000 / 60)
    const diffHours = Math.round(diffMs / 1000 / 60 / 60)
    const diffDays = Math.round(diffMs / 1000 / 60 / 60 / 24)

    if (diffSeconds < 60 && diffSeconds > 0) {
      return `${diffSeconds} seconds ago`
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`
    } else if (diffHours < 24) {
      return `${diffHours} hours ago`
    } else if (diffDays < 30 && diffDays > 1) {
      return `${diffDays} days ago`
    } else if (diffDays <= 1) {
      return `${diffDays} day ago`
    } else {
      return new Date(date).toLocaleDateString('en',
        { year: 'numeric', month: 'short', day: 'numeric' })
    }
  }
}

export const useFormatCurrency = (val) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val)
}

// return in user is premium based on plan
export const useUserIsPremium = (plan) => {
  return !!(plan === 'pro' || plan === 'pro_web') || plan === 'premium'
}

// update object by path using lodash
export function useUpdateObject (obj, path, val) {
  return set(obj, path, val)
}

// get the date x days in future or past from myDate if provided or current time
export function useGetDate (val, action = 'minus', myDate = null) {
  const date = myDate ? new Date(myDate) : new Date()
  const days = parseInt(val)
  // Use the setDate() method to set the date to 30 days ago
  if (action === 'minus') {
    date.setDate(date.getDate() - days)
  } else {
    date.setDate(date.getDate() + days)
  }
  // Return timestamp in milliseconds
  return date.getTime()
}

// Toggle item in array (remove or add)
export function useToggleArrayItem (array, val) {
  let index = null
  if (typeof val === 'object') {
    const newVal = val.name || val.val || val.value || val.id || val.uid
    index = array.findIndex(e => (e.name || e.val || e.value || e.id || val.uid) === newVal)
    if (index > -1) {
      /* array contains the element we're looking for, at index */
      array.splice(index, 1)
    } else {
      array.push(val)
    }
  } else if (array.includes(val)) {
    index = array.indexOf(val)
    array.splice(index, 1)
  } else {
    array.push(val)
  }
}

export function useTagColor (tag) {
  if (tag === 'account_issue') { return 'bg-red-500' }
  if (tag === 'app_crash') { return 'bg-orange-500' }
  if (tag === 'billing_issue') { return 'bg-amber-500' }
  if (tag === 'bug_report') { return 'bg-yellow-500' }
  if (tag === 'configuration_support') { return 'bg-lime-500' }
  if (tag === 'estimate_delivery') { return 'bg-green-500' }
  if (tag === 'feature_request') { return 'bg-emerald-500' }
  if (tag === 'login_problem') { return 'bg-teal-500' }
  if (tag === 'not_tradebox_issue') { return 'bg-cyan-500' }
  if (tag === 'product_feedback') { return 'bg-blue-500' }
  if (tag === 'urgent') { return 'bg-rose-500' }
}

export function useSortDescending (a, b) {
  if (a < b) { return 1 }
  if (a > b) { return -1 }
  return 0
}

export function useSortAscending (a, b) {
  if (a < b) { return -1 }
  if (a > b) { return 1 }
  return 0
}

export function useGetInitials (name) {
  if (typeof name === 'string' && name.trim() !== '') {
    const initials = name.match(/\b\w/g) || []
    return initials.join('').toUpperCase()
  } else {
    return ''
  }
}

export function useFormatBytes (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function useSafeUrl (str) {
  if (str) {
    return str
      .trim() // Remove leading/trailing white space
      .replace(/(^[^a-z0-9]+)|([^a-z0-9-.]+$)/gi, '') // Remove non-alphanumeric characters at the beginning or end
      .toLowerCase() // Convert to lowercase
      .replace(/([^a-z0-9-])/g, '-') // Replace non-alphanumeric characters with a single hyphen
      .replace(/(-+)/g, '-') // Replace consecutive hyphens with a single hyphen
  }
}

export function useGetNewUrl (originalUrl, masterUrlArray) {
  const safeUrl = useSafeUrl(originalUrl)

  if (masterUrlArray.includes(safeUrl)) {
    let i = 2
    while (masterUrlArray.includes(`${safeUrl}-${i}`)) {
      i++
    }
    return `${safeUrl}-${i}`
  } else {
    return safeUrl
  }
}

export function useRandomImage () {
  const randomNumber = Math.floor(Math.random() * 57) + 1
  return `img-${randomNumber}`
}

export function useRandomColor () {
  const randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex].theme
}

/**
 * Scrolls to the element with the provided class, id or element.
 *
 * @param {string | HTMLElement} target - The class, id or element to scroll to.
 * @param {ScrollBehavior | undefined} [behavior='smooth'] - The scrolling behavior to use.
 */
export function useScrollTo (target, behavior = 'smooth') {
  let el
  if (typeof target === 'string') {
    el = document.querySelector(`.${target}`)
    if (!el) {
      el = document.querySelector(`#${target}`)
    }
  } else if (target instanceof HTMLElement) {
    el = target
  }
  if (el) {
    el.scrollIntoView({ behavior })
  }
}
