<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<!-- Tailwind Compliant -->
<template>
  <div class="relative bg-white @container z-auto">
    <!--  content container -->
    <div class="mx-auto max-w-7xl">
      <div class="relative z-10 bg-white py-32 px-6 @sm:py-40 @lg:py-56 @lg:pr-0 @lg:w-full @lg:max-w-2xl transform -skew-x-12">
        <div class="max-w-2xl @lg:mx-0 @lg:max-w-xl transform skew-x-12">
          <!--  headline -->
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 @sm:text-6xl">
            {{ props.data?.content?.text?.headline || 'Beautiful Home Painting by Professionals' }}
          </h1>

          <!--  body -->
          <p class="mt-6 text-lg leading-8 text-gray-600">
            {{
              props.data?.content?.text?.body ||
                'Revitalize your space with the magic of a fresh coat of paint. Our team of professionals is dedicated to delivering clean lines, vibrant color, and flawless results that will leave you with a home that you\'ll love for years to come.'
            }}
          </p>

          <!--  button -->
          <div class="mt-10 flex items-center gap-x-6">
            <component
              :is="button.component"
              v-for="button in props.data?.content?.button"
              :key="button.uid"
              v-bind="button"
              @click="router.push(useWebsite.getRoutePath(button.link.href))"
            >
              {{ button.label || 'Get a free estimate' }}
            </component>
          </div>
        </div>
      </div>
    </div>

    <!--  image -->
    <div class="@lg:absolute @lg:inset-y-0 @lg:right-0 @lg:w-7/12 ">
      <theme-base-img
        :img-src="props.data?.content?.background_image?.url"
        alt=""
        class="aspect-[3/2] object-cover @lg:aspect-auto @lg:h-full @lg:w-full"
      />
    </div>
  </div>
</template>
