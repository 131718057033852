<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<!-- tailwind compliant -->
<template>
  <section class="@container z-10">
    <div class="max-w-screen-xl @lg:grid grid-cols-1 @lg:grid-cols-2 mx-auto relative gap-8">
      <!-- header -->
      <div class="@lg:col-span-1 justify-center items-center py-32 mx-6 @sm:mx-8 @md:mr-12">
        <div class="max-w-2xl @lg:mt-16">
          <h1 class="text-4xl @sm:text-5xl text-gray-900 font-bold ">
            {{ props.data?.content?.headline || 'Beautiful Home Painting by Professionals' }}
          </h1>
          <theme-base-subtitle>
            {{
              props. data?.content?.body ||
                'Revitalize your space with the magic of a fresh coat of paint. Our team of professionals is dedicated to delivering clean lines, vibrant color, and flawless results that will leave you with a home that you\'ll love for years to come.'
            }}
          </theme-base-subtitle>
          <div class="mt-8">
            <component
              :is="button.component"
              v-for="button in props.data?.content?.button"
              :key="button.uid"
              v-bind="button"
              @click="router.push(useWebsite.getRoutePath(button.link.href))"
            >
              {{ button.label || 'Get a free estimate' }}
            </component>
          </div>
        </div>
      </div>

      <!-- image -->
      <div class="@xl:pb-12">
        <theme-base-img
          :img-src="props.data?.content?.background_image?.url"
          alt=""
          class="h-full w-full object-cover @xl:rounded-lg @lg:w-screen"
        />
      </div>
    </div>
  </section>
</template>
