import { useUid, useRandomColor, useRandomImage, fileData } from '@/utils'
import { carpenter, cleaner, drywaller, electrician, handyman, hvac, landscaper, mason, painter, plumber, roofer, welder } from './copy'
import { cloneDeep } from 'lodash'

export const trades = [
  { name: 'carpenter', value: carpenter },
  { name: 'cleaner', value: cleaner },
  { name: 'drywaller', value: drywaller },
  { name: 'electrician', value: electrician },
  { name: 'handyman', value: handyman },
  { name: 'hvac', value: hvac },
  { name: 'landscaper', value: landscaper },
  { name: 'mason', value: mason },
  { name: 'painter', value: painter },
  { name: 'plumber', value: plumber },
  { name: 'roofer', value: roofer },
  { name: 'welder', value: welder }
]

export const tradeNames = trades.map(trade => trade.name)

function getPageData (trade) {
  const pageData = trades.find((t) => t.name === trade.toLowerCase())?.value
  if (!pageData) {
    throw new Error(`Invalid trade: ${trade}`)
  }
  return pageData
}

export function assignWebsiteCopy (website, trade) {
  const pageData = getPageData(trade)
  for (const page of website.pages) {
    const pageName = page.name
    const sections = page.body

    // iterate over the sections in the page
    for (const section of sections) {
      const sectionName = section.name

      // check if the section data exists in the pageData object
      if (pageData[pageName] && pageData[pageName][sectionName]) {
        const sectionData = pageData[pageName][sectionName]
        // assign the text and bullets to the section
        if (sectionData.text) {
          section.content.text = sectionData.text
        }
        if (sectionData.bullets) {
          section.content.bullets = sectionData.bullets
        }
      }
    }
  }

  return website
}

export function createWebsite (company, trade, color, theme) {
  let site = cloneDeep(website)
  site.uid = company?.uid
  site.company_uid = company?.uid
  site.created_at = Date.now()
  site.global.logo = company?.logo
  site.global.company.uid = company?.uid
  site.global.company.name = company?.contact?.name || ''
  site.global.company.phone = company?.contact?.phone || ''
  site.global.company.email = company?.contact?.email || ''
  site.global.company.email = company?.contact?.email || ''
  site.global.company.license_number = company?.business?.license_number || ''
  site.global.color_theme = color || useRandomColor()

  site = assignTheme(site, theme)
  site = assignWebsiteCopy(site, trade)

  console.log('website', site)
  return site
}

function assignTheme (site, theme) {
  // Update theme for all components in pages
  for (const page of site?.pages) {
    for (const section of page?.body) {
      const word = section.component
      section.component = word.substring(0, word.length - 1) + theme
    }
  }
  return site
}

export const website = {
  uid: '',
  company_uid: '',
  url: '',
  domain: '',
  created_at: Date.now(),
  published_at: null,
  first_published_at: null,
  version: 3.0,

  global: {
    color_theme: 'teal',
    company: {
      uid: '',
      name: '',
      phone: '',
      email: '',
      address: '',
      license_number: '',
      business_hours: {
        days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        open: '9:00am',
        close: '5:00pm'
      }
    },
    logo: fileData,
    links: [],
    page_navigation: [
      {
        uid: useUid(),
        name: 'Home',
        component: 'nav-item',
        link: {
          uid: useUid(),
          name: 'Home',
          href: 'home',
          external: false
        }
      },
      {
        uid: useUid(),
        name: 'About',
        component: 'nav-item',
        link: {
          uid: useUid(),
          name: 'About',
          href: 'about',
          external: false
        }
      },
      {
        uid: useUid(),
        name: 'FAQ',
        component: 'nav-item',
        link: {
          uid: useUid(),
          name: 'Faq',
          href: 'faq',
          external: false
        }
      },
      {
        uid: useUid(),
        name: 'Schedule',
        component: 'nav-item',
        link: {
          uid: useUid(),
          name: 'Schedule',
          href: 'schedule',
          external: false
        }
      },
      /* {
        uid: useUid(),
        name: 'Estimate',
        component: 'nav-item',
        link: {
          uid: useUid(),
          name: 'Estimate',
          href: 'estimate',
          external: false
        }
      }, */
      {
        uid: useUid(),
        name: 'Contact Us',
        component: 'nav-item',
        link: {
          uid: useUid(),
          name: 'Contact',
          href: 'contact',
          external: false
        }
      }
    ]
  },

  schedule: {
    is_enabled: false,
    available: {
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
      time_ranges: [{
        start: {
          value: '8:00am',
          time24h: '0800'
        },
        end: {
          value: '5:00pm',
          time24h: '1700'
        }
      }]
    },
    google: {
      save_to_calendar: '',
      calendars: []
    }
  },

  //   Example layout (Header/Footer) - Draft
  layout: {
    name: 'Default Layout',
    uid: useUid(),
    meta_data: null,
    content: {
      component: 'default-layout',
      header_component: 'theme-navbar-1',
      footer_component: 'theme-footer-1',
      social: true,
      footer_about: 'We are a trusted name in the construction industry, providing quality services to clients in the area. With a commitment to excellence, our team delivers exceptional workmanship, timely completion, and unparalleled customer service. Contact us today to learn more about how we can help with your construction needs.'
    }
  },

  // Pages
  pages: [
    // Home page
    {
      uid: useUid(),
      name: 'home page',
      path: 'home',
      meta_data: null,
      body: [
        // Hero
        {
          uid: useUid(),
          name: 'hero',
          editor_name: 'hero',
          component: 'theme-hero-3',
          component_has: ['background_image', 'button'],
          content: {
            text: {
              headline: '',
              body: ''
            },
            button: [
              {
                uid: useUid(),
                component: 'theme-base-button',
                label: 'Learn More',
                link: {
                  uid: useUid(),
                  name: 'Learn More',
                  href: 'about',
                  external: false
                }
              }],
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // CTA
        {
          uid: useUid(),
          name: 'about',
          editor_name: 'about',
          component: 'theme-cta-1',
          component_has: ['button'],
          style: {
            alignment: 'center',
            reverse_layout: false,
            background_color: ''
          },
          content: {
            text: {
              headline: '',
              body: ''
            },
            button: [
              {
                uid: useUid(),
                component: 'theme-base-button',
                label: 'Contact Us',
                style: {
                  inverse: false
                },
                link: {
                  uid: useUid(),
                  name: 'Contact Us',
                  href: 'contact',
                  external: false
                }
              }],
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // features
        {
          component: 'theme-features-1',
          name: 'features',
          editor_name: 'services',
          uid: useUid(),
          component_has: ['button', 'bullets'],
          style: {
            text_color: 'light',
            image_layout: 'fixed-height',
            reverse_layout: true,
            background_color: ''
          },
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            },
            bullets: []
          }
        },
        // reviews
        {
          uid: useUid(),
          component: 'theme-reviews-1',
          name: 'reviews',
          editor_name: 'reviews',
          component_has: ['bullets', 'background_image'],
          content: {
            bullets: [],
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // gallery
        {
          uid: useUid(),
          component: 'theme-gallery-1',
          name: 'gallery',
          editor_name: 'gallery',
          component_has: ['images'],
          styles: {
            cols: '1',
            rounded: true,
            background_color: 'white'
          },
          content: {
            text: {
              headline: '',
              body: ''
            },
            images: [
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              },
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              },
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              },
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              }
            ]
          }
        },
        // CTA
        {
          uid: useUid(),
          component: 'theme-cta-1',
          name: 'cta',
          editor_name: 'Call to Action',
          component_has: ['button'],
          style: {
            alignment: 'center',
            reverse_layout: false,
            background_color: ''
          },
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            },
            button: [
              {
                uid: useUid(),
                component: 'theme-base-button',
                label: 'Contact Us',
                style: {
                  inverse: false
                },
                link: {
                  uid: useUid(),
                  href: 'contact',
                  external: false
                }
              }]
          }
        }
      ]
    },

    // About page
    {
      uid: useUid(),
      name: 'about page',
      editor_name: 'about',
      path: 'about',
      meta_data: null,
      body: [
        // header
        {
          uid: useUid(),
          component: 'theme-page-header-1',
          component_has: ['background_image'],
          name: 'header',
          editor_name: 'header',
          content: {
            text: {
              headline: '',
              body: ''
            },
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // features
        {
          component: 'theme-features-1',
          name: 'features',
          editor_name: 'services',
          uid: useUid(),
          component_has: ['button', 'bullets'],
          style: {
            text_color: 'light',
            image_layout: 'fixed-height',
            reverse_layout: true,
            background_color: ''
          },
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            },
            bullets: []
          }
        },
        // Gallery
        {
          uid: useUid(),
          component: 'theme-gallery-1',
          name: 'gallery',
          editor_name: 'gallery',
          component_has: ['images'],
          styles: {
            cols: '1',
            rounded: true,
            background_color: 'white'
          },
          content: {
            text: {
              headline: '',
              subheadline: ''
            },
            images: [
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              },
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              },
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              },
              {
                uid: useUid(),
                name: '',
                size: 0,
                type: 'jpg',
                storage_path: '',
                url: useRandomImage()
              }
            ]
          }
        },
        // CTA
        {
          uid: useUid(),
          component: 'theme-cta-1',
          name: 'cta',
          editor_name: 'Call to Action',
          component_has: ['button'],
          style: {
            alignment: 'center',
            reverse_layout: false,
            background_color: ''
          },
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            },
            button: [
              {
                uid: useUid(),
                component: 'theme-base-button',
                label: 'Contact Us',
                style: {
                  inverse: false
                },
                link: {
                  uid: useUid(),
                  href: 'contact',
                  external: false
                }
              }]
          }
        }
      ]
    },

    // Faq page
    {
      uid: useUid(),
      name: 'faq page',
      path: 'faq',
      meta_data: null,
      body: [
        // Hero
        {
          uid: useUid(),
          component: 'theme-page-header-1',
          component_has: ['background_image'],
          name: 'header',
          editor_name: 'header',
          content: {
            text: {
              headline: '',
              body: ''
            },
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // faq
        {
          component: 'theme-faq-1',
          name: 'faq',
          editor_name: 'faq',
          uid: useUid(),
          component_has: ['bullets'],
          style: {
            reverse_layout: true,
            background_color: ''
          },
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            },
            bullets: []
          }
        },
        // CTA
        {
          uid: useUid(),
          component: 'theme-cta-1',
          name: 'cta',
          editor_name: 'Call to Action',
          component_has: ['button'],
          style: {
            alignment: 'center',
            reverse_layout: false,
            background_color: ''
          },
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            },
            button: [
              {
                uid: useUid(),
                component: 'theme-base-button',
                label: 'Contact Us',
                style: {
                  inverse: false
                },
                link: {
                  uid: useUid(),
                  href: 'contact',
                  external: false
                }
              }]
          }
        }
      ]
    },

    // Contact Page
    {
      uid: useUid(),
      name: 'contact page',
      path: 'contact',
      meta_data: null,
      body: [
        // Hero
        {
          uid: useUid(),
          component: 'theme-page-header-1',
          component_has: ['background_image'],
          name: 'header',
          editor_name: 'header',
          content: {
            text: {
              headline: '',
              body: ''
            },
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // contact
        {
          component: 'theme-contact-1',
          name: 'contact',
          editor_name: 'contact',
          uid: useUid(),
          component_has: ['contact_form'],
          style: {
            reverse_layout: false,
            background_color: ''
          },
          content: {
            text: {
              headline: '',
              body: ''
            },
            contact_form: [
              {
                uid: 1,
                label: 'name',
                description: 'Enter your full name.',
                input: 'input',
                type: 'text',
                required: true
              },
              {
                uid: 4,
                label: 'email',
                description: 'Enter your email address.',
                input: 'input',
                type: 'email',
                required: true,
                isSelected: true
              }
            ]
          }
        }

      ]
    },
    {
      uid: useUid(),
      name: 'schedule page',
      path: 'schedule',
      meta_data: null,
      body: [
        // Hero
        {
          uid: useUid(),
          component: 'theme-page-header-1',
          component_has: ['background_image'],
          name: 'header',
          editor_name: 'header',
          content: {
            text: {
              headline: 'Schedule with us',
              body: 'Easily schedule an appointment with us using our online booking system. Choose a date and time that works for you and receive top-quality care from our experienced professionals. Book your appointment now!'
            },
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        // schedule
        {
          uid: useUid(),
          component: 'theme-schedule-1',
          component_has: [],
          name: 'client schedule',
          editor_name: 'client schedule',
          content: {
            text: {
              overline: 'Schedule an Appointment',
              headline: 'Book Your Appointment Online',
              body: 'Welcome to our scheduling section! You can conveniently book your appointment online by selecting a date and time that suits you best. Our scheduling system is user-friendly and designed to make the process quick and hassle-free. Take control of your schedule and reserve your spot now!'
            }
          }
        }
      ]
    }

    // Estimate Page
    /* {
      uid: useUid(),
      name: 'estimate page',
      path: 'estimate',
      meta_data: null,
      body: [
        // Hero
        {
          uid: useUid(),
          component: 'theme-page-header-1',
          component_has: ['background_image'],
          name: 'header',
          editor_name: 'header',
          content: {
            text: {
              headline: '',
              body: ''
            },
            background_image: {
              uid: useUid(),
              name: '',
              size: 0,
              type: 'jpg',
              storage_path: '',
              url: useRandomImage()
            }
          }
        },
        {
          uid: useUid(),
          component: 'estimate-selector',
          component_has: [],
          name: 'estimate selector',
          editor_name: 'estimate selector',
          content: {
            text: {
              overline: '',
              headline: '',
              body: ''
            }
          }
        }
      ]
    } */
  ]
}
