import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

export default {
  install (app) {
    // base components
    const baseFiles = import.meta.glob(
      '@/components/base/*.vue',
      { eager: true }
    )

    Object.entries(baseFiles).forEach(([path, m]) => {
      const baseName = upperFirst(camelCase(path.split('/').pop().replace(/\.\w+$/, '')))

      app.component(`Base${baseName}`, m.default)
    })

    // theme base components
    const themeBaseFiles = import.meta.glob(
      '@/components/theme/base/*.vue',
      { eager: true }
    )

    Object.entries(themeBaseFiles).forEach(([path, m]) => {
      const themeBaseName = upperFirst(camelCase(path.split('/').pop().replace(/\.\w+$/, '')))

      app.component(`ThemeBase${themeBaseName}`, m.default)
    })

    // theme section components
    const themeSectionFiles = import.meta.glob(
      '@/components/theme/sections/*.vue',
      { eager: true }
    )

    Object.entries(themeSectionFiles).forEach(([path, m]) => {
      const themeSectionName = upperFirst(camelCase(path.split('/').pop().replace(/\.\w+$/, '')))
      app.component(`Theme${themeSectionName}`, m.default)
    })

    // theme estimate components
    /* const estimateSectionFiles = import.meta.glob(
      '@/components/estimate/!*.vue',
      { eager: true }
    )

    Object.entries(estimateSectionFiles).forEach(([path, m]) => {
      const estimateSectionName = upperFirst(camelCase(path.split('/').pop().replace(/\.\w+$/, '')))
      app.component(`Estimate${estimateSectionName}`, m.default)
    }) */
  }
}
