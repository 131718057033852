<script setup>
import { computed } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useUid } from '@/utils/index.js'

defineProps({
  image: {
    type: Object,
    default: () => {}
  },
  isOpen: {
    type: Boolean,
    required: true,
    default: false
  }
})

const emit = defineEmits(['update:image', 'close', 'update'])

const images = computed(() => {
  const assets = []
  for (let i = 1; i <= 72; i++) {
    assets.push({
      uid: useUid(),
      name: `Project Image ${i}`,
      size: 0,
      type: 'jpg',
      storage_path: '',
      url: `img-${i}`
    })
  }
  return assets
})

function updateImage (item) {
  emit('update:image', item)
  emit('close')
  emit('update', item)
}
</script>

<template>
  <TransitionRoot
    as="template"
    :show="isOpen"
  >
    <Dialog
      v-slot="{open}"
      as="div"
      class="relative z-10 "
      @close="open = false"
    >
      <!-- background cover -->
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-800/75 transition-opacity" />
      </TransitionChild>

      <!-- dialog -->
      <div class="fixed inset-0 z-10 overflow-hidden">
        <div class="flex min-h-full items-end justify-center sm:p-4 text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform sm:rounded-lg bg-white text-left shadow-xl transition-all"
            >
              <div class="flex justify-between px-4 pt-5 pb-4 sm:p-6">
                <div>
                  <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Choose a Photo
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Pick from several hand curated images for your websites.
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    class="rounded-md bg-white text-gray-500 hover:bg-gray-100 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    @click.prevent="emit('close')"
                  >
                    <span class="sr-only">Close Image picker</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>

              <div class="px-4 sm:px-6 pb-8 overflow-y-auto h-screen sm:max-h-[calc(100vh_-_14rem)] w-screen  sm:max-w-[80vw] scrollbar--none">
                <!-- headline -->

                <!-- image list -->
                <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 mt-4">
                  <li
                    v-for="(item, key) in images"
                    :key="key"
                    class="relative"
                    @click="updateImage(item)"
                  >
                    <div class="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                      <theme-base-img
                        :img-src="item.url"
                        extension="jpg"
                        class="pointer-events-none object-cover group-hover:opacity-75"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
