<script setup>
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'
import { computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  global: {
    type: Object,
    default: () => {}
  },
  editor: {
    type: Boolean,
    default: false
  }
})

const useWebsite = useWebsiteStore()

const navigation = computed(() => props.global.page_navigation)
const contactList = computed(() => {
  return {
    name: {
      label: props.global?.company?.name
    },
    phone: {
      label: props.global?.company?.phone,
      href: `tel:+1${props.global?.company?.phone}`
    },
    email: {
      label: props.global.company.email,
      href: `mailto:${props.global?.company?.email}`
    },
    address: {
      label: props.global.company.address,
      href: `https://maps.google.com/?q=${props.global?.company?.address}`,
      target: '_blank'
    }
  }
})

</script>

<template>
  <footer aria-labelledby="footer-heading" class="@container bg-gray-950">
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>
    <div class="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
      <div class="grid grid-cols-1 @md:grid-cols-8 md:gap-8 xl:col-span-2">
        <div class="md:col-span-3 md:gap-8">
          <div class="mt-10 md:mt-0">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">
                {{ props.global.company.name }}
              </h3>

              <p v-if="props.global?.company?.license_number" class="text-sm leading-6 text-gray-300 max-w-sm">
                License #{{ props.global?.company?.license_number }}
              </p>
            </div>

            <p class="text-sm leading-6 text-gray-300 max-w-sm mt-6">
              {{ props.data.content.footer_about }}
            </p>
          </div>
        </div>

        <div class="md:col-span-2 md:gap-8">
          <div class="mt-10 md:mt-0">
            <h3 class="text-sm font-semibold leading-6 text-white">
              {{ props.data?.content?.footer_nav_1_headline || 'Links' }}
            </h3>
            <ul class="mt-6 space-y-4" role="list">
              <template v-for="item in navigation" :key="item.name">
                <li v-if="item.name !== 'Estimate' || (item.name === 'Estimate' && props.global.enable_estimate)">
                  <router-link
                    :to="useWebsite.getRoutePath(item.link.href)"
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>

        <div class="md:col-span-2 md:gap-8">
          <div class="mt-10 md:mt-0">
            <h3 class="text-sm font-semibold leading-6 text-white">
              Contact
            </h3>
            <ul class="mt-6 space-y-4" role="list">
              <li v-for="(item, key) in contactList" :key="key">
                <a
                  :href="item.href"
                  :target="item.target"
                  class="text-sm leading-6 text-gray-300 hover:text-white"
                >
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
