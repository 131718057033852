<!-- This example requires Tailwind CSS v3.0+ -->
<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})
const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<!-- Tailwind Compliant -->
<template>
  <theme-base-section class="bg-theme-primary-700">
    <div class="max-w-2xl mx-auto text-center">
      <!-- headline -->
      <h2 class="text-4xl font-bold tracking-tight text-white">
        {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Services' }}
      </h2>

      <!-- body -->
      <p class="mt-6 text-lg/8 text-theme-primary-100">
        {{
          props.data?.content?.text?.body ||
            'Bring new life to your living space with our professional solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
        }}
      </p>

      <!-- button -->
      <div class="mt-10">
        <component
          :is="button.component"
          v-for="button in props.data?.content?.button"
          :key="button.uid"
          v-bind="button"
          @click="router.push(useWebsite.getRoutePath(button.link.href))"
        >
          {{ button.label || 'Get in touch' }}
        </component>
      </div>
    </div>
  </theme-base-section>
</template>
