<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<!-- tailwind compliant -->
<template>
  <section class="@container">
    <div class="relative bg-gray-900 flex flex-wrap-reverse">
      <!-- image - collapse below  -->
      <div
        class="relative h-80 w-full overflow-hidden bg-theme-primary-700 @md:absolute @md:left-0 @md:h-full @md:w-1/3 @lg:w-1/2"
      >
        <theme-base-img
          :img-src="props.data?.background_image?.url"
          alt=""
          class="h-full w-full object-cover"
        />
      </div>

      <!-- text -->
      <div class="relative mx-auto max-w-7xl py-24 @sm:py-32 @lg:py-40 @lg:px-8">
        <div class="pr-6 pl-6 @md:ml-auto @md:w-2/3 @md:pl-16 @lg:w-1/2 @lg:pl-24 @lg:pr-0 xl:pl-32">
          <!-- headline -->
          <h3 class="mt-2 text-4xl font-bold tracking-tight text-white">
            {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
          </h3>

          <!-- body -->
          <p class="mt-6 text-base/7 text-gray-300">
            {{
              props.data.body ||
                'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
            }}

          <!-- button -->
          </p><div class="mt-8">
            <component
              :is="button.component"
              v-for="button in props.data?.content?.button"
              :key="button.uid"
              v-bind="button"
              @click="router.push(useWebsite.getRoutePath(button.link.href))"
            >
              {{ button.label || 'Get in touch' }}
            </component>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
