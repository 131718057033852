import { defineStore, acceptHMRUpdate } from 'pinia'

export const useValidateStore = defineStore('validate', {
  state: () => ({
    errors: []
  }),

  actions: {
    clear () {
      this.errors = []
    },

    addError (val) {
      this.errors.push(val)
    },

    removeError (val) {
      const index = this.errors.indexOf(val)
      this.errors.splice(index, 1)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useValidateStore, import.meta.hot))
}
