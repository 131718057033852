<script setup>
import { useModalStore } from '@/stores/useModalStore'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'

const modal = useModalStore()
</script>

<template>
  <TransitionRoot
    :show="modal.isOpen"
    as="template"
  >
    <Dialog
      v-slot="{open}"
      as="div"
      class="relative z-10"
      @close="open = false"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/75 transition-opacity" />
      </TransitionChild>

      <div
        class="fixed inset-0 overflow-y-auto"
        style="z-index: 1000 !important;"
      >
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 z-50 dark:border"
            >
              <div>
                <div
                  v-if="modal.type === 'success'"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <CheckIcon
                    aria-hidden="true"
                    class="h-6 w-6 text-green-600"
                  />
                </div>
                <div
                  v-if="modal.type === 'destructive' || modal.type === 'error'"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                >
                  <ExclamationCircleIcon
                    aria-hidden="true"
                    class="h-6 w-6 text-red-500"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    {{ modal.title }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{ modal.text }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                :class="[`mt-6 grid grid-flow-row-dense gap-3`, modal?.action?.name ? 'grid-cols-2': 'grid-cols-1']"
              >
                <base-button
                  ref="cancelButtonRef"
                  class="flex justify-center"
                  outlined
                  @click="modal.onCancel()"
                >
                  {{ modal.action.cancel || 'Cancel' }}
                </base-button>
                <base-button
                  v-if="modal?.action?.name"
                  :destructive="modal.type === 'destructive'"
                  class="flex justify-center"
                  type="button"
                  @click="modal.onConfirm()"
                >
                  {{ modal.action.name }}
                </base-button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
