<script setup>
import { ref } from 'vue'
import { useScroll, useElementSize } from '@vueuse/core'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const el = ref(null)
const { x } = useScroll(el, { behavior: 'smooth' })
const { width } = useElementSize(el)
</script>

<!-- tailwind compliant -->
<template>
  <theme-base-section v-if="props.data?.content?.bullets?.length">
    <div class="relative mx-auto max-w-6xl px-6 @lg:px-8">
      <div class="relative overflow-hidden rounded-xl bg-gray-600 py-24 shadow-2xl ">
        <!-- background image -->
        <div class="absolute inset-0 opacity-50 mix-blend-multiply saturate-0 filter">
          <theme-base-img
            v-if="props.data?.content?.background_image?.url"
            :img-src="props.data?.content?.background_image?.url"
            alt=""
            class="h-full w-full object-cover"
          />
        </div>

        <!-- review overlay -->
        <div
          ref="el"
          class="flex flex-nowrap w-full overflow-x-auto snap-mandatory snap-x hide-scrollbar scrollbar--none items-center"
        >
          <!-- left navigation arrow -->
          <button
            class="absolute z-10 hover:bg-gray-700/30 rounded-full mx-4 mr-6 p-2 hidden @md:block"
            @click="x -= (width * 0.7)"
          >
            <ChevronLeftIcon class="text-gray-200 h-6 w-6" />
          </button>

          <!-- review -->
          <div
            v-for="(item, index) in props.data.content.bullets"
            :key="index"
            class="relative snap-center min-w-fit px-4 @sm:px-12 flex justify-between items-center"
          >
            <blockquote class="mt-6 text-white @sm:px-12 ">
              <p class="text-xl font-medium @sm:text-2xl">
                {{ item?.text }}
              </p>
              <footer class="mt-6">
                <p class="flex flex-col font-medium">
                  <span>{{ item?.name }}</span>
                  <span>{{ item?.location }}</span>
                </p>
              </footer>
            </blockquote>
          </div>

          <!-- right navigation arrow -->
          <button
            class="absolute right-0 z-10 hover:bg-gray-700/30 rounded-full mx-4 mr-6 p-2 hidden @md:block"
            @click="x += (width * 0.7)"
          >
            <ChevronRightIcon class="text-gray-200 h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  </theme-base-section>
</template>
