<script setup>
import { computed } from 'vue'
import googleLogo from '@/assets/partner-brand-identity/google/btn_google_light_normal.svg'
import appleWhite from '@/assets/partner-brand-identity/apple/White-Logo-Only.svg'

const props = defineProps({
  provider: {
    type: String,
    default: 'google'
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const providerLogo = computed(() => props.provider === 'google' ? googleLogo : props.provider === 'apple' ? appleWhite : null)
</script>

<template>
  <!-- bg-[#4285F4] -->
  <button
    :class="[props.provider === 'google' ? ' space-x-[9px] text-gray-500 bg-white': 'bg-black text-white space-x-[3px] pr-4 border-black' ]"
    :disabled="props.disabled"
    class="group inline-flex items-center justify-center rounded-md text-sm shadow-sm border pr-4 min-w-[280px] h-[48px] relative"
    type="button"
  >
    <img
      :src="providerLogo"
      alt=""
      height="h-4 w-4"
    >
    <!--    <div class="bg-red-600/50 h-[18px] w-[24px] absolute left-[96px]" /> -->
    <!-- Content slot -->
    <slot />
  </button>
</template>

<style>
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.auth-text {
  font-family: 'Roboto', sans-serif;
}*/
</style>
