<script setup>
import { onUnmounted } from 'vue'
import { useNotifyStore } from '@/stores/useNotifyStore'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'

const useNotify = useNotifyStore()

const unsubscribe = useNotify.$onAction(
  ({ name, after, onError }) => {
    after((result) => {
      if (name === 'openNotify' && useNotify.notifications.length) {
        setTimeout(() => {
          useNotify.notifications[result.index].isOpen = false
        }, result.notification.timeout)
      }
    })
    onError((error) => {
      console.warn(`Failed "${name}" \nError: ${error}.`)
    })
  }
)

onUnmounted(() => {
  unsubscribe()
})
</script>

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-40 flex items-start px-4 py-6 sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <template
        v-for="(item, index) in useNotify.notifications"
        :key="item.id"
      >
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="item.isOpen"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg border"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    v-if="item.type === 'success'"
                    aria-hidden="true"
                    class="h-6 w-6 text-green-500"
                  />
                  <ExclamationCircleIcon
                    v-if="item.type === 'error'"
                    aria-hidden="true"
                    class="h-6 w-6 text-red-500"
                  />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">
                    {{ item.title }}
                  </p>
                  <p class="mt-1 text-sm text-gray-500">
                    {{ item.text }}
                  </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    class="inline-flex rounded-md text-gray-700 hover:text-gray-800 focus:outline-none"
                    type="button"
                    @click="useNotify.closeNotify(index)"
                  >
                    <span class="sr-only">Close</span>
                    <XMarkIcon
                      aria-hidden="true"
                      class="h-5 w-5"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>
