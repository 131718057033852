<!-- This example requires Tailwind CSS v3.0+ -->
<script setup>
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'
import { computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})

const router = useRouter()
const useWebsite = useWebsiteStore()
</script>

<template>
  <theme-base-section class="bg-theme-primary-700">
    <div class="max-w-2xl">
      <!-- headline -->
      <theme-base-title class="text-white">
        {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
      </theme-base-title>

      <!-- body -->
      <theme-base-subtitle class="text-theme-primary-100">
        {{
          props.data?.content?.text?.body ||
            'Bring new life to your living space with our professional painting solutions. Our team of experts is dedicated to delivering exceptional quality and unparalleled customer service, ensuring that your home is transformed into the beautiful, functional space you\'ve always wanted.'
        }}
      </theme-base-subtitle>

      <!--  button -->
      <div class="mt-10">
        <component
          :is="button.component"
          v-for="button in props.data?.content?.button"
          :key="button.uid"
          v-bind="button.style"
          @click="router.push(useWebsite.getRoutePath(button.link.href))"
        >
          {{ button.label || 'Get in touch' }}
        </component>
      </div>
    </div>
  </theme-base-section>
</template>
