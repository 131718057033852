
export const mason = {
  // page name
  'home page': {
    hero: {
      text: {
        headline: 'Elevate Your Masonry with Expert Services',
        body: 'From stunning patios to precise stone work, our team of skilled masons will bring your vision to life with unparalleled craftsmanship and attention to detail.'
      }
    },
    about: {
      text: {
        headline: 'Experience the Difference',
        body: 'We are passionate about transforming your outdoor living space with exceptional masonry services. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring a flawless finish that exceeds your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Masonry Services',
        headline: 'Our Comprehensive Masonry Solutions',
        body: 'From start to finish, our team is dedicated to delivering exceptional results that elevate your outdoor living space.'
      },
      bullets: [
        {
          title: 'Patios',
          text: 'Transform your outdoor space with our expert patio installation services. We use only the highest-quality materials to create a flawless finish that lasts.'
        },
        {
          title: 'Stone Work',
          text: 'Enhance your property with our reliable stone work services. Our team will carefully prepare and install every stone, ensuring a durable and long-lasting finish.'
        },
        {
          title: 'Retaining Walls',
          text: 'Let our team build a sturdy retaining wall to protect your property and elevate its appearance. We offer personalized consultations to help you achieve the look you envision.'
        },
        {
          title: 'Outdoor Fireplaces',
          text: 'Create a cozy outdoor living space with our professional outdoor fireplace installation services. We use high-quality materials and finishes to breathe new life into your outdoor space.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Alex B.',
          rating: 5,
          location: 'Denver, CO',
          text: 'They did an amazing job on our patio. The team was professional, courteous, and went above and beyond to ensure a perfect finish. We highly recommend them!'
        },
        {
          name: 'Tom & Emily P.',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'We were so impressed with the quality of the work done by them. Our retaining wall looks beautiful, and the masons were reliable and efficient. Thank you for your excellent service!'
        },
        {
          name: 'Chris L.',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'I am so happy I chose them for my outdoor fireplace installation. Their experts were knowledgeable, friendly, and helped me choose the perfect design for my home. I am thrilled with the results!'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional masonry projects and see the difference our skilled professionals can make in transforming your outdoor living space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Outdoor Living Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Experience the Difference with Our Expert Masonry Services',
        body: 'With a commitment to excellence and a passion for delivering stunning results, our team of expert masons is dedicated to providing exceptional quality and customer service. Get to know us and see why we\'re the trusted choice for transforming homes and outdoor spaces.'
      }
    },
    features: {
      text: {
        overline: 'Premium Masonry Services',
        headline: 'Our Comprehensive Masonry Solutions',
        body: ' From start to finish, our team is dedicated to delivering exceptional results that elevate your outdoor space.'
      },
      bullets: [
        {
          title: 'Outdoor Living Spaces',
          text: 'Transform your outdoor living space with our expert masonry services. From outdoor fireplaces and fire pits to patios and walkways, we use only the highest-quality materials to create a beautiful and functional outdoor space.'
        },
        {
          title: 'Retaining Walls',
          text: 'Protect and enhance your property with our reliable retaining wall services. Our team will carefully plan and construct a retaining wall that is both functional and beautiful.'
        },
        {
          title: 'Stone Veneers',
          text: 'Upgrade the look of your home with our professional stone veneer installation services. We use high-quality materials and expert craftsmanship to create a beautiful and durable finish.'
        },
        {
          title: 'Brickwork',
          text: 'From chimney repairs to new construction, our team of expert masons can handle all your brickwork needs with precision and skill.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional masonry projects and see the difference our skilled professionals can make in transforming your outdoor space.'
      }
    },
    cta: {
      text: {
        overline: 'Transform Your Outdoor Space Today',
        headline: 'Request a Consultation to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Masonry Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our masonry services. From questions about pricing and scheduling, to tips for preparing your property, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Masonry Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your masonry project.'
      },
      bullets: [
        {
          title: 'How long will it take to complete my masonry project?',
          text: 'The time required to complete your masonry project will depend on a variety of factors, such as the size of the project, the scope of the work, and any necessary preparations. We will provide you with a detailed timeline before beginning work, and keep you informed throughout the process.'
        },
        {
          title: 'What materials do you use for your masonry projects?',
          text: 'We use only the highest-quality materials and tools to ensure a durable and long-lasting finish. Our team can provide recommendations based on your needs and preferences, and we are happy to work with any masonry materials of your choice.'
        },
        {
          title: 'Will the masonry process be disruptive to my daily routine?',
          text: 'We understand that having masonry work done on your property can be an inconvenience, and we will do everything we can to minimize disruption to your daily routine. Our team will work efficiently and cleanly, and will be respectful of your property throughout the process.'
        },
        {
          title: 'What if I am not satisfied with the final result?',
          text: 'Our team is committed to delivering exceptional results that exceed your expectations. In the unlikely event that you are not satisfied with the final product, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Ready to Enhance Your Property?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Masons',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Ready to Start Your Masonry Project?',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our masonry services. Our team is standing by to answer your questions and help you transform your space with exceptional masonry solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for Project Pricing?',
        body: 'We understand that you want to make informed decisions about your masonry project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your space and masonry needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
