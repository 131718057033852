import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import '@/assets/styles/styles.css'
import '@/assets/styles/tailwind.css'
import App from './App.vue'
import router from './router'
import { GoogleMaps, BaseComponents } from '@/boot'
import { screen, platform } from '@/plugins'

const app = createApp(App)
const pinia = createPinia()

// Make router available in pinia stores
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app.use(platform)
app.use(screen)
app.use(GoogleMaps)
app.use(BaseComponents)
app.use(pinia)
app.use(router)
app.mount('#app')
