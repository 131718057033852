<script setup>
import { useWebsiteStore } from '@/stores/useWebsiteStore.js'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  global: {
    type: Object,
    required: true
  }
})
const useWebsite = useWebsiteStore()
</script>

<!-- tailwind compliant -->
<template>
  <theme-base-section>
    <!-- header -->
    <div class="max-w-2xl">
      <theme-base-overline>
        {{ props.data?.content?.text?.headline || 'Experience Our Exceptional Craftsmanship' }}
      </theme-base-overline>

      <theme-base-title>
        {{ props.data?.content?.text?.headline || 'Revitalize Your Home with Expert Painting Services' }}
      </theme-base-title>

      <theme-base-subtitle>
        {{
          props.data?.content?.text?.body ||
            'Discover the difference that quality craftsmanship and a commitment to excellence can make in your home. Our team takes pride in every job, no matter the size, and is dedicated to delivering results that exceed your expectations. Browse our gallery to see the results for yourself.'
        }}
      </theme-base-subtitle>
    </div>

    <!-- gallery -->
    <ul class="mt-12 space-y-6 @sm:space-y-0 @sm:grid @sm:grid-cols-2 @md:grid-cols-3 gap-4 @sm:gap-6">
      <template v-if="props.data?.content?.images">
        <li
          v-for="(item, key) in props.data?.content?.images"
          :key="key"
        >
          <figure class="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
            <theme-base-img
              :type="item.type"
              :img-src="item.url"
              class="w-full h-auto object-cover sm:hover:scale-105 transition-all duration-300"
            />
          </figure>
        </li>
      </template>
      <template v-else>
        <li
          v-for="(item, key) in useWebsite.randomImages()"
          :key="key"
        >
          <figure class="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
            <theme-base-img
              :img-src="item"
              class="w-full h-auto object-cover sm:hover:scale-105 transition-all duration-300"
            />
          </figure>
        </li>
      </template>
    </ul>
  </theme-base-section>
</template>
