<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watchEffect } from 'vue'
import { loader } from '@/composables/GoogleMaps.js'
import { usePlatform } from '@/composables/usePlatform.js'

const props = defineProps({
  address: {
    type: Object,
    default: () => {
      return { lat: null, long: null }
    }
  },
  height: {
    type: String,
    default: '100%'
  },
  width: {
    type: String,
    default: '100%'
  },
  link: {
    type: Boolean,
    default: false
  },
  lat: {
    type: Number,
    default: null
  },
  long: {
    type: Number,
    default: null
  },
  zoom: {
    type: Number,
    default: 13
  }
})

const { device } = usePlatform

const href = ref(null)
const mapContainer = ref(null)
const coordinates = reactive({
  lat: props.address?.lat || props.lat,
  lng: props.address?.long || props.long
})

const styles = computed(() => {
  return {
    width: `${props.width}`,
    height: `${props.height}`
  }
})

const mapCoordinates = computed(() => `${coordinates.lat},${coordinates.lng}`)

watchEffect(() => {
  coordinates.lat = props.address?.lat || props.lat
  coordinates.lng = props.address?.long || props.long
  initMap()

  const hasCoordinates = (props.lat && props.long) || (props.address.lat && props.address.long)
  const hasAddress = props.address && props.address.formatted
  if (hasCoordinates) {
    const coordinates = `${props.lat},${props.long}`
    href.value = device === 'apple'
      ? `maps://maps.apple.com?q=${mapCoordinates.value}`
      : `https://maps.google.com/?q=${mapCoordinates.value}`
  } else if (hasAddress) {
    const address = encodeURIComponent(props.address.formatted)
    href.value = device === 'apple'
      ? `maps://maps.apple.com?q=${address}`
      : `https://maps.google.com/?q=${address}`
  } else {
    href.value = null
  }
})

let map = null

onMounted(() => {
  initMap()
})

onUnmounted(() => {
  if (map) {
    google.maps.event.clearInstanceListeners(map)
  }
})

function initMap () {
  const mapOptions = {
    zoom: props.zoom,
    center: coordinates,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUI: true
  }

  loader.load().then(() => {
    map = new google.maps.Map(
      mapContainer.value,
      mapOptions
    )
    // The Google Maps API has been loaded

    const marker = new google.maps.Marker({
      position: coordinates,
      map
    })

    marker.addListener('click', () => {
      map.setCenter(coordinates)
    })
  })
}
</script>

<template>
  <div
    v-if="address || (lat && long)"
    class="w-full"
  >
    <a
      :href="link ? href : null"
      class="w-full"
      target="_blank"
    >
      <div ref="mapContainer" :style="styles" />
    </a>
  </div>
</template>
