
/**
 * Fetches events from the Google Calendar API for the given time range.
 *
 * @param {string} accessToken User's access token
 * @param calendars
 * @param {string} timeMin Start time in ISO format
 * @param {string} timeMax End time in ISO format
 * @returns {Promise<Object[]>} List of events
 */
async function fetchEvents (accessToken, calendars, timeMin, timeMax) {
  if (!calendars.length) {
    calendars = [{ id: 'primary' }]
  }

  const promises = calendars.map((calendar) => {
    const url = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(calendar.id)}/events?timeMin=${timeMin}&timeMax=${timeMax}`

    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json()
          console.error('Error fetching events from Google Calendar API:', errorData)
          throw new Error('Failed to fetch events from Google Calendar API')
        }

        return response.json()
      })
      .then((data) => {
        return data.items
      })
  })

  try {
    const results = await Promise.all(promises)
    // Combine all the event items into a single array
    return results.flat()
  } catch (error) {
    console.error('Error fetching events:', error)
    throw error
  }
}

/**
 *
 * @param accessToken
 * @param calendarId
 * @param event
 * @return {Promise<any>}
 */
async function addEvent (accessToken, calendarId, event) {
  const queryParameters = {
    sendUpdates: 'all' // Change this value as per your requirement
  }

  const url = new URL(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`)
  url.search = new URLSearchParams(queryParameters).toString()

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(event)
  })

  if (!response.ok) {
    const errorData = await response.json()
    console.error('Failed to add event to Google Calendar:', errorData)
    throw new Error('Failed to add event to Google Calendar')
  }

  return await response.json()
}

/**
 *
 * @param accessToken
 * @param calendarId
 * @param eventId
 * @param updatedEvent
 * @return {Promise<any>}
 */
async function updateEvent (accessToken, calendarId, eventId, updatedEvent) {
  const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updatedEvent)
  })

  if (!response.ok) {
    const errorData = await response.json()
    console.error('Failed to update event on Google Calendar:', errorData)
    throw new Error('Failed to update event on Google Calendar')
  }

  return await response.json()
}

/**
 *
 * @param accessToken
 * @param calendarId
 * @param eventId
 * @return {Promise<void>}
 */
async function removeEvent (accessToken, calendarId, eventId) {
  const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  })

  if (!response.ok) {
    throw new Error('Failed to remove event from Google Calendar')
  }
}

/**
 *
 * @param accessToken
 * @return {Promise<*>}
 */
async function getCalendars (accessToken) {
  try {
    const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/calendarList', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      const errorData = await response.json()
      const errorMessage = `Failed to get calendars. Status code: ${response.status}. Error message: ${errorData.error.message}`
      console.error(errorMessage)
      throw new Error(errorMessage)
    }

    const data = await response.json()
    return data.items
  } catch (error) {
    console.error('Error getting calendars:', error.message)
    throw error
  }
}

/**
 *
 * @param refreshToken
 * @return {Promise<any>}
 */
async function refreshAccessToken (refreshToken) {
  const clientId = import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID
  const clientSecret = import.meta.env.VITE_GOOGLE_AUTH_CLIENT_SECRET
  const requestBody = new URLSearchParams({
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: refreshToken,
    grant_type: 'refresh_token'
  })

  try {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: requestBody
    })

    if (!response.ok) {
      throw new Error(`Failed to refresh access token. Status code: ${response.status}`)
    }

    const responseBody = await response.json()

    if (responseBody.error) {
      throw new Error(`Failed to refresh access token. ${responseBody.error}`)
    }

    return responseBody
  } catch (error) {
    console.error('Error refreshing access token:', error.message)
    throw error
  }
}

export default {
  fetchEvents,
  addEvent,
  updateEvent,
  removeEvent,
  getCalendars,
  refreshAccessToken
}
