
export const carpenter = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Crafting your dream space with precision.',
        body: 'At our carpenter\'s workshop, we don\'t just build furniture, we create functional works of art. With a focus on attention to detail and quality craftsmanship, our team is dedicated to bringing your vision to life. Let\'s build something beautiful together.'
      }
    },
    about: {
      text: {
        headline: 'Expert carpentry services for your home.',
        body: 'Looking for skilled carpenters to improve your living space? Look no further. Our team of experts offers a range of services, from custom cabinetry to finish carpentry. We take pride in our work and guarantee quality results that will exceed your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Craftsmanship that lasts a lifetime.',
        headline: 'Custom carpentry solutions for your home.',
        body: 'From built-in bookshelves to bespoke furniture, our team of skilled carpenters will bring your vision to life with quality craftsmanship and attention to detail. We use only the finest materials to create functional works of art that stand the test of time. Contact us to get started.'
      },
      bullets: [
        {
          title: 'Custom Cabinetry',
          text: 'Our skilled carpenters can design and build custom cabinets that fit your style and space perfectly. From kitchen cabinets to built-in shelving units, we\'ll create functional and beautiful storage solutions that enhance your home\'s aesthetic.'
        },
        {
          title: 'Finish Carpentry',
          text: 'Our team has the expertise to add finishing touches to any room, from crown molding to baseboards. We take pride in our attention to detail and craftsmanship, ensuring that every installation is flawless and long-lasting.'
        },
        {
          title: 'Woodwork',
          text: 'We specialize in creating unique and beautiful woodwork, from statement staircases to one-of-a-kind furniture pieces. Let us bring your vision to life with quality craftsmanship and stunning materials that will transform your space.'
        },
        {
          title: 'Restoration',
          text: 'Our team has the skills and experience to restore antique and vintage pieces to their former glory. Whether it\'s a cherished family heirloom or a flea market find, we\'ll give your piece the attention and care it deserves to ensure it lasts for generations to come.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'Lucy M',
          rating: 5,
          location: 'Denver, CO',
          text: 'The team at this carpenter\'s workshop are true craftsmen. They created a custom built-in bookcase for our living room that\'s both functional and beautiful.'
        },
        {
          name: 'Brian & Anne T.',
          rating: 5,
          location: 'Lakewood, CO',
          text: 'I couldn\'t be happier with the finish carpentry work done in my home. The attention to detail and quality of workmanship are outstanding.'
        },
        {
          name: 'Julie L',
          rating: 5,
          location: 'Breckenridge, CO',
          text: 'The custom cabinetry in my kitchen is stunning. The team worked with me to design the perfect layout and the final product exceeded my expectations.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'View Our Latest Woodworking Projects.',
        body: 'Browse through our gallery of custom woodworking projects and see how we\'ve transformed homes with our expert carpentry skills. From stunning staircases to built-in cabinetry, each piece showcases our team\'s attention to detail and commitment to quality craftsmanship. Start exploring today.'
      }
    },
    cta: {
      text: {
        overline: 'See Our Work In Person.',
        headline: 'Schedule a Visit to Our Workshop.',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Expert Carpentry for Your Home.',
        body: 'At our carpenter\'s workshop, we\'re passionate about creating functional works of art that enhance your living space. With a focus on quality materials and attention to detail, our team of skilled carpenters can bring your vision to life. Let\'s build something beautiful together.'
      }
    },
    features: {
      text: {
        overline: 'Craftsmanship that lasts a lifetime.',
        headline: 'Custom carpentry solutions for your home.',
        body: 'From built-in bookshelves to bespoke furniture, our team of skilled carpenters will bring your vision to life with quality craftsmanship and attention to detail. We use only the finest materials to create functional works of art that stand the test of time. Contact us to get started.'
      },
      bullets: [
        {
          title: 'Custom Cabinetry',
          text: 'Our skilled carpenters can design and build custom cabinets that fit your style and space perfectly. From kitchen cabinets to built-in shelving units, we\'ll create functional and beautiful storage solutions that enhance your home\'s aesthetic.'
        },
        {
          title: 'Finish Carpentry',
          text: 'Our team has the expertise to add finishing touches to any room, from crown molding to baseboards. We take pride in our attention to detail and craftsmanship, ensuring that every installation is flawless and long-lasting.'
        },
        {
          title: 'Woodwork',
          text: 'We specialize in creating unique and beautiful woodwork, from statement staircases to one-of-a-kind furniture pieces. Let us bring your vision to life with quality craftsmanship and stunning materials that will transform your space.'
        },
        {
          title: 'Restoration',
          text: 'Our team has the skills and experience to restore antique and vintage pieces to their former glory. Whether it\'s a cherished family heirloom or a flea market find, we\'ll give your piece the attention and care it deserves to ensure it lasts for generations to come.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'View Our Latest Woodworking Projects.',
        body: 'Browse through our gallery of custom woodworking projects and see how we\'ve transformed homes with our expert carpentry skills. From stunning staircases to built-in cabinetry, each piece showcases our team\'s attention to detail and commitment to quality craftsmanship. Start exploring today.'
      }
    },
    cta: {
      text: {
        overline: 'See Our Work In Person.',
        headline: 'Schedule a Visit to Our Workshop.',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Answers to Your Carpentry Questions.',
        body: 'Have questions about our services or the carpentry process? Check out our FAQ page for answers to common questions. If you don\'t see what you\'re looking for, feel free to contact us. Our team is always happy to help.'
      }
    },
    faq: {
      text: {
        overline: 'Carpentry Questions Answered.',
        headline: 'Find Answers to Common Questions.',
        body: 'Our FAQ page is a great resource for homeowners looking for more information about our services, materials, and process. We\'ve compiled answers to some of the most frequently asked questions to help you make informed decisions about your project. Contact us if you have any additional questions.'
      },
      bullets: [
        {
          title: 'What services do you offer?',
          text: 'We offer a wide range of carpentry services, including custom cabinetry, finish carpentry, woodwork, and restoration. Contact us to discuss how we can help transform your home.'
        },
        {
          title: 'What materials do you use?',
          text: 'We use only the highest quality materials for our carpentry projects, including hardwoods, softwoods, and composite materials. We work with our clients to choose materials that suit their style and budget.'
        },
        {
          title: 'How long does a project take?',
          text: 'The timeline for a project depends on its size and complexity. We\'ll provide you with a detailed estimate and timeline before we start work, so you know what to expect.'
        },
        {
          title: 'Do you offer custom designs?',
          text: 'Yes! Our team specializes in creating custom designs that are tailored to your unique style and space. We\'ll work with you to bring your vision to life with quality craftsmanship and attention to detail.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'See Our Work In Person.',
        headline: 'Schedule a Visit to Our Workshop.',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Carpenters',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Contact Us',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our carpentry services. Our team is standing by to answer your questions and help you transform your space with exceptional carpentry solutions.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your carpentry project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your space and carpentry needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
