<script setup>
import { computed, onMounted, ref, useSlots, watch } from 'vue'
import { useValidateStore } from '@/stores/useValidateStore.js'
import { XCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  label: {
    type: [String, Number],
    default: null
  },
  hint: {
    type: [String, Number],
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  component: {
    type: String,
    default: 'input'
  },
  altColor: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Function,
    default: () => {}
  },
  required: {
    type: Boolean,
    default: false
  },
  inputStyle: {
    type: String,
    default: ''
  },
  clearable: {
    type: Boolean,
    default: false
  },
  borderless: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const useValidate = useValidateStore()
const isError = ref(props.error)
const errorMessage = ref(null)
const slots = useSlots()

const classes = computed(() => {
  return [
    props.error || isError.value
      ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
      : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500 ',
    props.altColor ? 'bg-gray-100' : 'bg-white',
    slots.prefix && 'pl-10',
    (slots.suffix || props.clearable) && 'pr-10',
    props.borderless ? 'border-0' : 'border shadow-sm',
    'block w-full rounded-md sm:text-sm resize-none min-h-[38px] h-[38px]']
})

// getter
watch(
  () => props.modelValue,
  (val) => {
    if (props.required) {
      isRequired(val)
    }
  }
)

onMounted(() => {
  updateHeight()
})

function onInput (e) {
  emit('update:modelValue', e.target.value)
  e.target.style.height = ''
  e.target.style.height = `${e.target.scrollHeight + 2}px`
}

function updateHeight () {
  if (props.component === 'textarea') {
    const elements = document.querySelectorAll('textArea')
    elements.forEach((el) => {
      el.style.height = `${el.scrollHeight + 2}px`
    })
  }
}

function isRequired (val) {
  if (props.required) {
    setTimeout(() => {
      validate({
        message: props.label ? `${props.label} is required` : 'Field required',
        test: !!val
      })
    }, 200)
  }
}

function validate ({ message, test }) {
  const listed = useValidate.errors.includes(message)

  if (!test && !listed) {
    useValidate.addError(message)
    isError.value = true
    errorMessage.value = message
  }
  if (!test && listed) {
    isError.value = true
    errorMessage.value = message
  }
  if (test && listed) {
    useValidate.removeError(message)
    isError.value = false
    errorMessage.value = null
  }
  if (test && !listed) {
    isError.value = false
    errorMessage.value = null
  }
}
</script>

<template>
  <div class="w-full">
    <!-- label -->
    <label
      v-if="label"
      :for="label"
      class="block text-sm font-semibold leading-6 text-gray-900 capitalize"
    >{{ label }} </label>

    <div :class="[ label && 'mt-1', 'w-full relative']">
      <!-- prefix -->
      <div
        v-if="$slots.prefix"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500"
      >
        <slot name="prefix" />
      </div>

      <!-- input -->
      <component
        :is="component"
        :class="classes"
        :name="label"
        :role="component === 'textarea' ? 'textarea' : 'input'"
        :type="type"
        :value="modelValue"
        v-bind="$attrs"
        @blur="isRequired(modelValue)"
        @input="onInput"
      />

      <!-- suffix -->
      <div
        v-if="$slots.suffix"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500"
      >
        <slot name="suffix" />
      </div>

      <!-- clearable x -->
      <div
        v-if="clearable"
        class="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500"
      >
        <XCircleIcon
          aria-hidden="true"
          class="h-5 w-5 text-gray-400 z-20"
          @click=" emit('update:modelValue', '')"
        />
      </div>
    </div>

    <!-- hint / error message -->
    <p
      v-if="hint || errorMessage"
      id="input-hint"
      :class="[
        error || isError ? 'text-red-600' : 'text-gray-500',
        'pt-1 text-xs bg-transparent',
      ]"
    >
      {{ isError ? errorMessage : hint }}
    </p>
  </div>
</template>
<style>

</style>
