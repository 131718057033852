import { reactive, ref } from 'vue'

export function useCalendar () {
  const currentDate = new Date()
  const year = currentDate.getFullYear() // 2023
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0') // 06
  const day = currentDate.getDate().toString().padStart(2, '0') // 06
  const dateString = `${year}-${month}-${day}`

  const current = reactive({
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    day: new Date().getDay(),
    weekday: new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase(),
    dateString
  })

  const weeklyStartDate = ref(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())))

  const selectedDate = ref(new Date().toISOString().slice(0, 10))

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const daysOfWeekLong = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

  const dailyTimeSlots = [
    { value: '12:00 am', time24h: '0000' },
    { value: '1:00 am', time24h: '0100' },
    { value: '2:00 am', time24h: '0200' },
    { value: '3:00 am', time24h: '0300' },
    { value: '4:00 am', time24h: '0400' },
    { value: '5:00 am', time24h: '0500' },
    { value: '6:00 am', time24h: '0600' },
    { value: '7:00 am', time24h: '0700' },
    { value: '8:00 am', time24h: '0800' },
    { value: '9:00 am', time24h: '0900' },
    { value: '10:00 am', time24h: '1000' },
    { value: '11:00 am', time24h: '1100' },
    { value: '12:00 pm', time24h: '1200' },
    { value: '1:00 pm', time24h: '1300' },
    { value: '2:00 pm', time24h: '1400' },
    { value: '3:00 pm', time24h: '1500' },
    { value: '4:00 pm', time24h: '1600' },
    { value: '5:00 pm', time24h: '1700' },
    { value: '6:00 pm', time24h: '1800' },
    { value: '7:00 pm', time24h: '1900' },
    { value: '8:00 pm', time24h: '2000' },
    { value: '9:00 pm', time24h: '2100' },
    { value: '10:00 pm', time24h: '2200' },
    { value: '11:00 pm', time24h: '2300' }
  ]

  function resetCurrentDates () {
    const today = new Date()
    current.year = today.getFullYear()
    current.month = today.getMonth()
    current.day = today.getDay()
    selectedDate.value = new Date().toISOString().slice(0, 10)
    weeklyStartDate.value = new Date(today.setDate(today.getDate() - today.getDay()))
  }

  // formatting
  function monthToLongFormatDate (monthNumber) {
    const date = new Date(0, monthNumber)
    return date.toLocaleString('default', { month: 'long' })
  }

  function formatTime (value) {
    const date = new Date(value)
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
  }

  function formatSelectedDate (dateString) {
    const date = new Date(dateString)
    const timezoneOffset = date.getTimezoneOffset()
    date.setMinutes(date.getMinutes() + timezoneOffset)

    const options = { month: 'long', day: 'numeric', year: 'numeric' }

    return date.toLocaleDateString('en-US', options)
  }

  function usePrevCalendarPeriod (view) {
    if (view === 'Month' || view === 'month') {
      if (current.month === 0) {
        current.month = 11
        current.year--
      } else {
        current.month--
      }
    } else if (view === 'Week' || view === 'week') {
      const prevMonth = new Date(weeklyStartDate.value)
      prevMonth.setDate(prevMonth.getDate() - 7)
      if (prevMonth.getMonth() !== weeklyStartDate.value.getMonth()) {
        current.month = prevMonth.getMonth()
        current.year = prevMonth.getFullYear()
      }
      weeklyStartDate.value = prevMonth
    }
  }

  function useGoToToday () {
    const today = new Date()
    current.year = today.getFullYear()
    current.month = today.getMonth()
    current.day.value = today.getDay()
    weeklyStartDate.value = new Date(today.setDate(today.getDate() - today.getDay()))
    selectedDate.value = new Date().toISOString().slice(0, 10)
  }

  function useNextCalendarPeriod (view) {
    if (view === 'Month' || view === 'month') {
      if (current.month === 11) {
        current.month = 0
        current.year++
      } else {
        current.month++
      }
    } else if (view === 'Week' || view === 'week') {
      const nextWeek = new Date(weeklyStartDate.value)
      nextWeek.setDate(nextWeek.getDate() + 7)
      if (nextWeek.getMonth() !== weeklyStartDate.value.getMonth()) {
        current.month = nextWeek.getMonth()
        current.year = nextWeek.getFullYear()
      }
      weeklyStartDate.value = nextWeek
    }
  }
  /**
   * Generates an array of dates for a given year and month.
   * @param {number} year - The year for which to generate the date array. If not provided, the current year is used.
   * @param {number} month - The month (0-11) for which to generate the date array. If not provided, the current month is used.
   * @returns {Array} - An array of objects representing the dates.
   */
  async function generateDateArray (year, month) {
    year = year || new Date().getFullYear()
    month = month || new Date().getMonth()

    const startDate = new Date(year, month, 1)
    const endDate = new Date(year, month + 1, 0)
    const today = new Date()
    const daysInMonth = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1
    const dateArray = []

    for (let i = 0; i < daysInMonth; i++) {
      const currentDate = new Date(year, month, i + 1)
      const weekday = getWeekDay(currentDate.getDay())

      dateArray.push({
        date: currentDate.toISOString().split('T')[0],
        isCurrentMonth: true,
        isToday: isSameDay(today, currentDate),
        isSelected: isSameDay(today, currentDate),
        weekday,
        events: []
      })
    }

    // Calculate padding days needed
    const startWeekday = startDate.getDay()
    const daysInWeek = 7
    const weeksInCalendar = 6
    const totalDaysInCalendar = daysInWeek * weeksInCalendar
    const daysAdded = daysInMonth + startWeekday
    const paddingDays = totalDaysInCalendar - daysAdded

    // Add padding days to the beginning of the dateArray
    for (let i = 1; i <= startWeekday; i++) {
      const currentDate = new Date(year, month, -i + 1)
      const weekday = getWeekDay(currentDate.getDay())

      dateArray.unshift({
        date: currentDate.toISOString().split('T')[0],
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
        weekday,
        events: []
      })
    }

    // Add padding days to the end of the dateArray
    for (let i = 1; i <= paddingDays; i++) {
      const currentDate = new Date(year, month + 1, i)
      const weekday = getWeekDay(currentDate.getDay())
      dateArray.push({
        date: currentDate.toISOString().split('T')[0],
        isCurrentMonth: false,
        isToday: false,
        isSelected: false,
        weekday,
        events: []
      })
    }

    return dateArray
  }

  function getWeekDay (dayIndex) {
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    return weekDays[dayIndex]
  }

  function isSameDay (date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }

  return {
    current,
    weeklyStartDate,
    selectedDate,
    daysOfWeek,
    daysOfWeekLong,
    dailyTimeSlots,
    resetCurrentDates,
    monthToLongFormatDate,
    formatTime,
    formatSelectedDate,
    usePrevCalendarPeriod,
    useGoToToday,
    useNextCalendarPeriod,
    generateDateArray
  }
}
