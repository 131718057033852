<script setup>
import { ref, watchEffect } from 'vue'

const props = defineProps({
  imgSrc: {
    type: String,
    default: 'img-1'
  },
  extension: {
    type: String,
    default: 'jpg'
  }
})

const importImage = ref(null)

watchEffect(async () => {
  if (props.extension === 'jpg') {
    importImage.value = await import(`../../../assets/template/${props.imgSrc}.jpg`).then(
      lib => lib.default || lib)
  } else if (props.extension === 'png') {
    importImage.value = await import(`../../../assets/template/${props.imgSrc}.png`).then(
      lib => lib.default || lib)
  } else if (props.extension === 'url') {
    importImage.value = await new URL(props.imgSrc, import.meta.url)
  } else {
    importImage.value = await import(`../../../assets/template/${props.imgSrc}.jpg`).then(
      lib => lib.default || lib)
  }
})
</script>

<template>
  <img
    id="img"
    :src="importImage"
    alt="alt"
  >
</template>
