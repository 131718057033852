<script setup>
import { ref, watch } from 'vue'
import { usePhone } from '@/composables/usePhone'
import { XMarkIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  label: {
    type: [String, Number],
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: true
  },
  altColor: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const { phoneRef, formatPhone } = usePhone(props.modelValue)
const input = ref(null)

watch(phoneRef, (newX) => {
  emit('update:modelValue', newX)
})

watch(() => props.modelValue, (newX) => {
  if (newX !== phoneRef.value) phoneRef.value = newX
})
</script>

<template>
  <div class="w-full">
    <!-- screen reader -->
    <label :for="props.label" class="sr-only">
      {{ props.label }}
    </label>

    <!-- Visible label -->
    <label
      v-if="label"
      :for="label"
      class="block text-sm leading-6 font-medium text-gray-900"
    >
      {{ label }}
    </label>

    <div :class="[ props.label ? 'mt-2': null, 'w-full relative']">
      <!-- Prefix -->
      <div
        v-if="$slots.prefix"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400"
      >
        <slot name="prefix" />
      </div>

      <!-- Input -->
      <input
        ref="input"
        v-model="phoneRef"
        :class="[
          props.error
            ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-900/20 focus:border-theme-primary-500 focus:ring-theme-primary-500 ',
          props.clearable && 'pr-8',
          props.borderless ? 'border-0' : 'border shadow-sm',
          'block w-full rounded-md bg-white sm:text-sm resize-none min-h-[38px] h-[38px] text-gray-900  placeholder:text-gray-400 px-2 scroll-bar--none'
        ]"
        :name="label"
        maxlength="14"
        pattern="[0-9]*"
        type="tel"
        v-bind="$attrs"
        @input="formatPhone"
      >

      <!-- Suffix -->
      <div
        v-if="$slots.suffix"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400"
      >
        <slot name="suffix" />
      </div>

      <!-- Clearable -->
      <div
        v-if="clearable && phoneRef"
        class="pointer-events-auto absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <XMarkIcon
          aria-hidden="true"
          class="h-5 w-5 text-gray-400 z-20"
          @click="()=>{emit('update:modelValue', ''); input.focus()}"
        />
      </div>
    </div>
  </div>
</template>
