
export const plumber = {
  // page name
  'home page': {
    // section name
    hero: {
      text: {
        headline: 'Experience Exceptional Plumbing Services',
        body: 'From leaky faucets to major repairs, our team of skilled plumbers will provide the expertise and quality you need to keep your plumbing system running smoothly.'
      }
    },
    about: {
      text: {
        headline: 'Expert Plumbing Solutions',
        body: 'We are dedicated to providing top-notch plumbing services to our clients. Our team of skilled professionals brings years of expertise and a commitment to quality to every project we undertake, ensuring efficient and reliable solutions that exceed your expectations.'
      }
    },
    features: {
      text: {
        overline: 'Premium Plumbing Services',
        headline: 'Our Comprehensive Plumbing Solutions',
        body: ' From minor repairs to major installations, our team is dedicated to delivering exceptional results that keep your home or business running smoothly.'
      },
      bullets: [
        {
          title: 'Emergency Plumbing',
          text: 'We offer 24/7 emergency plumbing services to help you when you need it most. Our team will quickly diagnose the issue and provide a reliable solution to get your plumbing back on track.'
        },
        {
          title: 'Water Heater Services',
          text: 'From repairs to installations, our team provides expert water heater services to keep your hot water flowing. We use only the highest-quality materials and brands to ensure a long-lasting solution for your home or business.'
        },
        {
          title: 'Pipe Repair and Replacement',
          text: 'Our team of skilled professionals can provide reliable pipe repair and replacement services to keep your plumbing system in top shape. We use advanced technology and techniques to minimize disruption to your home or business.'
        },
        {
          title: 'Drain Cleaning',
          text: 'Keep your drains running smoothly with our expert drain cleaning services. We use safe and effective techniques to remove blockages and build-up, ensuring your plumbing system stays in top condition.'
        }
      ]
    },
    reviews: {
      bullets: [
        {
          name: 'James T.',
          rating: 5,
          location: 'Denver, CO',
          text: 'We had a major plumbing emergency and they were there in less than an hour to help us. The team was professional, efficient, and saved the day! Highly recommend.'
        },
        {
          name: 'Sarah G.',
          rating: 5,
          location: 'Aurora, CO',
          text: 'I had a leaky faucet that was driving me crazy, but they were able to fix it quickly and easily. Their team was friendly and knowledgeable, and the service was affordable. Would definitely use again!'
        },
        {
          name: 'Mike B.',
          rating: 5,
          location: 'Littleton, CO',
          text: 'I had an issue with my water heater, and they were able to provide a quick and reliable solution. The team was professional and efficient, and I am so grateful for their service. Thank you!'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'See Our Work',
        body: 'Explore our portfolio of exceptional plumbing projects and see the difference our skilled professionals can make in keeping your plumbing system running smoothly.'
      }
    },
    cta: {
      text: {
        overline: 'Need Expert Plumbing Solutions?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'about page': {
    header: {
      text: {
        headline: 'Discover the Difference in Professional Plumbing Services',
        body: 'With a commitment to excellence and a passion for delivering exceptional results, our team of professional plumbers is dedicated to providing top-quality plumbing services with outstanding customer service. Get to know us and see why we are the trusted choice for all your plumbing needs.'
      }
    },
    features: {
      text: {
        overline: 'Premium Plumbing Services',
        headline: 'Our Comprehensive Plumbing Solutions',
        body: 'From leaky faucets to complex installations, our team is dedicated to delivering reliable and efficient solutions that improve your space.'
      },
      bullets: [
        {
          title: 'Plumbing Installations',
          text: 'We provide expert plumbing installation services for a variety of fixtures including sinks, toilets, showers, bathtubs, and more. Our team will ensure that your new fixtures are installed efficiently and correctly.'
        },
        {
          title: 'Plumbing Repairs',
          text: 'Our team of skilled plumbers is available to provide fast and effective plumbing repairs for issues such as leaks, clogs, and damaged pipes. We use the latest tools and techniques to diagnose and repair plumbing problems quickly.'
        },
        {
          title: 'Water Heater Services',
          text: 'From installation to repair and maintenance, we offer comprehensive water heater services. Our team can help you choose the right water heater for your needs, and provide regular maintenance to ensure it operates efficiently.'
        },
        {
          title: 'Drain Cleaning',
          text: 'We offer professional drain cleaning services to remove clogs and improve the performance of your plumbing system. Our team uses state-of-the-art tools and techniques to ensure that your drains are clean and clear.'
        }
      ]
    },
    gallery: {
      text: {
        headline: 'Discover Our Work',
        body: 'Explore our portfolio of exceptional plumbing projects and see the difference our skilled professionals can make in transforming your space.'
      }
    },
    cta: {
      text: {
        overline: 'Need Plumbing Services?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'faq page': {
    header: {
      text: {
        headline: 'Get the Answers to Your Plumbing Questions',
        body: 'Our FAQ page is your one-stop shop for everything you need to know about our plumbing services. From questions about pricing and scheduling, to tips for maintaining your plumbing system, we have you covered. Read on for helpful information and answers to common questions.'
      }
    },
    faq: {
      text: {
        overline: 'Frequently Asked Questions',
        headline: 'Answers to Your Plumbing Questions',
        body: 'Our team is here to provide the information you need to make informed decisions about your plumbing needs.'
      },
      bullets: [
        {
          title: 'What are some common plumbing problems?',
          text: 'Common plumbing problems include leaky faucets, clogged drains, running toilets, low water pressure, and water heater issues. If you are experiencing any of these problems or have other concerns, our team is here to help.'
        },
        {
          title: 'How can I prevent plumbing issues?',
          text: 'Regular maintenance and inspections are key to preventing plumbing issues. This includes regular drain cleaning, water heater maintenance, and checking for leaks. Our team can provide recommendations for maintaining your plumbing system and help you avoid costly repairs.'
        },
        {
          title: 'Do you offer emergency plumbing services?',
          text: 'Yes, we offer emergency plumbing services 24/7. If you have a plumbing emergency, such as a burst pipe or major leak, our team is available to help and provide prompt, reliable service.'
        },
        {
          title: 'What if I am not satisfied with the service?',
          text: 'Our team is committed to delivering exceptional service that meets and exceeds your expectations. In the unlikely event that you are not satisfied with the service provided, we will work with you to make it right. We stand behind our work and are dedicated to your satisfaction.'
        }
      ]
    },
    cta: {
      text: {
        overline: 'Need Plumbing Services?',
        headline: 'Contact Us Today to Get Started',
        body: ''
      }
    }
  },

  'contact page': {
    header: {
      text: {
        headline: 'Get in Touch with Our Expert Plumbers',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    contact: {
      text: {
        headline: 'Contact Us',
        body: 'We would love to hear from you! Contact us today to schedule a consultation or request more information about our plumbing services. Our team is standing by to answer your questions and help you with all your plumbing needs.'
      }
    }
  },

  'estimate page': {
    header: {
      text: {
        headline: 'Get an Instant Estimate',
        body: 'Have questions about our services or want to schedule a free consultation? Our team is here to help. Fill out the form or give us a call to get in touch with us today.'
      }
    },
    'estimate selector': {
      text: {
        overline: 'Instant Estimate',
        headline: 'Looking for project pricing?',
        body: 'We understand that you want to make informed decisions about your plumbing project. That\'s why we offer an instant estimate tool that provides an accurate, reliable estimate of the cost of your project. Simply input some basic information about your plumbing needs, and our tool will generate an estimate for you in just minutes. Try it out today to get a better sense of what your project will cost and how we can help.'
      }
    }
  }
}
